// Manually extending prototype to get instanceof working for Errors
function Unauthorized(message, ...params) {
  this.name = 'Unauthorized';
  this.message = message;

  if (this.message === undefined) {
    this.message = 'Session time out';
  }

  this.stack = new Error().stack;
}

Unauthorized.prototype = Object.create(Error.prototype);
Unauthorized.prototype.constructor = Unauthorized;

Unauthorized.prototype.toString = function() {
  return this.message;
};

export default Unauthorized;
