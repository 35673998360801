import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { format, isDate } from 'date-fns';
import { sortBy } from 'lodash';
import KeyValueItem from '../../common/components/KeyValueItem';
import createFullName from '../../common/helpers/createFullName';

const themes = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0
  },
  oldValue: {
    textDecoration: 'line-through'
  }
});

const labelMappings = {
  'Passport.No': 'Passport - Number',
  'Passport.DateOfExpiry': 'Passport - Passport Expiry'
};

const renderChange = (change, paxId, classes) => {
  const getLabel = propertyName => {
    return (
      labelMappings[propertyName] ||
      propertyName
        .split('.')
        .map(v => v.replace(/([A-Z])/g, ' $1'))
        .join(' - ')
    );
  };

  return (
    <KeyValueItem
      key={paxId + '-' + change.propertyName}
      label={
        <span
          style={{
            fontSize: '0.75rem',
            fontWeight: '400',
            color: 'rgba(0, 0, 0, 0.54)'
          }}
        >
          {getLabel(change.propertyName)}
        </span>
      }
      value={
        <Grid container component="span" spacing={0}>
          <Grid
            component="span"
            item
            xs={5}
            sm={5}
            style={{
              fontSize: '0.875rem',
              fontWeight: '400',
              textDecoration: change.values.old ? 'line-through' : 'none'
            }}
          >
            {change.values.old
              ? isDate(change.values.old)
                ? format(change.values.old, process.env.REACT_APP_DATE_FORMAT)
                : change.values.old
              : '—'}
          </Grid>
          <Grid component="span" item xs={2} sm={2}>
            →
          </Grid>
          <Grid
            component="span"
            item
            xs={5}
            sm={5}
            style={{
              fontSize: '0.875rem',
              fontWeight: '400',
              color: 'rgba(0, 0, 0, 0.87)'
            }}
          >
            {isDate(change.values.new)
              ? format(change.values.new, process.env.REACT_APP_DATE_FORMAT)
              : change.values.new}
          </Grid>
        </Grid>
      }
      valueId={'pax.changes.' + change.propertyName + '_' + paxId}
    />
  );
};

const renderChanges = (paxChange, paxList, classes) => {
  const changes = paxChange.changes.map((change, i) =>
    renderChange(change, paxChange.id, classes)
  );

  const pax = paxList.find(x => x.id === paxChange.paxId);

  return (
    <ListItem key={paxChange.id}>
      <ListItemText
        primary={
          <span>
            <Typography
              style={{ fontWeight: '500' }}
              variant="body2"
              id={'pax.changes.fullNameAndBookingId_' + paxChange.id}
            >{`${pax.fullNameWithTitle} - ${pax.bookingId}`}</Typography>
          </span>
        }
        secondary={changes}
        disableTypography
      />
    </ListItem>
  );
};

const PaxChanges = ({ paxChanges, paxList, classes }) => {
  let paxChangesExtended = paxChanges.map(item => {
    const bookingId = {
      bookingId: paxList.find(x => x.id === item.paxId).bookingId
    };
    return { ...item, ...bookingId };
  });

  paxChangesExtended = sortBy(paxChangesExtended, [
    { bookingId: 'asc' },
    'surname',
    'firstName'
  ]);

  return (
    <List className={classes.root}>
      {paxChangesExtended.map(paxChange =>
        renderChanges(paxChange, paxList, classes)
      )}
    </List>
  );
};

export default withStyles(themes)(PaxChanges);
