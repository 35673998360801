import * as actionTypes from '../actionTypes/crewRoles';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.CREW_ROLES_GET_SUCCESS:
      return action.payload.crewRoles;
    default:
      return state;
  }
};
