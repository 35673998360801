import * as actionTypes from '../actionTypes/departures';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURES_GET_SUCCESS:
      return action.payload.departures;
    default:
      return state;
  }
};
