import * as actionTypes from '../actionTypes/medication';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import TripReportSectionBaseAction from './TripReportSectionBaseAction';

export default class MedicationAction extends TripReportSectionBaseAction {
  constructor() {
    super(
      LeaderPortalService.TripReport.Medication,
      actionTypes,
      'Medication issue'
    );
  }
}
