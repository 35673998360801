export default {
  iconButton: {
    flex: '1 1 auto'
  },
  badgeRoot: {
    padding: '0 2px',
    height: '5px',
    top: -12
  }
};
