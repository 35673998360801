import React from 'react';
import PropTypes from 'prop-types';
// import { withTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import DepartureCardContent from './DepartureCardContent';
import DepartureCardAction from './DepartureCardAction';
import cardStyles from '../../common/styles/Card.module.css';
import DepartureCardHeader from './DepartureCardHeader';

class DepartureCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { departure, expandable } = this.props;

    return (
      <Card className={cardStyles.card}>
        <DepartureCardHeader
          departure={departure}
          handleClick={this.handleExpandClick}
          cardExpanded={this.state.expanded}
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <DepartureCardContent departure={departure} expandable={expandable} />
          <Divider />
          <DepartureCardAction departure={departure} />
        </Collapse>
      </Card>
    );
  }
}

DepartureCard.propTypes = {
  departure: PropTypes.object,
  expandable: PropTypes.bool
};

export default DepartureCard;
