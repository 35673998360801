import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavigationCardActions.styles';
import { CardActions } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

const NavigationCardActions = ({ children, classes }) => (
  <CardActions classes={{ root: classes.root }}>{children}</CardActions>
);

NavigationCardActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default withStyles(styles)(NavigationCardActions);
