export default (min, max) => {
  return value => {
    if (value && Object.prototype.toString.call(value) !== '[object null]') {
      let date = new Date(value);
      return (min && date < min) || (max && date > max)
        ? `Age must be between 3 and 100 years`
        : undefined;
    }
    return 'Invalid date';
  };
};
