import * as actionTypes from '../actionTypes/possibleComplaints';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.GET_SUCCESS:
      let issues = action.payload.recordList.possibleComplaints;
      issues.map(i => (i['pax'] = i.passengers.map(p => p.paxId)));
      return issues;
    case actionTypes.INSERT_SUCCESS:
      action.payload.record.pax = action.payload.record.passengers.map(
        p => p.paxId
      );
      return [...state, action.payload.record];
    case actionTypes.DELETE_SUCCESS:
      return state.filter(x => x.id !== action.payload.recordId);
    case actionTypes.UPDATE_SUCCESS:
      let newState = Object.assign([], state);
      let itemIndex = state.findIndex(x => x.id === action.payload.record.id);
      newState[itemIndex] = action.payload.record;
      return newState;
    default:
      return state;
  }
};
