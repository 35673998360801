import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';
import selectors from '../selectors';
import TripReportSection from '../components/TripReportSection';
import TripReportCrewFields from '../components/TripReportCrewFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportCrewContainer extends React.Component {
  renderSubHeader = crewMember => {
    return (
      <span>
        <span id={'crew.header.roleName_' + crewMember.id}>
          {
            this.props.crewRoles.find(o => o.role === crewMember.role)
              .displayText
          }
        </span>
        {crewMember.contactNumber && (
          <span id={'crew.header.contactNumber_' + crewMember.id}>
            {' '}
            • {crewMember.contactNumber}
          </span>
        )}
      </span>
    );
  };

  renderContent = crewMember => {
    return (
      crewMember.comments && (
        <div style={styles.trimText}>
          <span id={'crew.header.comments_' + crewMember.id}>
            {crewMember.comments}
          </span>
        </div>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportCrewFields
      crewRoles={this.props.crewRoles}
      submitting={submitting}
    />
  );

  renderSectionCard = crewMember => {
    return (
      <TripReportSectionCard
        sectionName="crew"
        itemName="crew member"
        title={crewMember.name}
        subTitle="Include the details of Crew members who accompanied you on this departure."
        subHeader={this.renderSubHeader}
        cardContent={this.renderContent}
        item={crewMember}
        itemId={crewMember.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateCrew}
        delete={() =>
          this.props.actions.deleteCrew(this.props.departure.id, crewMember.id)
        }
        deleteMessage={crewMember =>
          `Are you sure you want to delete crew member "${crewMember.name}"?`
        }
      />
    );
  };

  render() {
    const { departure, crewList, crewRoles } = this.props;

    return (
      departure &&
      crewList &&
      crewRoles && (
        <TripReportSection
          sectionName="crew"
          itemName="crew member"
          subTitle="Include the details of Crew members who accompanied you on this departure."
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={crewList}
          insert={this.props.actions.insertCrew}
        />
      )
    );
  }
}

TripReportCrewContainer.propTypes = {
  departure: PropTypes.object.isRequired,
  crewList: PropTypes.array.isRequired,
  crewRoles: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    insertCrew: PropTypes.func,
    deleteCrew: PropTypes.func,
    updateCrew: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {
    id: params.router.params.id
  };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      getCrewRoles: actions.crewRoles.getCrewRoles,
      insertCrew: values => async dispatch => {
        await actions.crew.insertCrew(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteCrew: (departureId, crewMemberId) => async dispatch => {
        await actions.crew.deleteCrew(departureId, crewMemberId)(dispatch);
      },
      updateCrew: values => async dispatch => {
        await actions.crew.updateCrew(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportCrewContainer)
);
