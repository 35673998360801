import * as actionTypes from '../actionTypes/pax';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.PAX_GET_SUCCESS:
      return action.payload;
    case actionTypes.PAX_CLEAR:
      return null;
    default:
      return state;
  }
};
