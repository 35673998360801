import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import { messageTypes } from '../actions/globalMessages';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const icons = {
  [messageTypes.success]: 'done',
  [messageTypes.info]: 'info_outline',
  [messageTypes.error]: 'error_outline',
  [messageTypes.offline]: 'wifi_off'
};

const themes = theme => ({
  [messageTypes.success]: {
    color: theme.palette.success.main
  },
  [messageTypes.info]: {
    color: theme.palette.info.main
  },
  [messageTypes.error]: {
    color: theme.palette.error.main
  },
  messageContainer: {
    display: 'flex'
  },
  message: {
    flexGrow: 1,
    padding: '0 10px',
    lineHeight: 2
  }
});

export class GlobalMessage extends React.Component {
  onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.actions.removeMessage();
  };

  renderCloseButton = () =>
    this.props.closable &&
    this.props.message &&
    !this.props.message.redirect && (
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => this.props.actions.removeMessage()}
      >
        <Icon>close</Icon>
      </IconButton>
    );

  render() {
    const {
      open,
      autoHideDuration,
      message,
      type,
      action,
      classes
    } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          onClose={this.onClose}
          SnackbarContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={
            <div className={classes.messageContainer} id="message-id">
              <Icon className={classes[type]}>{icons[type]}</Icon>
              <span className={classes.message}>{message}</span>
            </div>
          }
          autoHideDuration={autoHideDuration}
          action={[].concat(action, this.renderCloseButton())}
        />
      </div>
    );
  }
}

GlobalMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  autoHideDuration: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  closable: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(messageTypes)),
  action: PropTypes.array,
  actions: PropTypes.shape({
    removeMessage: PropTypes.func.isRequired
  }).isRequired
};

GlobalMessage.defaultProps = {
  closable: false
};

export default withStyles(themes)(GlobalMessage);
