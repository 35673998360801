import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import createLink from '../../common/helpers/createLink';
import { ROUTE_DEPARTUREREPORT } from '../../common/constants/routes';
import actions from '../actions';
import selectors from '../selectors';
import DepartureReportCard from '../components/DepartureReportCard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import { fileTypes } from '../../common/constants/fileTypes';
import Unauthorized from '../../common/errors/Unauthorized';
import NetworkError from '../../common/errors/NetworkError';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';
import CustomRouterWrapper from '../../tripReport/containers/CustomRouterWrapper';

class DepartureReportContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };
  }

  componentDidMount() {
    this.loadData(this.props.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.loadData(this.props.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { pax, departure } = this.props;

    return (
      pax &&
      departure && (
        <DepartureReportCard
          pax={pax}
          departure={departure}
          getFile={this.props.actions.getFile}
          {...this.props}
        />
      )
    );
  }
}

DepartureReportContainer.propTypes = {
  actions: PropTypes.shape({
    getPax: PropTypes.func.isRequired,
    getFile: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = (state, params) => {
  let selectedDeparture = selectors.selectedDeparture.getDeparture(state);
  let stateDeparturePax = selectors.pax.getPax(state);

  let pax =
    stateDeparturePax && stateDeparturePax.pax ? stateDeparturePax.pax : null;

  return {
    id: params.router.params.id,
    pax: pax,
    departure: selectedDeparture
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        getPax: actions.selectedDeparture.getSelectedDeparture,
        getFile: (url, departureCode) => async dispatch => {
          try {
            await LeaderPortalService.getFile(
              url,
              departureCode,
              fileTypes.PDF
            );
          } catch (e) {
            if (e instanceof Unauthorized) {
              dispatch(
                addMessage(e.toString(), messageTypes.error, true, false)
              );
            }

            if (e instanceof NetworkError) {
              dispatch(addMessage(e.toString(), messageTypes.error));
            }
          }
        }
      },
      dispatch
    ),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
  }
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(DepartureReportContainer)
);
