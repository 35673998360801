export default class TripRating {
  constructor(service) {
    this.service = service;
  }

  async get(departureId) {
    const resourcePath = `/tripReport/${departureId}`;
    const data = await this.service.get(resourcePath);

    return data.tripReport;
  }

  async updateOverallTripRating(departureId, overallTripRating) {
    const resourcePath = `/tripReport/${departureId}/OverallTripRating`;
    let data = { overallTripRating: overallTripRating };
    return this.service.patch(resourcePath, data);
  }

  async updateGroupDynamicRating(departureId, groupDynamicRating) {
    const resourcePath = `/tripReport/${departureId}/GroupDynamicRating`;
    let data = { groupDynamicRating: groupDynamicRating };
    return this.service.patch(resourcePath, data);
  }
}
