import * as actionTypes from '../actionTypes/costChange';

function setPrecision(cost) {
  if (cost.toString().indexOf('.') != -1) return cost.toFixed(2);
  return cost;
}

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.GET_SUCCESS: {
      action.payload.recordList.costChanges.forEach(function(element) {
        element.budgetedCost = setPrecision(element.budgetedCost);
        element.newCost = setPrecision(element.newCost);
      });

      return action.payload.recordList.costChanges;
    }
    case actionTypes.INSERT_SUCCESS:
      return [...state, action.payload.record];
    case actionTypes.DELETE_SUCCESS:
      return state.filter(x => x.id !== action.payload.recordId);
    case actionTypes.UPDATE_SUCCESS:
      let newState = Object.assign([], state);
      let itemIndex = state.findIndex(x => x.id === action.payload.record.id);
      newState[itemIndex] = action.payload.record;
      return newState;
    default:
      return state;
  }
};
