import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './PaxUpdateCardDetails.styles';
import InputField from '../../common/forms/InputField';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardHeader } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import email from '../../common/forms/validators/email';
import phone from '../../common/forms/validators/phone';
import parsePhone from '../../common/forms/parsers/parsePhone';

const PaxUpdateCardContactDetails = ({
  paxContact,
  sectionCardStyle,
  classes
}) => {
  return (
    <div>
      <Card>
        <CardHeader
          style={sectionCardStyle.header}
          title={
            <Typography align="center" variant="body2">
              Contact Details
            </Typography>
          }
        />
        <CardContent>
          <div>
            <InputField
              name="contact.emailAddress"
              validate={email}
              className={classes.wideField}
              placeholder="Email Address"
              maxLength={200}
              type="email"
              disabled={Object.keys(paxContact).length === 0}
            />
          </div>
          <div>
            <InputField
              name="contact.travellingPhoneNumber"
              placeholder="Travelling Phone Number"
              validate={(value, allValues, meta) =>
                !meta.pristine && phone(value)
              }
              parse={parsePhone}
              className={classes.wideField}
              maxLength={20}
              helperText="+61370102496"
              type="tel"
              disabled={Object.keys(paxContact).length === 0}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

PaxUpdateCardContactDetails.propTypes = {
  paxContact: PropTypes.object
};

export default withStyles(styles)(PaxUpdateCardContactDetails);
