import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import themes from './TripReportField.styles';
import required from '../../common/forms/validators/required';

class TripReportAccommodationFields extends React.Component {
  render() {
    const { classes, submitting } = this.props;

    return (
      <Fragment>
        <div>
          <InputField
            name="name"
            placeholder="Accommodation name"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="location"
            placeholder="Location"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Comments"
            multiline={true}
            validate={required}
            rows={6}
            rowsMax={6}
            maxLength={1000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

TripReportAccommodationFields.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool
};

export default withStyles(themes)(TripReportAccommodationFields);
