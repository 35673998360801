import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addMessage } from '../../common/actions/globalMessages';
import Router from './Router';

const RouterContainer = ({ actions }) => {
  return <Router actions={actions} />;
};

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        addMessage: addMessage
      },
      dispatch
    )
  }
});

export default connect(undefined, mapDispatchToProps)(RouterContainer);
