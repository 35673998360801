import * as crewRoles from './crewRoles';
import * as crew from './crew';
import * as healthSafetyIssues from './healthSafetyIssues';
import * as medication from './medication';
import * as meal from './meal';
import * as accommodation from './accommodation';
import * as possibleComplaints from './possibleComplaints';
import * as responsibleTravel from './responsibleTravel';
import * as activity from './activity';
import * as transport from './transport';
import * as transportTypes from './transportTypes';
import * as userResponses from './userResponses';
import * as leaderNote from './leaderNote';
import * as paxTripNote from './paxTripNote';
import * as comment from './comment';
import * as overallTripRating from './overallTripRating';
import * as groupDynamicRating from './groupDynamicRating';
import * as costChange from './costChange';
import * as currencies from './currencies';
import * as responsibleTravelCategories from './responsibleTravelCategories';

export default {
  crewRoles,
  crew,
  healthSafetyIssues,
  medication,
  meal,
  accommodation,
  possibleComplaints,
  responsibleTravel,
  activity,
  transport,
  transportTypes,
  userResponses,
  leaderNote,
  comment,
  overallTripRating,
  groupDynamicRating,
  paxTripNote,
  costChange,
  currencies,
  responsibleTravelCategories
};
