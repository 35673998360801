import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import themes from './TripReportField.styles';
import SingleSelect from '../../common/forms/SingleSelect';
import required from '../../common/forms/validators/required';

class TripReportResponsibleTravelFields extends React.Component {
  render() {
    const { classes, responsibleTravelCategories, submitting } = this.props;

    const categories = responsibleTravelCategories
      ? responsibleTravelCategories
          .map(category => ({
            value: category.category,
            label: category.displayText
          }))
          .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
      : [];

    // Other must be at the end
    categories.push(
      categories.splice(
        categories.findIndex(x => x.value == 'OTHER'),
        1
      )[0]
    );

    return (
      <Fragment>
        <div>
          <div>
            <SingleSelect
              name="category"
              label="Type"
              validate={required}
              searchable={false}
              options={categories}
              className={classes.wideField}
              disabled={submitting}
            />
          </div>
          <InputField
            name="location"
            placeholder="Location"
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Comments"
            multiline={true}
            validate={required}
            rows={6}
            rowsMax={6}
            maxLength={1000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

TripReportResponsibleTravelFields.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool
};

export default withStyles(themes)(TripReportResponsibleTravelFields);
