import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

class AlertDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonsDisabled: false
    };
  }

  handleDisableButtons = value => {
    this.setState({ buttonsDisabled: value });
  };

  render() {
    const { message, title, closeText, close, open } = this.props;

    return (
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
        {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            onClick={close}
            disabled={this.state.buttonsDisabled}
          >
            {closeText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  closeText: PropTypes.string,
  close: PropTypes.func.isRequired
};

AlertDialog.defaultProps = {
  closeText: 'OK'
};

export default AlertDialog;
