import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import selectors from '../selectors';
import PaxListConfirmedByBooking from '../components/PaxListConfirmedByBooking';
import { useParams } from 'react-router-dom';

const PaxListConfirmedByBookingContainer = ({ onChange, paxList }) => {
  return (
    paxList && (
      <PaxListConfirmedByBooking onChange={onChange} paxList={paxList} />
    )
  );
};

PaxListConfirmedByBookingContainer.propTypes = {
  bookingId: PropTypes.number,
  paxId: PropTypes.number,
  onChange: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const filterPaxList = selectors.pax.getConfirmedPaxByBookingId(
    state,
    props.bookingId,
    props.paxId
  );

  return {
    paxList: filterPaxList
  };
};

// You can also add the useParams hook if you need to access route parameters
const ConnectedPaxListConfirmedByBookingContainer = props => {
  const { bookingId, paxId } = useParams();

  return (
    <PaxListConfirmedByBookingContainer
      {...props}
      bookingId={Number(bookingId)}
      paxId={Number(paxId)}
    />
  );
};

export default connect(mapStateToProps)(
  ConnectedPaxListConfirmedByBookingContainer
);
