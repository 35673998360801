import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorIcon from '../../common/components/ErrorIcon';
import selectStyles from '../../common/forms/Select.styles';
import { Field } from 'react-final-form';

class InputAdapter extends React.Component {
  handleChange(input, options) {
    return event => {
      let values = event.target.value;
      if (values.some(value => value === 'allItems')) {
        if (values.length - 1 === options.length) {
          values = [];
        } else {
          values = options.map(o => o.value);
        }
      }

      input.onChange(values);
    };
  }

  render() {
    const { input, meta, classes, ...rest } = this.props;
    const error = meta.touched && meta.error;
    const hasError =
      error && meta.error !== undefined && input.value.length == 0;
    const helperText = error ? meta.error : rest.helperText;
    const helperTextStyle =
      helperText && helperText !== ''
        ? classes.selectHelperText
        : classes.selectHiddenElement;

    const MenuProps = {
      PaperProps: { className: classes.multiSelectList },
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
      getContentAnchorEl: null
    };

    return (
      <div className={classes.container}>
        <InputLabel shrink={true} error={hasError}>
          {rest.placeholder}
        </InputLabel>

        <Select
          multiple
          {...input}
          error={hasError}
          disabled={rest.disabled}
          className={rest.disabled ? classes.progressiveLoading : ''}
          MenuProps={MenuProps}
          onChange={this.handleChange(input, rest.options)}
          renderValue={selected => (
            <div
              className={
                rest.disabled
                  ? classes.selectHiddenElement
                  : classes.selectRenderValue
              }
            >
              {selected
                .map(
                  selectedOption =>
                    rest.options.find(p => p.value == selectedOption).label
                )
                .join(', ')}
            </div>
          )}
        >
          {rest.selectAll && (
            <MenuItem key="AllItems" value="allItems">
              <Checkbox checked={input.value.length === rest.options.length} />
              <ListItemText
                primary={rest.selectAllText}
                className={classes.selectAllText}
              />
            </MenuItem>
          )}
          {rest.options.map(option => (
            <MenuItem key={option.label} value={option.value}>
              <Checkbox checked={input.value.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>

        <InputLabel error={hasError} shrink={false} className={helperTextStyle}>
          {hasError && <ErrorIcon />}
          {helperText}
        </InputLabel>
      </div>
    );
  }
}

const InputAdapterStyled = withStyles(selectStyles)(InputAdapter);

class MultipleSelect extends React.Component {
  render() {
    const {
      name,
      placeholder,
      helperText,
      options,
      disabled = false,
      validate,
      selectAll,
      selectAllText
    } = this.props;

    return (
      <div>
        <Field
          component={InputAdapterStyled}
          type="input"
          name={name}
          placeholder={placeholder}
          helperText={helperText}
          options={options}
          disabled={disabled}
          validate={validate}
          selectAll={selectAll}
          selectAllText={selectAllText}
          format={value => (Array.isArray(value) ? value : [])}
        />
      </div>
    );
  }
}

MultipleSelect.defaultProps = {
  selectAllText: 'Select all'
};

MultipleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  selectAll: PropTypes.bool,
  selectAllText: PropTypes.string
};

export default MultipleSelect;
