import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Field } from 'react-final-form';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '../../common/components/ErrorIcon';
import InputStyles from './InputField.module.css';
import styles from './DateField.styles';
import { format } from 'date-fns';

const formatDateLabel = date => {
  return date
    ? format(date, process.env.REACT_APP_DATE_FORMAT || 'MM/dd/yyyy')
    : '';
};

class FieldAdapter extends React.Component {
  render() {
    const { input, meta, ...rest } = this.props;

    const { value } = rest;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          clearable
          {...input}
          {...rest}
          value={value || null}
          format={process.env.REACT_APP_DATE_FORMAT || 'MM/dd/yyyy'}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

const InputAdapter = withStyles(styles)(
  ({ input, meta, classes, className, ...rest }) => {
    const error = meta.touched && meta.error;
    const hasError = Boolean(error);
    const helperText = error ? meta.error : rest.helperText;

    return (
      <div className={classnames(className, classes.root)}>
        <InputLabel
          className={classnames(classes.label, { [classes.error]: hasError })}
          shrink={true}
          style={{ color: hasError ? 'red' : undefined }}
        >
          {rest.placeholder}
        </InputLabel>

        <Input
          inputComponent={FieldAdapter}
          inputProps={{
            ...input,
            ...rest,
            error: hasError
          }}
          className={classnames({
            [classes.progressiveLoading]: rest.disabled,
            [classes.errorInput]: hasError
          })}
          error={hasError}
        />

        <FormHelperText error={hasError} className={classes.helperText}>
          {hasError && <ErrorIcon />}
          {helperText}
        </FormHelperText>
      </div>
    );
  }
);

class DateField extends React.Component {
  parseDate = value => {
    const date = new Date(value);

    return format(date, process.env.REACT_APP_DATE_FORMAT || 'MM/dd/yyyy');
  };

  render() {
    const {
      name,
      required,
      classes,
      className,
      disableFutureDate,
      placeholder,
      maxDate = new Date('2100-01-01'),
      minDate = new Date('1900-01-01'),
      validate,
      helperText,
      disabled
    } = this.props;

    return (
      <Field
        name={name}
        className={classnames(classes.root, className)}
        component={InputAdapter}
        validate={validate}
        placeholder={placeholder}
        helperText={helperText}
        maxDate={maxDate}
        minDate={minDate}
        disableFuture={disableFutureDate}
        parse={value => value && this.parseDate(value)}
        disabled={disabled}
        style={{ width: '100%' }}
      />
    );
  }
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  disableFutureDate: PropTypes.bool,
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  disabled: PropTypes.bool
};

export default withStyles(styles)(DateField);
