import { detect } from 'detect-browser';

const iosDownload = (blob, fileName, fileType) => {
  let blobPDF = blob.slice(0, blob.size, fileType.mimeType);
  let reader = new FileReader();

  reader.fileName = fileName;
  reader.onload = function(e) {
    window.location.href = reader.result;
  };

  reader.readAsDataURL(blobPDF);
};

const defaultDownload = (blob, fileName, fileType) => {
  let anchor = document.createElement('a');
  let objectUrl = window.URL.createObjectURL(blob);

  anchor.href = objectUrl;
  anchor.download = `${fileName}.${fileType.extension}`;
  anchor.target = '_blank';
  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(objectUrl);
};

export default (blob, fileName, fileType) => {
  const browser = detect();

  if (
    browser.os === 'iOS' ||
    (browser.os === 'Mac OS' && browser.name === 'safari')
  ) {
    iosDownload(blob, fileName, fileType);
  } else {
    defaultDownload(blob, fileName, fileType);
  }
};
