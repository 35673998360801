import { combineReducers } from 'redux';
import crewRoles from './crewRoles';
import crew from './crew';
import healthSafetyIssues from './healthSafetyIssues';
import medication from './medication';
import meal from './meal';
import accommodation from './accommodation';
import possibleComplaints from './possibleComplaints';
import responsibleTravel from './responsibleTravel';
import activity from './activity';
import leaderNote from './leaderNote';
import comment from './comment';
import overallTripRating from './overallTripRating';
import groupDynamicRating from './groupDynamicRating';
import transport from './transport';
import userResponses from './userResponses';
import paxTripNote from './paxTripNote';
import currencies from './currencies';
import costChange from './costChange';
import responsibleTravelCategories from './responsibleTravelCategories';

export default combineReducers({
  crewRoles,
  crew,
  healthSafetyIssues,
  medication,
  meal,
  accommodation,
  possibleComplaints,
  responsibleTravel,
  activity,
  transport,
  userResponses,
  comment,
  overallTripRating,
  groupDynamicRating,
  leaderNote,
  paxTripNote,
  costChange,
  currencies,
  responsibleTravelCategories
});
