import * as actionTypes from '../actionTypes/userAccess';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.USER_ACCESS_GET_SUCCESS:
      return action.payload.userAccess;
    default:
      return state;
  }
};
