import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import required from '../../common/forms/validators/required';

const themes = () => ({
  wideField: {
    margin: '5px 0px 5px 0px',
    width: '100%'
  }
});

class TripReportLeaderNoteFields extends React.Component {
  render() {
    const { classes, submitting } = this.props;

    return (
      <Fragment>
        <div>
          <InputField
            name="location"
            placeholder="Location"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Changes/Additions"
            multiline={true}
            validate={required}
            rows={6}
            rowsMax={6}
            maxLength={1000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

TripReportLeaderNoteFields.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool
};

export default withStyles(themes)(TripReportLeaderNoteFields);
