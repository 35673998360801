export default class UserAccess {
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/userAccess';
  }

  async get() {
    const resourcePath = `${this.baseResourcePath}`;
    let data = await this.service.get(resourcePath);
    let userAccess = data.body;
    return userAccess;
  }
}
