export default function(values) {
  if (values.pax == undefined) {
    values['passengers'] = undefined;
  }

  if (!Array.isArray(values.pax)) {
    return;
  }

  values['passengers'] = values.pax.map(p => {
    return { paxId: parseInt(p) };
  });
}
