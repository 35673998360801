import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';
import selectors from '../selectors';
import CovidQuestionnaire from '../components/CovidQuestionnaire';
import { targetType } from '../../common/constants/targetType';
import systemConfigSelectors from '../../systemConfigs/selectors';
import { IS_LEADER_PORTAL_COVID_SURVEY_ENABLED } from '../../common/constants/systemConfigCode';
import { ROUTE_PAXLISTUPDATE } from '../../common/constants/routes';
import createLink from '../../common/helpers/createLink';
import { useParams } from 'react-router-dom';

const targetId = targetType.PRE_TRIP_PAX_COVID_TEST;

const PreTripCovidTestPaxContainer = ({
  actions,
  covidQuestionnaire,
  covidQuestionnaireResponses,
  isLeaderPortalCovidSurveyEnabled,
  statePax
}) => {
  const { departureId, id: paxId } = useParams();

  useEffect(() => {
    loadData();
  }, [departureId, paxId]);

  const loadData = () => {
    actions.getCovidQuestionnaireResponses(departureId, paxId, targetId);
    actions.getCovidQuestionnaire(targetId);
    actions.getPax(paxId, departureId);
  };

  const getFirstPageContent = () => (
    <div>
      <p>
        <b>Record Covid Health Screening</b>
      </p>
      <p>Why are you being asked to provide this information?</p>
      <p>
        The safety and well-being of our customers, leaders and suppliers is our
        highest priority. Before you depart on a trip, you are required to
        complete COVID-19 Health Screening. We are collecting this information
        to ensure that you are fit to travel and for the purpose of contact
        tracing in the event of suspected COVID-19 case during the trip. Your
        information will not be released to the public or shared with any third
        parties except for the relevant health departments who will use it to
        contact people who may have been exposed to covid-19 and in some
        circumstances, certain third-party suppliers that require this
        information to facilitate your booking. This may also include your name
        and contact details.
      </p>
    </div>
  );

  const getInstructions = () => (
    <div>
      <p style={{ marginTop: 0 }}>
        Customer may not have met the necessary requirements for travel and may
        be unable to join the trip. Please contact your Operations Manager. If
        the customer is exempt from vaccination then please ignore.
      </p>
    </div>
  );

  let covidQuestionnairePaxResponse = null;

  if (covidQuestionnaireResponses) {
    covidQuestionnairePaxResponse =
      covidQuestionnaireResponses.covidQuestionnairePaxResponse;
  }

  const returnUrl = createLink(ROUTE_PAXLISTUPDATE, {
    id: departureId
  });

  return (
    isLeaderPortalCovidSurveyEnabled &&
    covidQuestionnaire &&
    covidQuestionnaireResponses &&
    statePax.pax && (
      <CovidQuestionnaire
        covidQuestionnaire={covidQuestionnaire}
        covidQuestionnaireResponses={covidQuestionnairePaxResponse}
        departureId={departureId}
        submit={actions.submitCovidQuestionnaire}
        returnURL={returnUrl}
        instructionTitle={'Instructions'}
        confirmatonTitle={'Covid Health Screening Confirmation'}
        confirmationMessage={
          'Customer may be unfit to travel, are you sure you want to submit?'
        }
        instructionContent={getInstructions()}
        firstPageContent={getFirstPageContent()}
        specialMessage={
          statePax.pax.age >= 70
            ? ' Passenger is 70+ at the time of travel and must provide proof of vaccination unless has a pre-approved exemption.'
            : null
        }
        crewOrPaxFullName={statePax.pax.fullNameWithTitle}
        age={statePax.pax.age}
      />
    )
  );
};

PreTripCovidTestPaxContainer.propTypes = {
  covidQuestionnaire: PropTypes.object,
  actions: PropTypes.shape({
    getCovidQuestionnaire: PropTypes.func.isRequired,
    getCovidQuestionnaireResponses: PropTypes.func.isRequired,
    submitCovidQuestionnaire: PropTypes.func.isRequired,
    getPax: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  covidQuestionnaireResponses: selectors.covidQuestionnaireResponses.getCovidQuestionnaireResponses(
    state
  ),
  covidQuestionnaire: selectors.covidQuestionnaire.getCovidQuestionnaire(state),
  isLeaderPortalCovidSurveyEnabled: systemConfigSelectors.systemConfigs.getIsLeaderPortalCovidSurveyEnabled(
    state,
    IS_LEADER_PORTAL_COVID_SURVEY_ENABLED
  ),
  statePax: selectors.selectedPax.getSelectedDeparturePax(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCovidQuestionnaireResponses:
        actions.covidQuestionnaireResponses.getCovidQuestionnaireResponses,
      getCovidQuestionnaire: actions.covidQuestionnaire.getCovidQuestionnaire,
      submitCovidQuestionnaire: request => async (dispatch, getState) => {
        const { id, departureId } = useParams();
        await actions.covidQuestionnaire.submitCovidQuestionnaire(
          id,
          departureId,
          request
        )(dispatch);
      },
      getPax: actions.selectedPax.getSelectedDeparturePax
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreTripCovidTestPaxContainer);
