export const ROUTE_INDEX = '/';

export const ROUTE_DEPARTURES = '/departures';
export const ROUTE_PAX = '/departures/:id/pax';
export const ROUTE_DEPARTUREREPORT = '/departures/:id/departureReport';
export const ROUTE_PAXLISTUPDATE = '/departures/:id/paxUpdate';
export const ROUTE_PAXUPDATE = '/departures/:departureId/paxUpdate/:id';
export const ROUTE_DEPARTURETRIPREPORT = '/departures/:id/tripReport';
export const ROUTE_DEPARTURESUMMARYVIEW = '/departures/:id/viewSummary';
export const ROUTE_LEADERACTIONS = '/departures/:id/leaderActions';
export const ROUTE_TRIPREPORTUPLOAD = '/tripReport/:id/uploadSummary';
export const ROUTE_SHAREPOINTFILE = '/departures/:id/sharepointfileuploads';
export const ROUTE_NOTFOUND = '/NotFound';
export const ROUTE_ACCESSDENIED = '/AccessDenied';
export const ROUTE_PAXQUESTIONNAIRE =
  '/departures/:departureId/paxquestionnaire/:id';
export const ROUTE_CREWQUESTIONNAIRE =
  '/departures/:departureId/crewquestionnaire';
export const ROUTE_PAX_PRE_TRIP_COVID_TEST =
  '/departures/:departureId/pretripcovidtest/:id';
