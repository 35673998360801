import * as actionTypes from '../actionTypes/leaderNote';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import TripReportSectionBaseAction from './TripReportSectionBaseAction';

export default class LeaderNoteAction extends TripReportSectionBaseAction {
  constructor() {
    super(
      LeaderPortalService.TripReport.LeaderNote,
      actionTypes,
      'Leader notes'
    );
  }
}
