import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import { withStyles } from '@material-ui/core/styles';
import themes from './TripReportField.styles';
import SingleSelect from '../../common/forms/SingleSelect';
import required from '../../common/forms/validators/required';

class TripReportTransportFields extends React.Component {
  render() {
    const { classes, userResponses, transportTypes, submitting } = this.props;

    const responses = userResponses
      ? userResponses.map(response => ({
          value: response.response,
          label: response.responseText
        }))
      : [];

    const types = transportTypes
      ? transportTypes.map(type => ({
          value: type.type,
          label: type.displayText
        }))
      : [];

    return (
      <Fragment>
        <div>
          <SingleSelect
            name="type"
            label="Type"
            validate={required}
            searchable={false}
            options={types}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="registrationNumber"
            placeholder="Registration Number"
            maxLength={16}
            className={classes.wideField}
            disabled={submitting}
            validate={(value, allValues) =>
              allValues['type'] === 'DMC_FLEET' && required(value)
            }
          />
        </div>
        <div>
          <InputField
            name="vehicle"
            placeholder="Vehicle"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="location"
            placeholder="Location (add from/to, if necessary)"
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <SingleSelect
            name="workingSeatBeltsAvailable"
            label="All seatbelts available and working"
            validate={required}
            searchable={false}
            options={responses}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Comments"
            multiline={true}
            validate={required}
            rows={6}
            rowsMax={6}
            maxLength={1000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

export default withStyles(themes)(TripReportTransportFields);
