import * as actionTypes from '../actionTypes/countries';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';

export const countriesGetRequest = () => ({
  type: actionTypes.COUNTRIES_GET_REQUEST,
  meta: {
    disableLoadingBar: true
  }
});

export const countriesGetSuccess = countries => ({
  type: actionTypes.COUNTRIES_GET_SUCCESS,
  payload: {
    countries
  },
  meta: {
    disableLoadingBar: true
  }
});

export const getCountries = () => async dispatch => {
  const data = await LeaderPortalService.Countries.get();

  dispatch(countriesGetSuccess(data.countries));
};
