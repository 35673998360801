export default class LeaderActions {
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/departures';
  }

  getDeparturePaxReviewDetail(departureId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/DeparturePaxReviewDetail`;

    return this.service.get(resourcePath);
  }

  postdeparturePaxReviewDetail(departureId, data) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/departurePaxReviewSave`;
    return this.service.post(resourcePath, data);
  }
}
