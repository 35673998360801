import React from 'react';
import ErrorMessage from './ErrorMessage';

const Unauthorized401 = () => (
  <ErrorMessage
    code="401"
    name="Access Denied"
    message="You don't have access to use the Leader Portal. Please contact your manager."
  />
);

export default Unauthorized401;
