export default {
  icon: {
    float: 'left',
    marginRight: '5px'
  },
  iconContainer: {
    padding: '5px 10px 5px 10px'
  },
  defaultTypography: {
    display: 'inline'
  }
};
