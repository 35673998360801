import * as actionTypes from '../actionTypes/pax';

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PAX_INSURANCE_GET_SUCCESS:
      return action.payload.paxInsuranceInfo;
    case actionTypes.PAX_INSURANCE_CLEAR:
      return {};
    default:
      return state;
  }
};
