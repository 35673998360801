import { combineReducers } from 'redux';
import departures from '../departures';
import tripReport from '../tripReport';
import systemConfigs from '../systemConfigs';
import userAccess from '../userAccess';
import globalMessages from '../common/reducers/globalMessages';
import { loadingBarReducer } from 'react-redux-loading-bar';

const rootReducer = combineReducers({
  departures: departures.reducers,
  tripReport: tripReport.reducers,
  globalMessages: globalMessages,
  loadingBar: loadingBarReducer,
  systemConfigs: systemConfigs.reducers,
  userAccess: userAccess.reducers
});

export default rootReducer;
