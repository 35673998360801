import * as actionTypes from '../actionTypes/covidQuestionnaire';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';
import { PAX_CLEAR } from '../actionTypes/pax';

export const covidQuestionnaireGetRequest = () => ({
  type: actionTypes.COVIDQUESTIONAIR_GET_REQUEST,
  payload: {}
});

export const covidQuestionnaireGetSuccess = covidQuestionnaire => ({
  type: actionTypes.COVIDQUESTIONAIR_GET_SUCCESS,
  payload: {
    covidQuestionnaire
  }
});

export const covidQuestionnairePostRequest = (departureId, paxId) => ({
  type: actionTypes.COVIDQUESTIONAIR_POST_REQUEST,
  payload: {
    paxId,
    departureId
  }
});

export const covidQuestionnairePostSuccess = covidQuestionnaireResponse => ({
  type: actionTypes.COVIDQUESTIONAIR_POST_SUCCESS,
  payload: {
    covidQuestionnaireResponse
  }
});

export const covidQuestionnairePostFailure = () => ({
  type: actionTypes.COVIDQUESTIONAIR_POST_FAILURE
});

export const getCovidQuestionnaire = targetId => async dispatch => {
  const data = await LeaderPortalService.CovidQuestionnaire.get(targetId);
  dispatch(covidQuestionnaireGetSuccess(data.body));
};

export const submitCovidQuestionnaire = (
  paxId,
  departureId,
  values
) => async dispatch => {
  dispatch(covidQuestionnairePostRequest(paxId, departureId));
  try {
    await LeaderPortalService.CovidQuestionnaire.postPaxQuestionnaire(
      paxId,
      departureId,
      values
    );
    dispatch(addMessage('Questionnaire Submitted.', messageTypes.success));
    dispatch(paxClear());
    dispatch(covidQuestionnairePostSuccess());
  } catch (e) {
    dispatch(
      addMessage('Unable to submit, Please try again and contact your office')
    );
    dispatch(covidQuestionnairePostFailure());
  }
};

export const paxClear = () => ({
  type: PAX_CLEAR
});

export const submitCrewCovidQuestionnaire = (
  departureId,
  values
) => async dispatch => {
  dispatch(covidQuestionnairePostRequest(departureId));
  try {
    await LeaderPortalService.CovidQuestionnaire.postCrewQuestionnaire(
      departureId,
      values
    );
    dispatch(addMessage('Questionnaire Submitted.', messageTypes.success));
    dispatch(covidQuestionnairePostSuccess());
  } catch (e) {
    dispatch(
      addMessage('Unable to submit, Please try again and contact your office')
    );
    dispatch(covidQuestionnairePostFailure());
  }
};
