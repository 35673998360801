import * as React from 'react';
import PropTypes from 'prop-types';
import { CardHeader } from '@material-ui/core/';
import AvatarLogo from '../../common/components/AvatarLogo';
import ErrorIcon from '../../common/components/ErrorIcon';
import Grid from '@material-ui/core/Grid';
import styles from './DepartureCardHeader.styles';
import { withStyles } from '@material-ui/core/styles';
import { fileUploadStatus } from '../../common/constants/fileUploadStatus';

const DepartureCardHeader = ({
  departure,
  handleClick,
  classes,
  cardExpanded
}) => {
  return (
    <CardHeader
      avatar={
        <AvatarLogo
          brand={departure.brand}
          imgId={'img.header_' + departure.departureCode}
        />
      }
      title={
        departure &&
        (departure.tripReportUploadStatus === fileUploadStatus.FAILED ||
          departure.departureSummaryUploadStatus ===
            fileUploadStatus.FAILED) ? (
          <div>
            <Grid container spacing={0}>
              <Grid item xs={10} sm={10}>
                <span>{departure.departureCode}</span>
              </Grid>
              <Grid item xs={2} sm={2}>
                <div className={classes.departureErrorIconContainer}>
                  <ErrorIcon size="normal" />
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          departure.departureCode
        )
      }
      subheader={departure.productName}
      onClick={handleClick}
      id={'departure.header_' + departure.departureCode}
    />
  );
};

DepartureCardHeader.propTypes = {
  departure: PropTypes.object,
  handleClick: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(DepartureCardHeader);
