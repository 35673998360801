import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './CovidQuestionnaire.styles';
import FormRadioGroup from '../../common/components/FormRadioGroup';
import FormRadio from '../../common/components/FormRadio';
import { FormControlLabel } from '@material-ui/core';

class CovidQuestionRepresentationRadioButton extends React.Component {
  getAnswers(
    covidQuestionAnswerText,
    covidQuestionnaireQuestionId,
    classes,
    covidQuestionResponse,
    answer,
    handleAnswerClick
  ) {
    return (
      <FormControlLabel
        className={classes.RadioButtonLabel}
        control={<FormRadio />}
        disabled={covidQuestionResponse != null}
        label={covidQuestionAnswerText.answerText}
        value={covidQuestionAnswerText.covidQuestionAnswerTextId}
        onClick={() =>
          handleAnswerClick(
            covidQuestionResponse == null && covidQuestionnaireQuestionId,
            covidQuestionAnswerText.covidQuestionAnswerTextId,
            covidQuestionAnswerText.isAlertingAnswer,
            covidQuestionResponse
          )
        }
      />
    );
  }

  render(props) {
    const {
      covidQuestionnaireQuestionId,
      covidQuestionAnswerTexts,
      covidQuestionResponse,
      classes,
      handleAnswerClick,
      answer,
      age
    } = this.props;

    const radioButtonList = [];
    covidQuestionAnswerTexts &&
      covidQuestionAnswerTexts.forEach(covidQuestionAnswerText => {
        if (
          !covidQuestionAnswerText.validityAgeLimit ||
          !age ||
          age < covidQuestionAnswerText.validityAgeLimit
        ) {
          radioButtonList.push(
            this.getAnswers(
              covidQuestionAnswerText,
              covidQuestionnaireQuestionId,
              classes,
              covidQuestionResponse,
              answer,
              handleAnswerClick
            )
          );
        }
      });
    return (
      <div className={classes.QuestionRadioButtonContainer}>
        <FormRadioGroup
          value={
            covidQuestionResponse
              ? covidQuestionResponse.covidQuestionAnswerTextId
              : answer
          }
        >
          {radioButtonList}
        </FormRadioGroup>
      </div>
    );
  }
}

export default withStyles(styles)(CovidQuestionRepresentationRadioButton);
