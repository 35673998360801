import * as actionTypes from '../actionTypes/paxChanges';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURE_PAX_CHANGES_GET_SUCCESS:
      return action.payload.paxChanges;
    case actionTypes.DEPARTURE_PAX_CHANGES_CLEAR:
      return null;
    default:
      return state;
  }
};
