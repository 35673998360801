import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  hiddenField: {
    display: 'none'
  }
};

// This is a work around to disable autofill and autocomplete
const DisableAutoFill = ({ classes }) => (
  <input type="text" name="fname" className={classes.hiddenField} />
);

export default withStyles(styles)(DisableAutoFill);
