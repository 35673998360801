import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResponsibleTravelAction from '../actions/ResponsibleTravelAction';
import TripReportSection from '../components/TripReportSection';
import TripReportResponsibleTravelFields from '../components/TripReportResponsibleTravelFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import '../../common/helpers/stringExtensions';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportResponsibleTravelContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subTitleText:
        'Include any highlights or challenges with responsible travel.'
    };
  }

  renderSubHeader = responsibleTravel => {
    return (
      <div style={styles.trimText}>
        <span id={'responsibleTravel.header.category_' + responsibleTravel.id}>
          {
            this.props.responsibleTravelCategories.find(
              o => o.category === responsibleTravel.category
            ).displayText
          }
        </span>
        {responsibleTravel.location && (
          <span
            id={'responsibleTravel.header.location_' + responsibleTravel.id}
          >
            {' • '}
            {responsibleTravel.location}
          </span>
        )}
      </div>
    );
  };

  renderSectionFields = submitting => (
    <TripReportResponsibleTravelFields
      responsibleTravelCategories={this.props.responsibleTravelCategories}
      submitting={submitting}
    />
  );

  renderSectionCard = responsibleTravel => {
    return (
      <TripReportSectionCard
        sectionName="responsibleTravel"
        itemName="responsible travel"
        title={responsibleTravel.comments}
        subTitle={this.state.subTitleText}
        subHeader={this.renderSubHeader}
        cardContent={() => {
          return null;
        }}
        item={responsibleTravel}
        itemId={responsibleTravel.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateResponsibleTravel}
        delete={() =>
          this.props.actions.deleteResponsibleTravel(
            this.props.departure.id,
            responsibleTravel.id
          )
        }
        deleteMessage={responsibleTravel =>
          `Are you sure you want to delete responsible travel "${responsibleTravel.comments.trunc(
            60
          )}"?`
        }
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const {
      departure,
      responsibleTravelList,
      responsibleTravelCategories
    } = this.props;

    return (
      departure &&
      responsibleTravelList &&
      responsibleTravelCategories && (
        <TripReportSection
          subTitle={this.state.subTitleText}
          sectionName="ResponsibleTravel"
          itemName="responsible travel"
          noItemsMessage="No responsible travel entries have been added yet"
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={responsibleTravelList}
          insert={this.props.actions.insertResponsibleTravel}
        />
      )
    );
  }
}

TripReportResponsibleTravelContainer.propTypes = {
  departure: PropTypes.object,
  responsibleTravelList: PropTypes.array,
  responsibleTravelCategories: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    insertResponsibleTravel: PropTypes.func,
    deleteResponsibleTravel: PropTypes.func,
    updateResponsibleTravel: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {};
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      insertResponsibleTravel: values => async dispatch => {
        await new ResponsibleTravelAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteResponsibleTravel: (
        departureId,
        responsibleTravelId
      ) => async dispatch => {
        await new ResponsibleTravelAction().delete(
          departureId,
          responsibleTravelId
        )(dispatch);
      },
      updateResponsibleTravel: values => async dispatch => {
        await new ResponsibleTravelAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TripReportResponsibleTravelContainer)
);
