import React from 'react';
import PropTypes from 'prop-types';
import GlobalMessageCard from '../../common/components/GlobalMessageCard';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { Element, animateScroll as scroll } from 'react-scroll';
import FormDialog from '../../common/components/FormDialog';
import styles from '../../common/styles/Section.styles';
import { Card, CardContent } from '@material-ui/core/';
import cardStyles from '../../common/styles/Card.module.css';
import Typography from '@material-ui/core/Typography';

class TripReportSection extends React.Component {
  state = {
    open: false,
    action: null
  };

  openAddDialog = () => {
    this.setState({ open: true });
  };

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  closeAddDialog = e => {
    this.setState({ open: false });

    // Added record scroll to the bottom
    if (e === undefined) {
      scroll.scrollToBottom();
    }
  };

  renderAddButton = (classes, itemName) => {
    return (
      <div className={classes.addButtonContainer}>
        <Button
          raised
          color="primary"
          variant="contained"
          className={classes.wideButton}
          onClick={this.openAddDialog}
        >
          Add {itemName}
        </Button>
      </div>
    );
  };

  renderScrollToTopButton = classes => {
    return (
      <div>
        <Button
          outlined="true"
          color="secondary"
          className={classes.wideButton}
          onClick={this.scrollToTop}
        >
          <Icon>keyboard_arrow_up</Icon> Scroll to top
        </Button>
      </div>
    );
  };

  render() {
    const {
      classes,
      itemName,
      subTitle,
      items,
      preContent,
      noItemsMessage,
      sectionCard,
      departureId
    } = this.props;

    let content = items.map(item => {
      return (
        item && (
          <Element
            key={`${itemName}_${item.id}`}
            name={`${itemName}_${item.id}`}
          >
            {sectionCard(item)}
          </Element>
        )
      );
    });

    if (items && items.length === 0) {
      content = (
        <div>
          <GlobalMessageCard
            message={
              noItemsMessage
                ? noItemsMessage
                : `There are no ${itemName}s added yet`
            }
          />
        </div>
      );
    }

    return (
      <div>
        {subTitle && (
          <Card className={cardStyles.card}>
            <CardContent>
              <Typography variant="caption">{subTitle}</Typography>
            </CardContent>
          </Card>
        )}
        {preContent && (
          <Card className={cardStyles.card}>
            <CardContent>{preContent(departureId, classes)}</CardContent>
          </Card>
        )}

        {this.renderAddButton(classes, this.props.itemName)}
        {content}

        <FormDialog
          title={`Add ${this.props.itemName}`}
          children={this.props.sectionFields}
          action={this.props.insert}
          actionText="Add"
          open={this.state.open}
          close={this.closeAddDialog}
          initialValues={this.props.defaultValues}
          format={this.props.format}
        />

        {this.renderScrollToTopButton(classes)}
      </div>
    );
  }
}

TripReportSection.propTypes = {
  classes: PropTypes.object.isRequired,
  itemName: PropTypes.string.isRequired,
  noItemsMessage: PropTypes.string,
  items: PropTypes.array.isRequired,
  sectionFields: PropTypes.func.isRequired,
  sectionCard: PropTypes.func.isRequired,
  insert: PropTypes.func.isRequired,
  preContent: PropTypes.func,
  format: PropTypes.func,
  defaultValues: PropTypes.object
};

export default withStyles(styles)(TripReportSection);
