import * as actionTypes from '../actionTypes/transfers';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';

export const departureTransfersGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_TRANSFERS_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departureTransfersGetSuccess = transfers => ({
  type: actionTypes.DEPARTURE_TRANSFERS_GET_SUCCESS,
  payload: {
    transfers
  }
});

export const getDepartureTransfers = id => async dispatch => {
  dispatch(departureTransfersGetRequest(id));

  const transfers = await LeaderPortalService.Transfers.getByDepartureId(id);

  dispatch(departureTransfersGetSuccess(transfers));
};
