import BaseService from '../BaseService';
import Departures from './Departures';
import Pax from './Pax';
import Countries from './Countries';
import Transfers from './Transfers';
import Accommodations from './Accommodations';
import Sightseeings from './Sightseeings';
import CovidQuestionnaire from './CovidQuestionnaire';
import SystemConfig from './SystemConfig';
import UserAccess from './UserAccess';
import LeaderActions from './LeaderActions';

import convertToAPIDate from '../../../common/helpers/convertToAPIDate';
import downloadPDF from '../../../common/helpers/downloadPDF';

import CrewRoles from './TripReport/CrewRoles';
import Crew from './TripReport/Crew';
import HealthSafetyIssues from './TripReport/HealthSafetyIssues';
import Medication from './TripReport/Medication';
import Meal from './TripReport/Meal';
import Accommodation from './TripReport/Accommodation';
import PossibleComplaints from './TripReport/PossibleComplaints';
import ResponsibleTravel from './TripReport/ResponsibleTravel';
import Activity from './TripReport/Activity';
import CostChange from './TripReport/CostChange';
import LeaderNote from './TripReport/LeaderNote';
import Comment from './TripReport/Comment';
import TripRating from './TripReport/TripRating';
import Transport from './TripReport/Transport';
import UserResponses from './TripReport/UserResponses';
import PaxTripNote from './TripReport/PaxTripNote';
import Currency from './TripReport/Currency';
import ResponsibleTravelCategories from './TripReport/ResponsibleTravelCategories';
import adalFetch from '../../helpers/adalFetch';

export default class LeaderPortalService extends BaseService {
  constructor() {
    super();

    this.Departures = new Departures(this);
    this.Pax = new Pax(this);
    this.Countries = new Countries(this);
    this.Transfers = new Transfers(this);
    this.Accommodations = new Accommodations(this);
    this.Sightseeings = new Sightseeings(this);
    this.CovidQuestionnaire = new CovidQuestionnaire(this);
    this.SystemConfig = new SystemConfig(this);
    this.LeaderActions = new LeaderActions(this);
    this.UserAccess = new UserAccess(this);

    this.TripReport = {
      CrewRoles: new CrewRoles(this),
      Currency: new Currency(this),
      Crew: new Crew(this),
      HealthSafetyIssues: new HealthSafetyIssues(this),
      Medication: new Medication(this),
      Meal: new Meal(this),
      Accommodation: new Accommodation(this),
      PossibleComplaints: new PossibleComplaints(this),
      ResponsibleTravel: new ResponsibleTravel(this),
      Activity: new Activity(this),
      Transport: new Transport(this),
      UserResponses: new UserResponses(this),
      LeaderNote: new LeaderNote(this),
      PaxTripNote: new PaxTripNote(this),
      Comment: new Comment(this),
      TripRating: new TripRating(this),
      CostChange: new CostChange(this),
      ResponsibleTravelCategories: new ResponsibleTravelCategories(this)
    };

    this.baseUrl = process.env.REACT_APP_LEADER_PORTAL_SERVICE_URL;
    this.fetch = adalFetch(super.fetch).bind(this);
  }

  get(href) {
    const request = this.createRequest(this.baseUrl, href, {
      method: 'GET',
      headers: { requestDate: convertToAPIDate(new Date()) }
    });

    return this.fetch(request)
      .then(this.validate)
      .then(this.extract);
  }

  getFile(href, fileName, fileType) {
    const request = this.createRequest(this.baseUrl, href, {
      method: 'GET',
      headers: {
        requestDate: convertToAPIDate(new Date())
      }
    });

    return this.fetch(request)
      .then(response => response.blob())
      .then(blob => downloadPDF(blob, fileName, fileType));
  }

  post(href, data) {
    const request = this.createRequest(this.baseUrl, href, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });

    return this.fetch(request)
      .then(this.validate)
      .then(this.extract);
  }

  patch(href, data) {
    const request = this.createRequest(this.baseUrl, href, {
      body: JSON.stringify(data),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });

    return this.fetch(request)
      .then(this.validate)
      .then(this.extract);
  }

  delete(href) {
    const request = this.createRequest(this.baseUrl, href, {
      method: 'DELETE',
      headers: {
        requestDate: convertToAPIDate(new Date()),
        'Content-Type': 'application/json;charset=utf-8'
      }
    });

    return this.fetch(request)
      .then(this.validate)
      .then(this.extract);
  }
}
