import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HealthSafetyIssuesAction from '../actions/HealthSafetyIssuesAction';
import departureActions from '../../departures/actions';
import departureSelectors from '../../departures/selectors';
import TripReportSection from '../components/TripReportSection';
import TripReportHealthSafetyIssueFields from '../components/TripReportHealthSafetyIssueFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import '../../common/helpers/stringExtensions';
import updatePassengers from '../helpers/updateTripReportPaxList';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportHealthSafetyIssueContainer extends React.Component {
  renderSubHeader = healthSafetyIssue => {
    return (
      healthSafetyIssue.location && (
        <div style={styles.trimText}>
          <span
            id={'healthSafetyIssue.header.location_' + healthSafetyIssue.id}
          >
            {healthSafetyIssue.location}
          </span>
        </div>
      )
    );
  };

  renderContent = healthSafetyIssue => {
    return (
      healthSafetyIssue.passengers &&
      healthSafetyIssue.passengers.length > 0 && (
        <span id={'healthSafetyIssue.header.paxNames_' + healthSafetyIssue.id}>
          {healthSafetyIssue.passengers
            .map(h => {
              let pax = this.props.pax.filter(p => p.id == h.paxId)[0];
              return pax && pax.fullName;
            })
            .filter(h => h != undefined)
            .join(' • ')}
        </span>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportHealthSafetyIssueFields
      pax={this.props.pax}
      submitting={submitting}
    />
  );

  renderSectionCard = healthSafetyIssue => {
    return (
      <TripReportSectionCard
        sectionName="healthSafetyIssue"
        itemName="health &amp; safety issue"
        subTitle="Include all health &amp; safety issues."
        title={healthSafetyIssue.comments}
        subHeader={this.renderSubHeader}
        cardContent={this.renderContent}
        item={healthSafetyIssue}
        itemId={healthSafetyIssue.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateHealthSafetyIssue}
        delete={() =>
          this.props.actions.deleteHealthSafetyIssue(
            this.props.departure.id,
            healthSafetyIssue.id
          )
        }
        deleteMessage={healthSafetyIssue =>
          `Are you sure you want to delete the health & safety issue "${healthSafetyIssue.comments.trunc(
            60
          )}"?`
        }
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { departure, healthSafetyIssueList } = this.props;

    return (
      departure &&
      healthSafetyIssueList && (
        <TripReportSection
          sectionName="healthSafetyIssue"
          itemName="health &amp; safety issue"
          subTitle="Include all health &amp; safety issues."
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={healthSafetyIssueList}
          insert={this.props.actions.insertHealthSafetyIssue}
        />
      )
    );
  }
}

TripReportHealthSafetyIssueContainer.propTypes = {
  departure: PropTypes.object,
  healthSafetyIssueList: PropTypes.array,
  actions: PropTypes.shape({
    insertHealthSafetyIssue: PropTypes.func,
    deleteHealthSafetyIssue: PropTypes.func,
    updateHealthSafetyIssue: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  let paxList = departureSelectors.pax.getPax(state);

  return {
    pax: paxList != null ? paxList.pax : []
  };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      getPax: departureActions.selectedDeparture.getSelectedDeparture,
      insertHealthSafetyIssue: values => async dispatch => {
        updatePassengers(values);
        await new HealthSafetyIssuesAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteHealthSafetyIssue: (
        departureId,
        healthSafetyIssueId
      ) => async dispatch => {
        await new HealthSafetyIssuesAction().delete(
          departureId,
          healthSafetyIssueId
        )(dispatch);
      },
      updateHealthSafetyIssue: values => async dispatch => {
        updatePassengers(values);
        await new HealthSafetyIssuesAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TripReportHealthSafetyIssueContainer)
);
