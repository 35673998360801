import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './CovidQuestionnaire.styles';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

class CovidQuestionRepresentationButton extends React.Component {
  getAnswers = (
    covidQuestionAnswerText,
    covidQuestionnaireQuestionId,
    classes,
    covidQuestionResponse,
    answer,
    handleAnswerClick
  ) => {
    const inputClasses = classnames({
      [classes.buttonSelected]:
        answer == covidQuestionAnswerText.covidQuestionAnswerTextId ||
        (covidQuestionResponse &&
          covidQuestionResponse.covidQuestionAnswerTextId ==
            covidQuestionAnswerText.covidQuestionAnswerTextId),
      [classes.buttonDefaultTouchDevice]: window.matchMedia('(pointer: coarse)')
        .matches,
      [classes.buttonDefault]: !window.matchMedia('(pointer: coarse)').matches
    });

    return (
      <Button
        aria-label={covidQuestionAnswerText.answerText}
        className={inputClasses}
        disabled={covidQuestionResponse != null}
        onClick={() =>
          handleAnswerClick(
            covidQuestionnaireQuestionId,
            covidQuestionAnswerText.covidQuestionAnswerTextId,
            covidQuestionAnswerText.isAlertingAnswer,
            covidQuestionResponse
          )
        }
      >
        {covidQuestionAnswerText.answerText}
      </Button>
    );
  };

  render(props) {
    const {
      covidQuestionnaireQuestionId,
      covidQuestionAnswerTexts,
      covidQuestionResponse,
      classes,
      handleAnswerClick,
      answer
    } = this.props;
    const buttonList = [];
    covidQuestionAnswerTexts &&
      covidQuestionAnswerTexts.forEach(covidQuestionAnswerText => {
        buttonList.push(
          this.getAnswers(
            covidQuestionAnswerText,
            covidQuestionnaireQuestionId,
            classes,
            covidQuestionResponse,
            answer,
            handleAnswerClick
          )
        );
      });

    return <div className={classes.QuestionButtonContainer}>{buttonList}</div>;
  }
}

export default withStyles(styles)(CovidQuestionRepresentationButton);
