import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SingleSelect from '../../common/forms/SingleSelect';
import InputLabel from '@material-ui/core/InputLabel';
import InputField from '../../common/forms/InputField';
import Grid from '@material-ui/core/Grid';
import styles from './CurrencyCombo.styles';
import { FormControl } from '@material-ui/core';
import { FormSpy } from 'react-final-form';
import required from '../../common/forms/validators/required';
import currency from '../../common/forms/validators/currency';
import parseCurrency from '../../common/forms/parsers/parseCurrency';

class CurrencyCombo extends React.Component {
  hasErrors(errors, touched) {
    return touched && errors;
  }

  renderLabel(label) {
    const { currencyName, costName } = this.props;

    return (
      <FormSpy subscription={{ errors: true, touched: true }}>
        {({ errors, touched }) => {
          // Check if either the currency or cost has any errors
          const hasError =
            this.hasErrors(errors[currencyName], touched[currencyName]) ||
            this.hasErrors(errors[costName], touched[costName]);

          return (
            <InputLabel shrink={true} error={hasError}>
              {label}
            </InputLabel>
          );
        }}
      </FormSpy>
    );
  }

  render() {
    const {
      classes,
      label,
      currencyName,
      currencySearchable = false,
      currencyOptions,
      currencyDisabled,
      costName,
      costDisabled
    } = this.props;

    return (
      <FormControl fullWidth className={classes.formControl}>
        {this.renderLabel(label)}
        <Grid container spacing={0} className={classes.fieldsContainer}>
          <Grid item xs={4} sm={4}>
            <SingleSelect
              name={currencyName}
              validate={required}
              searchable={currencySearchable}
              options={currencyOptions}
              disabled={currencyDisabled}
            />
          </Grid>
          <Grid item xs={8} sm={8}>
            <InputField
              name={costName}
              validate={currency}
              parse={parseCurrency}
              disabled={costDisabled}
              type="number"
              className={classes.cost}
            />
          </Grid>
        </Grid>
      </FormControl>
    );
  }
}

CurrencyCombo.propTypes = {
  currencyName: PropTypes.string,
  currencySearchable: PropTypes.bool,
  currencyOptions: PropTypes.array.isRequired,
  currencyClassName: PropTypes.string,
  currencyDisabled: PropTypes.bool,

  costName: PropTypes.string,
  costRequired: PropTypes.bool,
  costClassName: PropTypes.string,
  costDisabled: PropTypes.bool,
  costMaxLength: PropTypes.number,
  classes: PropTypes.object
};

export default withStyles(styles)(CurrencyCombo);
