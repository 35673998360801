import {
  getDeparturePax,
  getPassport,
  getVisa,
  getInsurance,
  getEmergencyContact,
  getContact
} from './pax';

export const getSelectedPax = (id, departureId) => (dispatch, getState) => {
  return Promise.all([
    dispatch(getDeparturePax(id, departureId)),
    dispatch(getPassport(id, departureId)),
    dispatch(getVisa(id, departureId)),
    dispatch(getInsurance(id, departureId)),
    dispatch(getEmergencyContact(id, departureId)),
    dispatch(getContact(id, departureId))
  ]);
};

export const getSelectedDeparturePax = (id, departureId) => (
  dispatch,
  getState
) => {
  return Promise.all([dispatch(getDeparturePax(id, departureId))]);
};
