import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MealAction from '../actions/MealAction';
import TripReportSection from '../components/TripReportSection';
import TripReportMealFields from '../components/TripReportMealFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import '../../common/helpers/stringExtensions';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportMealContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subTitleText:
        'Include any highlights or challenges with food or meals on the trip. List both optional and included.'
    };
  }

  renderSubHeader = meal => {
    return (
      meal.location && (
        <div style={styles.trimText}>
          <span id={'meal.header.location_' + meal.id}>{meal.location}</span>
        </div>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportMealFields submitting={submitting} />
  );

  renderSectionCard = meal => {
    return (
      <TripReportSectionCard
        sectionName="Meal"
        itemName="meals/food hygiene"
        title={meal.comments}
        subTitle={this.state.subTitleText}
        subHeader={this.renderSubHeader}
        cardContent={() => {
          return null;
        }}
        item={meal}
        itemId={meal.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateMeal}
        delete={() =>
          this.props.actions.deleteMeal(this.props.departure.id, meal.id)
        }
        deleteMessage={meal =>
          `Are you sure you want to delete meals/food hygiene "${meal.comments.trunc(
            60
          )}"?`
        }
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { departure, mealList } = this.props;

    return (
      departure &&
      mealList && (
        <TripReportSection
          subTitle={this.state.subTitleText}
          sectionName="Meal"
          itemName="meals/food hygiene"
          noItemsMessage="There are no meals/food hygiene issues added yet"
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={mealList}
          insert={this.props.actions.insertMeal}
        />
      )
    );
  }
}

TripReportMealContainer.propTypes = {
  departure: PropTypes.object,
  mealList: PropTypes.array,
  actions: PropTypes.shape({
    insertMeal: PropTypes.func,
    deleteMeal: PropTypes.func,
    updateMeal: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {};
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      insertMeal: values => async dispatch => {
        await new MealAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteMeal: (departureId, mealId) => async dispatch => {
        await new MealAction().delete(departureId, mealId)(dispatch);
      },
      updateMeal: values => async dispatch => {
        await new MealAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportMealContainer)
);
