import * as overallRatingActionTypes from '../actionTypes/overallTripRating';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import * as groupDynamicRatingactionTypes from '../actionTypes/groupDynamicRating';

export const tripReportOverallTripRatingGetRequest = () => ({
  type: overallRatingActionTypes.GET_REQUEST
});

export const tripReportOverallTripRatingGetSuccess = overallTripRating => ({
  type: overallRatingActionTypes.GET_SUCCESS,
  payload: {
    overallTripRating
  }
});

export const tripReportOverallTripRatingUpdateRequest = departureId => ({
  type: overallRatingActionTypes.UPDATE_REQUEST,
  payload: {
    departureId
  }
});

export const tripReportOverallTripRatingUpdateSuccess = overallTripRating => ({
  type: overallRatingActionTypes.UPDATE_SUCCESS,
  payload: {
    overallTripRating: overallTripRating
  }
});

export const tripReportOverallTripRatingClear = () => ({
  type: overallRatingActionTypes.CLEAR
});

export const tripReportGroupDynamicRatingGetRequest = () => ({
  type: groupDynamicRatingactionTypes.GET_REQUEST
});

export const tripReportGroupDynamicRatingGetSuccess = groupDynamicRating => ({
  type: groupDynamicRatingactionTypes.GET_SUCCESS,
  payload: {
    groupDynamicRating
  }
});

export const tripReportGroupDynamicRatingUpdateRequest = departureId => ({
  type: groupDynamicRatingactionTypes.UPDATE_REQUEST,
  payload: {
    departureId
  }
});

export const tripReportGroupDynamicRatingUpdateSuccess = groupDynamicRating => ({
  type: groupDynamicRatingactionTypes.UPDATE_SUCCESS,
  payload: {
    groupDynamicRating: groupDynamicRating
  }
});

export const tripReportGroupDynamicRatingClear = () => ({
  type: groupDynamicRatingactionTypes.CLEAR
});

function shouldFetch(state, departureId) {
  const selectedDeparture = state.departures.selectedDeparture;
  return (
    !selectedDeparture ||
    selectedDeparture.id.toString() !== departureId ||
    state.tripReport.overallTripRating == null ||
    state.tripReport.groupDynamicRating == null
  );
}

export const get = departureId => async (dispatch, getState) => {
  try {
    if (!shouldFetch(getState(), departureId)) {
      return;
    }

    dispatch(tripReportOverallTripRatingGetRequest());
    dispatch(tripReportOverallTripRatingClear());
    dispatch(tripReportGroupDynamicRatingGetRequest());
    dispatch(tripReportGroupDynamicRatingClear());

    const tripReport = await LeaderPortalService.TripReport.TripRating.get(
      departureId
    );

    const overallTripRating = tripReport.overallTripRating
      ? parseInt(tripReport.overallTripRating)
      : null;

    const groupDynamicRating = tripReport.groupDynamicRating
      ? parseInt(tripReport.groupDynamicRating)
      : null;

    dispatch(tripReportOverallTripRatingGetSuccess(overallTripRating));
    dispatch(tripReportGroupDynamicRatingGetSuccess(groupDynamicRating));
  } catch (e) {
    console.log(e);
  }
};

export const updateOverallRating = (
  departureId,
  overallTripRating
) => async dispatch => {
  dispatch(tripReportOverallTripRatingUpdateRequest(departureId));

  await LeaderPortalService.TripReport.TripRating.updateOverallTripRating(
    departureId,
    overallTripRating
  );

  dispatch(tripReportOverallTripRatingUpdateSuccess(overallTripRating));
};

export const updateGroupDynamicRating = (
  departureId,
  groupDynamicRating
) => async dispatch => {
  dispatch(tripReportGroupDynamicRatingUpdateRequest(departureId));

  await LeaderPortalService.TripReport.TripRating.updateGroupDynamicRating(
    departureId,
    groupDynamicRating
  );

  dispatch(tripReportGroupDynamicRatingUpdateSuccess(groupDynamicRating));
};
