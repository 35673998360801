import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './CovidQuestionnaire.styles';
import CovidQuestionAnswers from './CovidQuestionAnswers';

class CovidQuestion extends React.Component {
  render(props) {
    const {
      covidQuestion,
      covidQuestionResponse,
      classes,
      handleAnswerClick,
      answer,
      specialMessage,
      crewOrPaxFullName,
      age
    } = this.props;

    return (
      covidQuestion && (
        <div className={classes.QuestionContainer}>
          <div className={classes.QuestionTextContainer}>
            {crewOrPaxFullName && (
              <Typography
                className={this.props.classes.PaxName}
                variant="body1"
                gutterBottom
              >
                {crewOrPaxFullName}
              </Typography>
            )}
            <Typography
              className={classes.Typography}
              variant="body1"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              {covidQuestion.covidQuestionText}
            </Typography>
            {specialMessage && (
              <Typography
                className={this.props.classes.SpecialMessage}
                variant="body1"
                gutterBottom
                style={{ whiteSpace: 'pre-line' }}
              >
                {specialMessage}
              </Typography>
            )}
          </div>
          <CovidQuestionAnswers
            covidQuestion={covidQuestion}
            covidQuestionResponse={covidQuestionResponse}
            handleAnswerClick={handleAnswerClick}
            answer={answer}
            age={age}
          />
        </div>
      )
    );
  }
}

export default withStyles(styles)(CovidQuestion);
