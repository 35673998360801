import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './TripReportCardActions.styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';

class TripReportCardActions extends React.Component {
  constructor() {
    super();

    this.tabs = [];
  }

  state = {
    selectedTabIndex: 0
  };

  handleChange = (event, selectedTabIndex) => {
    this.setState({ selectedTabIndex });
  };

  addTab = (tabLabel, itemsLength, componentName) => {
    const { selectedTabIndex } = this.state;
    const { classes } = this.props;

    if (this.tabs.indexOf(componentName) < 0) {
      this.tabs.push(componentName);
    }

    return (
      <Tab
        label={
          itemsLength > 0 ? (
            <Badge
              classes={{
                root: classes.badgeRoot,
                badge:
                  selectedTabIndex === this.tabs.indexOf(componentName)
                    ? classes.badge
                    : classes.secondaryBadge
              }}
              color="primary"
              badgeContent={itemsLength}
            >
              {tabLabel}
            </Badge>
          ) : (
            tabLabel
          )
        }
      />
    );
  };

  renderTabs = () => {
    const {
      classes,
      crewComponent,
      healthSafetyIssueComponent,
      accommodationComponent,
      medicationComponent,
      mealComponent,
      possibleComplaintComponent,
      responsibleTravelComponent,
      activityComponent,
      transportComponent,
      leaderNoteComponent,
      commentComponent,
      paxTripNoteComponent,
      costChangeComponent
    } = this.props;

    if (
      crewComponent &&
      healthSafetyIssueComponent &&
      accommodationComponent &&
      medicationComponent &&
      mealComponent &&
      possibleComplaintComponent &&
      responsibleTravelComponent &&
      activityComponent &&
      transportComponent &&
      leaderNoteComponent &&
      commentComponent &&
      paxTripNoteComponent &&
      costChangeComponent
    ) {
      return (
        <Paper className={classes.paper}>
          <Tabs
            value={this.state.selectedTabIndex}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
          >
            {this.addTab(
              'Trip Overall',
              commentComponent.props.commentList.length,
              'commentComponent'
            )}
            {this.addTab(
              'H&S',
              healthSafetyIssueComponent.props.healthSafetyIssueList.length,
              'healthSafetyIssueComponent'
            )}
            {this.addTab(
              'Complaints',
              possibleComplaintComponent.props.possibleComplaints.length,
              'possibleComplaintComponent'
            )}
            {this.addTab(
              'Pax Trip Notes',
              paxTripNoteComponent.props.paxTripNoteList.length,
              'paxTripNoteComponent'
            )}
            {this.addTab(
              "Leader's Notes",
              leaderNoteComponent.props.leaderNoteList.length,
              'leaderNoteComponent'
            )}
            {this.addTab(
              'Accom.',
              accommodationComponent.props.accommodationList.length,
              'accommodationComponent'
            )}
            {this.addTab(
              'Meals',
              mealComponent.props.mealList.length,
              'mealComponent'
            )}
            {this.addTab(
              'Transport',
              transportComponent.props.transportList.length,
              'transportComponent'
            )}
            {this.addTab(
              'RT',
              responsibleTravelComponent.props.responsibleTravelList.length,
              'responsibleTravelComponent'
            )}
            {this.addTab(
              'Activities',
              activityComponent.props.activityList.length,
              'activityComponent'
            )}
            {this.addTab(
              'Costs',
              costChangeComponent.props.costChangeList.length,
              'costChangeComponent'
            )}
            {this.addTab(
              'Medication',
              medicationComponent.props.medicationList.length,
              'medicationComponent'
            )}
            {this.addTab(
              'Crew',
              crewComponent.props.crewList.length,
              'crewComponent'
            )}
          </Tabs>
        </Paper>
      );
    }
  };

  renderTabContents = () => {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.state.selectedTabIndex == i) {
        const component = this.props[this.tabs[i]];
        return <span>{component}</span>;
      }
    }
  };

  render() {
    return (
      <div>
        {this.renderTabs()}
        {this.renderTabContents()}
      </div>
    );
  }
}

TripReportCardActions.propTypes = {
  crewComponent: PropTypes.object,
  healthSafetyIssueComponent: PropTypes.object,
  possibleComplaintComponent: PropTypes.object,
  transportComponent: PropTypes.object,
  paxTripNoteComponent: PropTypes.object,
  costChangeComponent: PropTypes.object
};

export default withStyles(styles)(TripReportCardActions);
