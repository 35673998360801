export default {
  trimText: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  cardHeaderContent: {
    fontSize: '12.5pt',
    fontWeight: 500
  },
  cardContent: {
    paddingTop: '0px',
    fontSize: '11pt'
  },
  menuItemIcon: {
    paddingRight: '5px',
    marginLeft: '-5px'
  }
};
