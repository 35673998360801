import * as actionTypes from '../actionTypes/systemConfigs';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const systemConfigsGetRequest = () => ({
  type: actionTypes.SYSTEM_CONFIGS_GET_REQUEST,
  meta: {
    disableLoadingBar: true
  }
});

export const systemConfigsGetSuccess = systemConfigs => ({
  type: actionTypes.SYSTEM_CONFIGS_GET_SUCCESS,
  payload: {
    systemConfigs
  }
});

export const getSystemConfigsByCodes = configCodes => async dispatch => {
  const data = await LeaderPortalService.SystemConfig.get(configCodes);

  dispatch(systemConfigsGetSuccess(data));
};
