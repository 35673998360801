import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import MultipleSelect from '../../common/forms/MultipleSelect';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import themes from './TripReportField.styles';
import required from '../../common/forms/validators/required';

class TripReportHealthSafetyIssueFields extends React.Component {
  getOptions = pax => {
    return pax
      .sort((a, b) =>
        a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0
      )
      .map(p => ({
        label: `${p.bookingId} - ${p.fullName}`,
        value: p.id
      }));
  };

  render() {
    const { classes, submitting } = this.props;

    return (
      <Fragment>
        <div>
          <MultipleSelect
            name="pax"
            placeholder="Pax Names"
            validate={required}
            options={this.getOptions(this.props.pax)}
            className={classes.wideField}
            disabled={submitting}
            selectAll={true}
            selectAllText="Select all passengers"
          />
        </div>
        <div>
          <InputField
            name="location"
            placeholder="Location"
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Comments"
            multiline={true}
            validate={required}
            rows={6}
            rowsMax={6}
            maxLength={1000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

TripReportHealthSafetyIssueFields.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  pax: PropTypes.array.isRequired
};

export default withStyles(themes)(TripReportHealthSafetyIssueFields);
