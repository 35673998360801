import * as actionTypes from '../actionTypes/systemConfigs';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.SYSTEM_CONFIGS_GET_SUCCESS:
      return action.payload.systemConfigs;
    default:
      return state;
  }
};
