import * as actionTypes from '../actionTypes/covidQuestionnaire';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.COVIDQUESTIONAIR_GET_SUCCESS:
      return action.payload.covidQuestionnaire;
    default:
      return state;
  }
};
