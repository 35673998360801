export default function createURLWithQueryParams(url, parameters) {
  let fullUrl = url;
  if (parameters) {
    fullUrl = `${fullUrl}?`;
    fullUrl =
      fullUrl +
      Object.entries(parameters)
        .map(p => p.join('='))
        .join('&');
  }

  return fullUrl;
}
