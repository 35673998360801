import React from 'react';
import PropTypes from 'prop-types';
import InputField from './InputField';

export class FullNameMembers {
  constructor(firstName, surname, middleName) {
    this.firstName = firstName;
    this.middleName = middleName;
    this.surname = surname;
  }
}

export class FullNameField {
  constructor(name, placeholder, helperText, className, required, maxLength) {
    this.name = name;
    this.placeholder = placeholder;
    this.helperText = helperText;
    this.className = className;
    this.required = required;
    this.maxLength = maxLength;
  }
}

class FullName extends React.Component {
  render() {
    const { members, disabled } = this.props;

    return (
      <div>
        <div>
          <InputField
            name={members.firstName.name}
            helperText={members.firstName.helperText}
            className={members.firstName.className}
            maxLength={members.firstName.maxLength}
            placeholder={members.firstName.placeholder}
            validate={members.firstName.required}
            disabled={disabled}
          />
        </div>
        <div>
          {members.middleName && (
            <InputField
              name={members.middleName.name}
              className={members.middleName.className}
              maxLength={members.middleName.maxLength}
              helperText={members.middleName.helperText}
              placeholder={members.middleName.placeholder}
              disabled={disabled}
            />
          )}
        </div>
        <div>
          <InputField
            name={members.surname.name}
            helperText={members.surname.helperText}
            className={members.surname.className}
            maxLength={members.surname.maxLength}
            placeholder={members.surname.placeholder}
            validate={members.surname.required}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

FullName.propTypes = {
  members: PropTypes.object,
  disabled: PropTypes.bool
};

export default FullName;
