export const GET_REQUEST = 'tripReport/ACTIVITY_GET_REQUEST';
export const GET_FAILURE = 'tripReport/ACTIVITY_GET_FAILURE';
export const GET_SUCCESS = 'tripReport/ACTIVITY_GET_SUCCESS';
export const CLEAR = 'tripReport/ACTIVITY_GET_CLEAR';

export const INSERT_REQUEST = 'tripReport/ACTIVITY_INSERT_REQUEST';
export const INSERT_SUCCESS = 'tripReport/ACTIVITY_INSERT_SUCCESS';

export const DELETE_REQUEST = 'tripReport/ACTIVITY_DELETE_REQUEST';
export const DELETE_SUCCESS = 'tripReport/ACTIVITY_DELETE_SUCCESS';

export const UPDATE_REQUEST = 'tripReport/ACTIVITY_UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'tripReport/ACTIVITY_UPDATE_SUCCESS';
