export default value => {
  // Convert null values to empty string
  const number = value || '';

  // Empty string is  a valid value
  if (number.toString().trim() === '') {
    return 'Required. Valid format is 565.52';
  }

  // Validate the pattern and min length
  let pattern = new RegExp('^[0-9][0-9]*(.[0-9][0-9])?$');
  if (!pattern.test(number.toString().trimRight())) {
    return 'Invalid amount. Valid format is 565.52';
  }

  return '';
};
