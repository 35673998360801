import React from 'react';
import PropTypes from 'prop-types';
import DepartureCard from './DepartureCard';
import Typography from '@material-ui/core/Typography';
import GlobalMessageCard from '../../common/components/GlobalMessageCard';

const DepartureList = ({ departures }) => {
  if (departures.length == 0) {
    return <GlobalMessageCard message="No departures to display" />;
  }

  return departures.map((departure, i) => (
    <DepartureCard key={departure.id} departure={departure} expandable={true} />
  ));
};

DepartureList.propTypes = {
  departures: PropTypes.array
};

export default DepartureList;
