export default class Pax {
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/departures';
  }

  getPassport(paxId, departureId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}/passport`;

    return this.service.get(resourcePath);
  }

  getVisa(paxId, departureId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}/visa`;

    return this.service.get(resourcePath);
  }

  getInsurance(paxId, departureId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}/insurance`;

    return this.service.get(resourcePath);
  }

  getEmergencyContact(paxId, departureId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}/EmergencyContact`;

    return this.service.get(resourcePath);
  }

  getContact(paxId, departureId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}/Contact`;

    return this.service.get(resourcePath);
  }

  updatePax(paxId, departureId, data) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}`;
    return this.service.patch(resourcePath, data);
  }
}
