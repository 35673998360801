import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './PaxListConfirmedByBooking.styles';

class PaxListConfirmedByBooking extends React.Component {
  state = {
    dialogOpen: false
  };

  toggleDialogOpen = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  };

  onCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };

  onClickPax = pax => async () => {
    await this.props.onChange(pax);
    this.onCloseDialog();
  };

  renderDialog = paxList => (
    <Dialog
      open={this.state.dialogOpen}
      aria-labelledby="form-dialog-title"
      fullWidth
      onClose={this.onCloseDialog}
    >
      <DialogTitle>Copy information from</DialogTitle>
      <DialogContent>
        <List>
          {paxList.map(pax => (
            <ListItem button onClick={this.onClickPax(pax)}>
              <ListItemIcon>
                <Icon>file_copy</Icon>
              </ListItemIcon>
              <ListItemText primary={pax.fullName} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.onCloseDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  render() {
    const { paxList, classes } = this.props;
    return (
      <div>
        {paxList && paxList.length > 0 && (
          <div>
            <div className={classes.buttonContainer}>
              <Button onClick={this.toggleDialogOpen}>
                <Icon className={classes.buttonIcon}>file_copy</Icon>Copy
                information from
              </Button>
            </div>
            {this.renderDialog(paxList)}
          </div>
        )}
      </div>
    );
  }
}

PaxListConfirmedByBooking.propTypes = {
  classes: PropTypes.object,
  paxList: PropTypes.array,
  labelText: PropTypes.string,
  onChange: PropTypes.func
};

export default withStyles(styles)(PaxListConfirmedByBooking);
