import { combineReducers } from 'redux';
import search from './departures';
import pax from './pax';
import selectedDeparture from './selectedDeparture';
import selectedPaxDeparture from './selectedPaxDeparture';
import selectedPaxPassport from './selectedPaxPassport';
import selectedPaxEmergencyContact from './selectedPaxEmergencyContact';
import selectedPaxContact from './selectedPaxContact';
import selectedPaxVisa from './selectedPaxVisa';
import selectedPaxInsurance from './selectedPaxInsurance';
import selectedDepartureTransfers from './selectedDepartureTransfers';
import selectedDepartureAccommodations from './selectedDepartureAccommodations';
import selectedDepartureSightseeings from './selectedDepartureSightseeings';
import selectedDeparturePaxChanges from './selectedDeparturePaxChanges';
import countries from './countries';
import leaderActions from './leaderActions';
import covidQuestionnaire from './covidQuestionnaire';
import covidQuestionnaireResponses from './covidQuestionnaireResponses';

export default combineReducers({
  search,
  selectedDeparture,
  pax,
  selectedPaxDeparture,
  selectedPaxPassport,
  selectedPaxEmergencyContact,
  selectedPaxInsurance,
  selectedPaxVisa,
  selectedPaxContact,
  selectedDepartureTransfers,
  selectedDepartureAccommodations,
  selectedDepartureSightseeings,
  selectedDeparturePaxChanges,
  countries,
  leaderActions,
  covidQuestionnaire,
  covidQuestionnaireResponses
});
