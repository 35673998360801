import * as actionTypes from '../actionTypes/crew';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';

export const tripReportCrewGetRequest = departureId => ({
  type: actionTypes.CREW_GET_REQUEST,
  payload: {
    departureId
  }
});

export const tripReportCrewGetSuccess = crewList => ({
  type: actionTypes.CREW_GET_SUCCESS,
  payload: {
    crewList
  }
});

export const crewInsertRequest = (departureId, crew) => ({
  type: actionTypes.CREW_INSERT_REQUEST,
  payload: {
    departureId,
    crew
  }
});

export const crewUpdateRequest = (departureId, crewMemberId) => ({
  type: actionTypes.CREW_UPDATE_REQUEST,
  payload: {
    departureId,
    crewMemberId
  }
});

export const crewDeleteRequest = (departureId, crewMemberId) => ({
  type: actionTypes.CREW_DELETE_REQUEST,
  payload: {
    departureId,
    crewMemberId
  }
});

export const crewInsertSuccess = crew => ({
  type: actionTypes.CREW_INSERT_SUCCESS,
  payload: {
    crew
  }
});

export const crewDeleteSuccess = crewId => ({
  type: actionTypes.CREW_DELETE_SUCCESS,
  payload: {
    crewId
  }
});

export const crewUpdateSuccess = crew => ({
  type: actionTypes.CREW_UPDATE_SUCCESS,
  payload: {
    crew
  }
});

export const getCrewList = id => async dispatch => {
  dispatch(tripReportCrewGetRequest(id));

  const crew = await LeaderPortalService.TripReport.Crew.getByDepartureId(id);

  dispatch(tripReportCrewGetSuccess(crew));
};

export const insertCrew = (departureId, crew) => async dispatch => {
  dispatch(crewInsertRequest(departureId, crew));

  const data = await LeaderPortalService.TripReport.Crew.insert(
    departureId,
    crew
  );

  dispatch(
    addMessage('Crew member added to the Trip Report', messageTypes.success)
  );

  dispatch(crewInsertSuccess(data.body));
};

export const deleteCrew = (departureId, crewId) => async dispatch => {
  dispatch(crewDeleteRequest(departureId, crewId));

  await LeaderPortalService.TripReport.Crew.delete(departureId, crewId);

  dispatch(
    addMessage('Crew member removed from the Trip Report', messageTypes.success)
  );

  dispatch(crewDeleteSuccess(crewId));
};

export const updateCrew = (departureId, crew) => async dispatch => {
  dispatch(crewUpdateRequest(departureId, crew));

  await LeaderPortalService.TripReport.Crew.update(departureId, crew);

  dispatch(
    addMessage('Crew member updated in the trip report', messageTypes.success)
  );

  dispatch(crewUpdateSuccess(crew));
};
