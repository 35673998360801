import {
  ROUTE_TRIPREPORTUPLOAD,
  ROUTE_SHAREPOINTFILE
} from '../../common/constants/routes';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';
import convertToAPIDate from '../../common/helpers/convertToAPIDate';
import createURLWithQueryParams from '../../common/helpers/createURLWithQueryParams';
import createLink from '../../common/helpers/createLink';
import * as actionTypes from '../actionTypes/leaderActions';

// Passenger Insurance & Emergency Details
export const departurePaxDetailReviewCompleteSuccess = () => ({
  type: actionTypes.REVIEW_COMPLETE_SUCCESS
});

export const departurePaxDetailReviewCompleteFailure = () => ({
  type: actionTypes.REVIEW_COMPLETE_FAILURE
});

export const departurePaxDetailReviewCompleteRequest = departureId => ({
  type: actionTypes.REVIEW_COMPLETE_REQUEST,
  payload: {
    departureId
  }
});

export const departurePaxDetailReviewComplete = departureId => async dispatch => {
  dispatch(departurePaxDetailReviewCompleteRequest(departureId));
  const request = {
    DeparturePaxReview: {
      departureId: departureId,
      executionDateTimeStr: convertToAPIDate(new Date())
    }
  };
  try {
    await LeaderPortalService.LeaderActions.postdeparturePaxReviewDetail(
      departureId,
      request
    );

    dispatch(
      addMessage(
        'Passenger Insurance & Emergency Details submitted',
        messageTypes.success
      )
    );
    dispatch(departurePaxDetailReviewCompleteSuccess());
  } catch (e) {
    dispatch(departurePaxDetailReviewCompleteFailure());
    throw e;
  }
};

// Sharepoint File Uploads
export const sharepointFileUploadsSuccess = sharepointFileUploads => ({
  type: actionTypes.SHAREPOINT_FILE_UPLOADS_SUCCESS,
  payload: {
    sharepointFileUploads
  }
});

export const sharepointFileUploadsFailure = () => ({
  type: actionTypes.SHAREPOINT_FILE_UPLOADS_FAILURE
});

export const sharepointFileUploadsRequest = departureId => ({
  type: actionTypes.SHAREPOINT_FILE_UPLOADS_REQUEST,
  payload: {
    departureId
  }
});

export const sharepointFileUploadsClear = () => ({
  type: actionTypes.SHAREPOINT_FILE_UPLOADS_CLEAR
});

export const sharepointFileUploads = departureId => async dispatch => {
  dispatch(sharepointFileUploadsClear());
  dispatch(sharepointFileUploadsRequest(departureId));

  let route = createLink(ROUTE_SHAREPOINTFILE, {
    id: departureId
  });
  let urlParams = {
    executionDateTimeStr: convertToAPIDate(new Date())
  };
  let url = createURLWithQueryParams(`${route}`, urlParams);
  try {
    let data = await LeaderPortalService.get(url);

    dispatch(sharepointFileUploadsSuccess(data));
  } catch (e) {
    dispatch(sharepointFileUploadsFailure());
    throw e;
  }
};

// Emergency Details Has been uploaded to sharepoint
export const departurePaxReviewDetailSuccess = DeparturePaxReviewDetail => ({
  type: actionTypes.DEPARTURE_PAX_REVIEW_DETAIL_SUCCESS,
  payload: {
    DeparturePaxReviewDetail
  }
});

export const departurePaxReviewDetailClear = () => ({
  type: actionTypes.DEPARTURE_PAX_REVIEW_DETAIL_CLEAR
});

export const departurePaxReviewDetailFailure = () => ({
  type: actionTypes.DEPARTURE_PAX_REVIEW_DETAIL_FAILURE
});

export const departurePaxReviewDetailRequest = departureId => ({
  type: actionTypes.DEPARTURE_PAX_REVIEW_DETAIL_REQUEST,
  payload: {
    departureId
  }
});

export const departurePaxReviewDetail = departureId => async dispatch => {
  dispatch(departurePaxReviewDetailClear());
  dispatch(departurePaxReviewDetailRequest(departureId));
  try {
    let data = await LeaderPortalService.LeaderActions.getDeparturePaxReviewDetail(
      departureId
    );
    dispatch(departurePaxReviewDetailSuccess(data));
  } catch (e) {
    dispatch(departurePaxReviewDetailFailure());
    throw e;
  }
};

// Trip Report
export const tripReportUploadSuccess = () => ({
  type: actionTypes.TRIP_REPORT_UPLOAD_SUCCESS
});

export const tripReportUploadFailure = () => ({
  type: actionTypes.TRIP_REPORT_UPLOAD_FAILURE
});

export const tripReportUploadToSharepointRequest = departureId => ({
  type: actionTypes.TRIP_REPORT_UPLOAD_REQUEST,
  payload: {
    departureId
  }
});

export const tripReportUploadToSharepoint = departureId => async dispatch => {
  dispatch(tripReportUploadToSharepointRequest(departureId));

  let route = createLink(ROUTE_TRIPREPORTUPLOAD, {
    id: departureId
  });
  let urlParams = {
    executionDateTimeStr: convertToAPIDate(new Date())
  };
  let url = createURLWithQueryParams(`${route}`, urlParams);
  try {
    await LeaderPortalService.get(url);

    dispatch(addMessage('Trip Report submitted', messageTypes.success));
    dispatch(tripReportUploadSuccess());
  } catch (e) {
    dispatch(tripReportUploadFailure());
    throw e;
  }
};
