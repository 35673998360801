import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import ErrorIcon from '../../common/components/ErrorIcon';
import InputStyles from './InputField.module.css';
import { withStyles } from '@material-ui/core/styles';
import animations from '../../common/styles/Animations.styles';

const TextFieldAdapter = withStyles(animations)(
  ({ input, meta, maxLength, classes, ...rest }) => {
    // Process the value without modifying input.value directly
    const processedValue = input.value ? input.value.toString().trimLeft() : '';

    // Conditional error and touched values based on whether the field is disabled
    const showError = !rest.disabled && meta.touched && meta.error;

    return (
      <TextField
        {...input}
        value={rest.disabled ? '' : processedValue}
        {...rest}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          id: input.name,
          maxLength: maxLength,
          className: rest.disabled && classes.progressiveLoading
        }}
        autoComplete="off"
        error={showError}
        helperText={showError ? [<ErrorIcon />, meta.error] : rest.helperText}
        disabled={rest.disabled}
      />
    );
  }
);

class InputField extends React.Component {
  render() {
    const {
      name,
      className = 'styles.wideField',
      type = 'input',
      disabled = false,
      placeholder = '',
      helperText = '',
      maxLength = 5000,
      multiline = false,
      rows = 1,
      rowsMax = 1,
      validate,
      parse
    } = this.props;

    return (
      <Field
        name={name}
        type={type}
        classes={InputStyles}
        className={[className].join(' ')}
        validate={validate}
        component={TextFieldAdapter}
        label={placeholder}
        helperText={helperText}
        parse={parse}
        disabled={disabled}
        maxLength={maxLength}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
      />
    );
  }
}

InputField.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  validate: PropTypes.func,
  parse: PropTypes.func,
  disabled: PropTypes.bool
};

export default InputField;
