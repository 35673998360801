import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LeaderNoteAction from '../actions/LeaderNoteAction';
import TripReportSection from '../components/TripReportSection';
import TripReportLeaderNoteFields from '../components/TripReportLeaderNoteFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import '../../common/helpers/stringExtensions';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportLeaderNoteContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subTitleText: 'Include any changes or additions that should be made.'
    };
  }

  renderSubHeader = leaderNote => {
    return (
      leaderNote.location && (
        <div style={styles.trimText}>
          <span id={'leaderNote.header.location_' + leaderNote.id}>
            {leaderNote.location}
          </span>
        </div>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportLeaderNoteFields submitting={submitting} />
  );

  renderSectionCard = leaderNote => {
    return (
      <TripReportSectionCard
        sectionName="LeaderNote"
        itemName="leader notes"
        title={leaderNote.comments}
        subTitle={this.state.subTitleText}
        subHeader={this.renderSubHeader}
        cardContent={() => {
          return null;
        }}
        item={leaderNote}
        itemId={leaderNote.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateLeaderNote}
        delete={() =>
          this.props.actions.deleteLeaderNote(
            this.props.departure.id,
            leaderNote.id
          )
        }
        deleteMessage={leaderNote =>
          `Are you sure you want to delete leader note "${leaderNote.comments.trunc(
            60
          )}"?`
        }
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { departure, leaderNoteList } = this.props;

    return (
      departure &&
      leaderNoteList && (
        <TripReportSection
          subTitle={this.state.subTitleText}
          sectionName="LeaderNote"
          itemName="leader notes"
          noItemsMessage="There are no leader notes added yet"
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={leaderNoteList}
          insert={this.props.actions.insertLeaderNote}
        />
      )
    );
  }
}

TripReportLeaderNoteContainer.propTypes = {
  departure: PropTypes.object,
  leaderNoteList: PropTypes.array,
  actions: PropTypes.shape({
    insertLeaderNote: PropTypes.func,
    deleteLeaderNote: PropTypes.func,
    updateLeaderNote: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {};
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      insertLeaderNote: values => async dispatch => {
        await new LeaderNoteAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteLeaderNote: (departureId, leaderNoteId) => async dispatch => {
        await new LeaderNoteAction().delete(
          departureId,
          leaderNoteId
        )(dispatch);
      },
      updateLeaderNote: values => async dispatch => {
        await new LeaderNoteAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportLeaderNoteContainer)
);
