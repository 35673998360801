export default {
  icon: {
    float: 'right'
  },

  paper: {
    margin: '0px 0px 5px 0px',
    paddingBottom: '1px'
  },
  tab: {
    height: '60px',
    top: '-5px'
  }
};
