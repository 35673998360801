import * as departures from './departures';
import * as pax from './pax';
import * as selectedDeparture from './selectedDeparture';
import * as selectedPax from './selectedPax';
import * as transfers from './transfers';
import * as accommodations from './accommodations';
import * as sightseeings from './sightseeings';
import * as paxChanges from './paxChanges';
import * as countries from './countries';
import * as leaderActions from './leaderActions';
import * as covidQuestionnaire from './covidQuestionnaire';
import * as covidQuestionnaireResponses from './covidQuestionnaireResponses';

export default {
  departures,
  pax,
  selectedDeparture,
  selectedPax,
  transfers,
  accommodations,
  sightseeings,
  paxChanges,
  countries,
  leaderActions,
  covidQuestionnaire,
  covidQuestionnaireResponses
};
