import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@material-ui/core/';
import cardStyles from '../../common/styles/Card.module.css';
import { withStyles } from '@material-ui/core/styles';
import styles from './TripReportSectionCard.styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu'; // Correct import
import MenuItem from '@material-ui/core/MenuItem'; // Correct import

import ConfirmationDialog from '../../common/components/ConfirmationDialog';
import FormDialog from '../../common/components/FormDialog';

class TripReportSectionCard extends React.Component {
  state = {
    anchorEl: null,
    openDeleteConfirmation: false,
    openEditDialog: false
  };

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  openDeleteConfirmation = () => {
    this.setState({
      openDeleteConfirmation: true
    });

    this.handleMenuClose();
  };

  closeDeleteConfirmation = () => {
    this.setState({ openDeleteConfirmation: false });
  };

  openEditDialog = () => {
    this.setState({
      openEditDialog: true
    });
    this.handleMenuClose();
  };

  closeEditDialog = () => {
    this.setState({
      openEditDialog: false
    });
  };

  renderMenu(item, anchorEl) {
    return (
      <div>
        <IconButton aria-haspopup="true" onClick={this.handleMenuClick}>
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          id={`menu_${item.id}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMenuClose}
        >
          <MenuItem
            id={`edit_${item.id}`}
            key={`edit_${item.id}`}
            onClick={this.openEditDialog}
          >
            <Icon className={this.props.classes.menuItemIcon}>edit</Icon>
            Edit
          </MenuItem>
          <MenuItem
            id={`delete_${item.id}`}
            key={`delete_${item.id}`}
            onClick={this.openDeleteConfirmation}
          >
            <Icon className={this.props.classes.menuItemIcon}>delete</Icon>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }

  render() {
    const {
      classes,
      sectionName,
      title,
      subHeader,
      item,
      cardContent
    } = this.props;
    const { anchorEl } = this.state;
    const cardContentObj = cardContent && cardContent(item);

    return (
      <Card className={cardStyles.card}>
        <CardHeader
          title={title}
          subheader={subHeader && subHeader(item)}
          id={`${sectionName}.header_${item.id}`}
          classes={{
            title: classes.cardHeaderContent
          }}
          action={this.renderMenu(item, anchorEl)}
        />
        {cardContentObj && (
          <CardContent className={classes.cardContent}>
            {cardContentObj}
          </CardContent>
        )}

        <FormDialog
          title={`Update ${this.props.itemName}`}
          subTitle={this.props.subTitle}
          children={this.props.sectionFields}
          initialValues={this.props.item}
          action={this.props.update}
          actionText={this.state.actionText}
          open={this.state.openEditDialog}
          close={this.closeEditDialog}
          format={this.props.format}
        />

        <ConfirmationDialog
          open={this.state.openDeleteConfirmation}
          title={`Delete ${this.props.itemName}`}
          message={this.props.deleteMessage(item)}
          action={this.props.delete}
          actionText="Delete"
          close={this.closeDeleteConfirmation}
        />
      </Card>
    );
  }
}

TripReportSectionCard.propTypes = {
  title: PropTypes.string,
  subHeader: PropTypes.func,
  sectionName: PropTypes.string.isRequired,
  sectionFields: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  cardContent: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  format: PropTypes.func
};

export default withStyles(styles)(TripReportSectionCard);
