import isDate from '../../../common/helpers/isDate';
import { cloneDeepWith } from 'lodash';
import { parse } from 'date-fns';

// Converting date like values to js date objects
const transformPaxChanges = paxChanges => {
  return cloneDeepWith(paxChanges, value => {
    if (isDate(value)) {
      let date = parse(value, "yyyy-MM-dd'T'HH:mm:ss", new Date());
      return date;
    }
  });
};

export default class Departures {
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/departures';
  }

  get(id) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(id)}`;

    return this.service.get(resourcePath);
  }

  getByLeaderId() {
    const resourcePath = `/leader${this.baseResourcePath}`;

    return this.service.get(resourcePath);
  }

  getPaxByDepartureId(id) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      id
    )}/pax`;

    return this.service.get(resourcePath);
  }

  getDeparturePax(paxId, departureId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}`;

    return this.service.get(resourcePath);
  }

  async getPaxChanges(departureId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/paxChanges`;

    const data = await this.service.get(resourcePath);

    return transformPaxChanges(data.body);
  }
}
