import React from 'react';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import styles from './FormRadio.styles';

const FormRadio = ({ classes, ...props }) => (
  <Radio {...props} className={classes.radio} />
);

export default withStyles(styles)(FormRadio);
