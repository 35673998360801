import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from '@material-ui/core/';
import AvatarLogo from '../../common/components/AvatarLogo';
import cardStyles from '../../common/styles/Card.module.css';
import TripReportCardActions from './TripReportCardActions';

const TripReportCard = ({
  departure,
  crewComponent,
  healthSafetyIssueComponent,
  medicationComponent,
  mealComponent,
  accommodationComponent,
  possibleComplaintComponent,
  responsibleTravelComponent,
  activityComponent,
  transportComponent,
  leaderNoteComponent,
  commentComponent,
  paxTripNoteComponent,
  costChangeComponent
}) => {
  return (
    <div>
      <Card className={cardStyles.card}>
        <CardHeader
          avatar={
            <AvatarLogo
              brand={departure.brand}
              imgId={'img.header_' + departure.departureCode}
            />
          }
          title={departure.departureCode}
          subheader={departure.productName}
          id={'departure.header_' + departure.departureCode}
        />
      </Card>
      <TripReportCardActions
        crewComponent={crewComponent}
        healthSafetyIssueComponent={healthSafetyIssueComponent}
        medicationComponent={medicationComponent}
        mealComponent={mealComponent}
        accommodationComponent={accommodationComponent}
        possibleComplaintComponent={possibleComplaintComponent}
        responsibleTravelComponent={responsibleTravelComponent}
        activityComponent={activityComponent}
        transportComponent={transportComponent}
        leaderNoteComponent={leaderNoteComponent}
        paxTripNoteComponent={paxTripNoteComponent}
        commentComponent={commentComponent}
        costChangeComponent={costChangeComponent}
      />
    </div>
  );
};

TripReportCard.propTypes = {
  departure: PropTypes.object.isRequired,
  crewComponent: PropTypes.object,
  healthSafetyIssueComponent: PropTypes.object,
  accommodationComponent: PropTypes.object,
  possibleComplaintComponent: PropTypes.object,
  transportComponent: PropTypes.object,
  paxTripNoteComponent: PropTypes.object,
  commentComponent: PropTypes.object,
  costChangeComponent: PropTypes.object
};

export default TripReportCard;
