import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ProgressIndicator from '../../common/components/ProgressIndicator';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import {
  createGenerateClassName,
  jssPreset,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import GlobalMessagesContainer from '../../common/containers/GlobalMessagesContainer';
import './App.css';
import BrowserNotSupportedMessage from '../../common/components/BrowserNotSupportedMessage';
import DetectOfflineMessage from '../../common/components/DetectOfflineMessage';
import Drawer from '@material-ui/core/Drawer';
import { BrowserRouter } from 'react-router-dom';
import DrawerMenu from '../../common/components/DrawerMenu';
import extend from 'jss-extend';
import RouterContainer from './RouterContainer';
import PropTypes from 'prop-types';
import actions from '../../systemConfigs/actions';
import selectors from '../../systemConfigs/selectors';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IS_LEADER_PORTAL_COVID_SURVEY_ENABLED } from '../../common/constants/systemConfigCode';
import userAccessActions from '../../userAccess/actions';
import userAccessSelectors from '../../userAccess/selectors';
import { ROUTE_ACCESSDENIED } from '../../common/constants/routes';

const muiTheme = createTheme({
  palette: {
    primary: { main: '#1666B0' },
    secondary: { main: '#231764' },
    success: { main: '#448644' },
    info: { main: '#FFFFFF' }
  },
  overrides: {
    MuiCardHeader: {
      content: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      title: {
        overflow: 'inherit',
        textOverflow: 'inherit',
        fontWeight: '500'
      },
      subheader: {
        overflow: 'inherit',
        textOverflow: 'inherit'
      }
    },
    MuiListSubheader: {
      root: {
        color: 'black',
        lineHeight: '14px',
        paddingTop: '10px'
      }
    },
    MuiFormLabel: {
      root: {
        whiteSpace: 'nowrap',
        color: '#1666B0',
        '&$focused': {
          color: '#1666B0'
        }
      }
    },
    MuiInput: {
      input: {
        padding: 0,
        '&::placeholder': {
          color: 'transparent'
        }
      }
    },

    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#1666B0'
        }
      }
    },
    MuiTab: {
      root: {
        minWidth: '40px'
      },
      wrapper: {
        textTransform: 'uppercase'
      }
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'space-between'
      }
    },
    MuiSelect: {
      icon: {
        top: '-5px'
      }
    },
    MuiTypography: {
      body1: {
        color: 'black',
        fontSize: '0.875rem'
      },
      caption: {
        color: 'rgba(0, 0, 0, 0.54)'
      },
      body2: {
        fontWeight: '500'
      }
    },

    MuiPopover: {
      paper: {
        justifySelf: 'left'
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '26px'
      }
    },

    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#1666B0'
        }
      }
    }
  }
});

const generateClassName = createGenerateClassName();
const jss = create({ plugins: [...jssPreset().plugins, extend()] });
jss.options.insertionPoint = document.getElementById('jss-insertion-point');

class App extends Component {
  state = {
    open: false
  };

  componentDidMount() {
    document.title = process.env.REACT_APP_NAME;
    if (window.location.pathname !== ROUTE_ACCESSDENIED) {
      this.loadData();
    }
  }

  loadData = async () => {
    await this.props.actions.getSystemConfigsByCodes(
      IS_LEADER_PORTAL_COVID_SURVEY_ENABLED
    );
    await this.props.actions.getUserAccess();
  };

  toggleDrawer = open => {
    this.setState({ open });
  };

  render() {
    let isInternalUser = null;

    if (this.props.userAccess != null) {
      isInternalUser = this.props.userAccess.userType === 'Member';
    }

    return (
      this.props.userAccess && (
        <BrowserRouter>
          <JssProvider jss={jss} generateClassName={generateClassName}>
            <MuiThemeProvider theme={muiTheme}>
              <>
                <BrowserNotSupportedMessage />
                <DetectOfflineMessage />
                <ProgressIndicator />
                <div className="App">
                  <AppBar position="static">
                    <Toolbar>
                      <IconButton
                        color="inherit"
                        aria-label="Menu"
                        onClick={() => this.toggleDrawer(true)}
                      >
                        <Icon>menu</Icon>
                      </IconButton>
                      <Typography variant="h6" color="inherit">
                        {process.env.REACT_APP_NAME}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <Drawer
                    open={this.state.open}
                    onClose={() => this.toggleDrawer(false)}
                  >
                    <DrawerMenu
                      toggleDrawer={this.toggleDrawer}
                      isInternalUser={isInternalUser}
                    />
                  </Drawer>
                  <div>
                    <RouterContainer />
                  </div>
                  <GlobalMessagesContainer />
                </div>
              </>
            </MuiThemeProvider>
          </JssProvider>
        </BrowserRouter>
      )
    );
  }
}

const mapStateToProps = state => ({
  isLeaderPortalCovidSurveyEnabled: selectors.systemConfigs.getIsLeaderPortalCovidSurveyEnabled(
    state,
    IS_LEADER_PORTAL_COVID_SURVEY_ENABLED
  ),
  userAccess: userAccessSelectors.userAccess.getUserAccess(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSystemConfigsByCodes: actions.systemConfigs.getSystemConfigsByCodes,
      getUserAccess: userAccessActions.userAccess.getUserAccess
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
