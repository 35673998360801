import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';
import TripReportSection from '../components/TripReportSection';
import TripReportTransportFields from '../components/TripReportTransportFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import TransportAction from '../actions/TransportAction';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportTransportContainer extends React.Component {
  constructor(props) {
    super(props);

    this.transportTypes = [
      { type: 'PUBLIC', displayText: 'Public' },
      { type: 'CHARTER', displayText: 'Charter' },
      { type: 'DMC_FLEET', displayText: 'DMC Fleet' }
    ];
  }

  renderSubHeader = transport => {
    return (
      transport.location && (
        <div style={styles.trimText}>
          <span id={'transport.header.location_' + transport.id}>
            {transport.location}
          </span>
        </div>
      )
    );
  };

  renderContent = transport => {
    return (
      transport.comments && (
        <div style={styles.trimText}>
          <span id={'transport.header.comments_' + transport.id}>
            {transport.comments}
          </span>
        </div>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportTransportFields
      userResponses={this.props.userResponses}
      transportTypes={this.transportTypes}
      submitting={submitting}
    />
  );

  renderSectionCard = transport => {
    let transportType = this.transportTypes.filter(
      i => i.type === transport.type
    )[0].displayText;
    return (
      <TripReportSectionCard
        sectionName="transport"
        itemName="transport"
        subTitle="Include any highlights or challenges with any of the transport used."
        title={`${transportType} • ${transport.vehicle}`}
        subHeader={this.renderSubHeader}
        cardContent={this.renderContent}
        item={transport}
        itemId={transport.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateTransport}
        delete={() =>
          this.props.actions.deleteTransport(
            this.props.departure.id,
            transport.id
          )
        }
        deleteMessage={transport =>
          `Are you sure you want to delete transport "${transport.vehicle}"?`
        }
      />
    );
  };
  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { departure, transportList, userResponses } = this.props;

    return (
      departure &&
      transportList &&
      userResponses && (
        <TripReportSection
          sectionName="transport"
          itemName="transport"
          subTitle="Include any highlights or challenges with any of the transport used."
          noItemsMessage="There are no transport issues added yet"
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={transportList}
          departureId={departure.id}
          insert={this.props.actions.insertTransport}
        />
      )
    );
  }
}

TripReportTransportContainer.propTypes = {
  departure: PropTypes.object,
  transportList: PropTypes.array,
  userResponses: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    insertTransport: PropTypes.func,
    deleteTransport: PropTypes.func,
    updateTransport: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {
    id: params.router.params.id
  };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      getUserResponses: actions.UserResponses.getUserResponses,
      insertTransport: values => async dispatch => {
        await new TransportAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteTransport: (departureId, transportId) => async dispatch => {
        await new TransportAction().delete(departureId, transportId)(dispatch);
      },
      updateTransport: values => async dispatch => {
        await new TransportAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportTransportContainer)
);
