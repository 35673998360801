// Manually extending prototype to get instanceof working for Errors
function ForbiddenError(message, ...params) {
  this.name = 'Forbidden Error';
  this.message = message;

  if (this.message === undefined) {
    this.message = 'Forbidden access to resource';
  }

  this.stack = new Error().stack;
}

ForbiddenError.prototype = Object.create(Error.prototype);
ForbiddenError.prototype.constructor = ForbiddenError;

ForbiddenError.prototype.toString = function() {
  return this.message;
};

export default ForbiddenError;
