import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import DateField from '../../common/forms/DateField';
import InputField from '../../common/forms/InputField';
import styles from './PaxUpdateCardDetails.styles';
import { Card, CardContent, CardHeader } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

const PaxUpdateCardVisaDetails = ({ visa, sectionCardStyle, classes }) => {
  return (
    <div>
      <Card>
        <CardHeader
          style={sectionCardStyle.header}
          title={
            <div>
              <Typography align="center" variant="body2">
                Visa Information
              </Typography>
            </div>
          }
        />

        <CardContent>
          <Typography align="center" variant="caption">
            <div>(if required)</div>
          </Typography>

          <div>
            <InputField
              name="visa.visaNumber"
              placeholder="Visa Number"
              className={classes.wideField}
              maxLength={100}
              disabled={Object.keys(visa).length == 0}
            />
          </div>
          <div>
            <DateField
              className={classes.wideField}
              name="visa.expiryDate"
              placeholder="Expiry Date"
              disabled={Object.keys(visa).length == 0}
            />
          </div>
          <div>
            <DateField
              className={classes.wideField}
              name="visa.entryDate"
              placeholder="Entry Date"
              disabled={Object.keys(visa).length == 0}
            />
          </div>
          <div>
            <DateField
              className={classes.wideField}
              name="visa.exitDate"
              placeholder="Exit Date"
              disabled={Object.keys(visa).length == 0}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

PaxUpdateCardVisaDetails.propTypes = {
  paxVisa: PropTypes.object
};

export default withStyles(styles)(PaxUpdateCardVisaDetails);
