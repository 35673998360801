import * as React from 'react';
import styles from './Logo.module.css';

const Logo = function({ brand, className, imgId }) {
  let logo;
  let brandText = brand || 'Generic Brand';

  switch (brand) {
    case 'Intrepid':
      logo = '/images/Intrepid.png';
      break;
    case 'Geckos':
      logo = '/images/Geckos.png';
      break;
    case 'Peregrine':
      logo = '/images/Peregrine.png';
      break;
    default:
      logo = '/images/DefaultBrand.png';
      break;
  }

  return <img alt={brandText} src={logo} className={styles.logo} id={imgId} />;
};

export default Logo;
