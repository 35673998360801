import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import departureActions from '../../departures/actions';
import departureSelectors from '../../departures/selectors';
import TripReportCard from '../components/TripReportCard';
import TripReportCrewContainer from './TripReportCrewContainer';
import TripReportHealthSafetyIssueContainer from './TripReportHealthSafetyIssueContainer';
import TripReportMedicationContainer from './TripReportMedicationContainer';
import TripReportMealContainer from './TripReportMealContainer';
import TripReportAccommodationContainer from './TripReportAccommodationContainer';
import TripReportPossibleComplaintContainer from './TripReportPossibleComplaintContainer';
import TripReportResponsibleTravelContainer from './TripReportResponsibleTravelContainer';
import TripReportActivityContainer from './TripReportActivityContainer';
import TripReportCommentContainer from './TripReportCommentContainer';
import actions from '../actions';
import HealthSafetyIssuesAction from '../actions/HealthSafetyIssuesAction';
import MedicationAction from '../actions/MedicationAction';
import MealAction from '../actions/MealAction';
import AccommodationAction from '../actions/AccommodationAction';
import selectors from '../selectors';
import PossibleComplaintsAction from '../actions/PossibleComplaintsAction';
import ResponsibleTravelAction from '../actions/ResponsibleTravelAction';
import ActivityAction from '../actions/ActivityAction';
import TransportAction from '../actions/TransportAction';
import TripReportTransportContainer from './TripReportTransportContainer';
import PaxTripNoteAction from '../actions/PaxTripNoteAction';
import TripReportPaxTripNoteContainer from './TripReportPaxTripNoteContainer';
import TripReportLeaderNoteContainer from './TripReportLeaderNoteContainer';
import LeaderNoteAction from '../actions/LeaderNoteAction';
import CommentAction from '../actions/CommentAction';
import TripReportCostChangeContainer from './TripReportCostChangeContainer';
import CostChangeAction from '../actions/CostChangeAction';
import { ROUTE_ACCESSDENIED } from '../../common/constants/routes';
import { getLeaderActions } from '../../departures/selectors/leaderActions';
import { sharepointFileUploads as sharepointFileUploadsAction } from '../../departures/actions/leaderActions';
import { fileUploadStatus } from '../../common/constants/fileUploadStatus';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportContainer extends React.Component {
  componentDidMount() {
    this.loadData(this.props.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.loadData(this.props.id);
    }

    if (this.props.departure && this.props.tripReportSubmitted) {
      window.location.href = ROUTE_ACCESSDENIED;
    }
  }

  loadData(departureId) {
    this.props.actions.getDeparture(departureId);
    this.props.actions.getSharepointFileUploads(departureId);
    this.props.actions.getCrewList(departureId);
    this.props.actions.getHealthSafetyIssueList(departureId);
    this.props.actions.getMedicationList(departureId);
    this.props.actions.getAccommodationList(departureId);
    this.props.actions.getMealList(departureId);
    this.props.actions.getPossibleComplaints(departureId);
    this.props.actions.getResponsibleTravelList(departureId);
    this.props.actions.getActivityList(departureId);
    this.props.actions.getCrewRoles();
    this.props.actions.getTransportList(departureId);
    this.props.actions.getUserResponses();
    this.props.actions.getLeaderNoteList(departureId);
    this.props.actions.getPaxTripNoteList(departureId);
    this.props.actions.getCommentList(departureId);
    this.props.actions.getCurrencies();
    this.props.actions.getCostChangeList(departureId);
    this.props.actions.getResponsibleTravelCategories();
  }

  render() {
    const {
      departure,
      crewList,
      crewRoles,
      healthSafetyIssueList,
      medicationList,
      mealList,
      accommodationList,
      possibleComplaints,
      responsibleTravelList,
      activityList,
      transportList,
      userResponses,
      paxTripNoteList,
      leaderNoteList,
      commentList,
      currencies,
      costChanges,
      responsibleTravelCategories,
      tripReportSubmitted
    } = this.props;

    return (
      departure && (
        <TripReportCard
          departure={departure}
          crewComponent={
            crewList && (
              <TripReportCrewContainer
                departure={departure}
                crewList={crewList}
                crewRoles={crewRoles}
              />
            )
          }
          transportComponent={
            transportList && (
              <TripReportTransportContainer
                departure={departure}
                transportList={transportList}
                userResponses={userResponses}
              />
            )
          }
          healthSafetyIssueComponent={
            healthSafetyIssueList && (
              <TripReportHealthSafetyIssueContainer
                departure={departure}
                healthSafetyIssueList={healthSafetyIssueList}
              />
            )
          }
          medicationComponent={
            medicationList && (
              <TripReportMedicationContainer
                departure={departure}
                medicationList={medicationList}
              />
            )
          }
          mealComponent={
            mealList && (
              <TripReportMealContainer
                departure={departure}
                mealList={mealList}
              />
            )
          }
          accommodationComponent={
            accommodationList && (
              <TripReportAccommodationContainer
                departure={departure}
                accommodationList={accommodationList}
              />
            )
          }
          possibleComplaintComponent={
            possibleComplaints && (
              <TripReportPossibleComplaintContainer
                departure={departure}
                possibleComplaints={possibleComplaints}
              />
            )
          }
          responsibleTravelComponent={
            responsibleTravelList &&
            responsibleTravelCategories && (
              <TripReportResponsibleTravelContainer
                departure={departure}
                responsibleTravelList={responsibleTravelList}
                responsibleTravelCategories={responsibleTravelCategories}
              />
            )
          }
          activityComponent={
            activityList && (
              <TripReportActivityContainer
                departure={departure}
                activityList={activityList}
                userResponses={userResponses}
              />
            )
          }
          leaderNoteComponent={
            leaderNoteList && (
              <TripReportLeaderNoteContainer
                departure={departure}
                leaderNoteList={leaderNoteList}
              />
            )
          }
          paxTripNoteComponent={
            paxTripNoteList && (
              <TripReportPaxTripNoteContainer
                departure={departure}
                paxTripNoteList={paxTripNoteList}
              />
            )
          }
          commentComponent={
            commentList && (
              <TripReportCommentContainer
                departure={departure}
                commentList={commentList}
              />
            )
          }
          costChangeComponent={
            costChanges && (
              <TripReportCostChangeContainer
                departure={departure}
                costChangeList={costChanges}
                currencies={currencies}
              />
            )
          }
        />
      )
    );
  }
}

TripReportContainer.propTypes = {
  departure: PropTypes.object,
  crewList: PropTypes.array,
  crewRoles: PropTypes.array,
  accommodationList: PropTypes.array,
  mealList: PropTypes.array,
  transportList: PropTypes.array,
  paxTripNoteList: PropTypes.array,
  commentList: PropTypes.array,
  costChanges: PropTypes.array,
  currencies: PropTypes.array,
  actions: PropTypes.shape({
    getDeparture: PropTypes.func.isRequired,
    getCrewList: PropTypes.func.isRequired,
    getCrewRoles: PropTypes.func.isRequired,
    getHealthSafetyIssueList: PropTypes.func.isRequired,
    getMedicationList: PropTypes.func.isRequired,
    getMealList: PropTypes.func.isRequired,
    getAccommodationList: PropTypes.func.isRequired,
    getPossibleComplaints: PropTypes.func.isRequired,
    getResponsibleTravelList: PropTypes.func.isRequired,
    getActivityList: PropTypes.func.isRequired,
    getTransportList: PropTypes.func.isRequired,
    getUserResponses: PropTypes.func.isRequired,
    getLeaderNoteList: PropTypes.func.isRequired,
    getCommentList: PropTypes.func.isRequired,
    getPaxTripNoteList: PropTypes.func.isRequired,
    getCostChangeList: PropTypes.func.isRequired,
    getCurrencies: PropTypes.func.isRequired,
    getResponsibleTravelCategories: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = (state, props) => {
  let selectedDeparture = departureSelectors.selectedDeparture.getDeparture(
    state
  );
  let crewList = selectors.crew.getCrewList(state);
  let crewRoles = selectors.crewRoles.getCrewRoles(state);
  let healthSafetyIssues = selectors.healthSafetyIssues.getHealthSafetyIssueList(
    state
  );
  let medication = selectors.medication.getMedicationList(state);
  let meal = selectors.meal.getMealList(state);
  let accommodation = selectors.accommodation.getAccommodationList(state);
  let possibleComplaints = selectors.possibleComplaints.getPossibleComplaints(
    state
  );
  let responsibleTravel = selectors.responsibleTravel.getResponsibleTravelList(
    state
  );
  let activity = selectors.activity.getActivityList(state);
  let transport = selectors.transport.getTransportList(state);
  let responses = selectors.userResponses.getUserResponses(state);
  let leaderNote = selectors.leaderNote.getLeaderNoteList(state);
  let paxTripNotes = selectors.paxTripNote.getPaxTripNoteList(state);
  let comments = selectors.comment.getCommentList(state);
  let currencies = selectors.currencies.getCurrencies(state);
  let costChanges = selectors.costChange.getCostChangeList(state);

  let leaderActions = getLeaderActions(state);
  let tripReportSubmitted =
    selectedDeparture &&
    leaderActions &&
    leaderActions.sharepointFileUploads &&
    leaderActions.sharepointFileUploads.filter(
      f =>
        f.fileType === 'TripReport' &&
        f.sharepointFileUploadStatus !== fileUploadStatus.REACTIVATED
    ).length > 0;

  let responsibleTravelCategories = selectors.responsibleTravelCategories.getResponsibleTravelCategories(
    state
  );

  return {
    id: props.router.params.id,
    departure: selectedDeparture,
    crewList: crewList,
    crewRoles: crewRoles,
    healthSafetyIssueList: healthSafetyIssues,
    medicationList: medication,
    mealList: meal,
    accommodationList: accommodation,
    possibleComplaints: possibleComplaints,
    responsibleTravelList: responsibleTravel,
    activityList: activity,
    transportList: transport,
    userResponses: responses,
    leaderNoteList: leaderNote,
    commentList: comments,
    paxTripNoteList: paxTripNotes,
    costChanges: costChanges,
    currencies: currencies,
    responsibleTravelCategories: responsibleTravelCategories,
    tripReportSubmitted: tripReportSubmitted
  };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      getDeparture: departureActions.selectedDeparture.getSelectedDeparture,
      getCrewList: actions.crew.getCrewList,
      getCrewRoles: actions.crewRoles.getCrewRoles,
      getHealthSafetyIssueList: new HealthSafetyIssuesAction().getList,
      getMedicationList: new MedicationAction().getList,
      getMealList: new MealAction().getList,
      getAccommodationList: new AccommodationAction().getList,
      getPossibleComplaints: new PossibleComplaintsAction().getList,
      getResponsibleTravelList: new ResponsibleTravelAction().getList,
      getActivityList: new ActivityAction().getList,
      getTransportList: new TransportAction().getList,
      getUserResponses: actions.UserResponses.getUserResponses,
      getLeaderNoteList: new LeaderNoteAction().getList,
      getCommentList: new CommentAction().getList,
      getPaxTripNoteList: new PaxTripNoteAction().getList,
      getCurrencies: actions.Currencies.getCurrencies,
      getCostChangeList: new CostChangeAction().getList,
      getSharepointFileUploads: sharepointFileUploadsAction,
      getResponsibleTravelCategories:
        actions.ResponsibleTravelCategoryAction.getResponsibleTravelCategories
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportContainer)
);
