import reducers from './reducers';
import TransferList from './components/TransferList';
import SightseeingList from './components/SightseeingList';
import DepartureListContainer from './containers/DepartureListContainer';
import DepartureReportContainer from './containers/DepartureReportContainer';
import PaxListContainer from './containers/PaxListContainer';
import PaxUpdateListContainer from './containers/PaxUpdateListContainer';
import PaxUpdateContainer from './containers/PaxUpdateContainer';
import LeaderActionsContainer from './containers/LeaderActionsContainer';
import CovidPaxQuestionnaireContainer from './containers/CovidPaxQuestionnaireContainer';
import CovidCrewQuestionnaireContainer from './containers/CovidCrewQuestionnaireContainer';
import PreTripCovidTestPaxContainer from './containers/PreTripCovidTestPaxContainer';

export default {
  reducers,
  components: { SightseeingList, TransferList },
  containers: {
    DepartureListContainer,
    PaxListContainer,
    PaxUpdateListContainer,
    PaxUpdateContainer,
    DepartureReportContainer,
    LeaderActionsContainer,
    CovidPaxQuestionnaireContainer,
    CovidCrewQuestionnaireContainer,
    PreTripCovidTestPaxContainer
  }
};
