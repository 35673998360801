import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import KeyValueItem from '../../common/components/KeyValueItem';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { sortBy, groupBy } from 'lodash';
import styles from '../../common/styles/AdditionalServices.module.css';

const themes = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0
  }
});

const TransferList = ({ transfers, classes }) => {
  const sortedTransfers = sortBy(transfers, ['flightDateTime']);
  const transferGroups = groupBy(sortedTransfers, val =>
    format(val.flightDateTime, process.env.REACT_APP_DATE_FORMAT)
  );

  return (
    <List className={classes.root}>
      {Object.keys(transferGroups).map(transferGroupKey => {
        const transfers = transferGroups[transferGroupKey];
        const transferProductGroups = groupBy(
          transfers,
          val =>
            `${val.startingPoint.toLowerCase()}|${val.finishingPoint.toLowerCase()}`
        );
        const transferProductListItems = getTransferProductList(
          transferProductGroups
        );

        return (
          <div key={transferGroupKey}>
            <ListSubheader
              disableSticky
              id={`pax.transfers.transferGroupKey_${transferGroupKey}`}
              className={styles.subHeader}
            >
              {transferGroupKey}
            </ListSubheader>
            {transferProductListItems}
            <Divider />
          </div>
        );
      })}
    </List>
  );
};

function getTransferProductList(transferProductGroups) {
  return Object.keys(transferProductGroups).map(transferProductGroupKey => {
    const transferProducts = transferProductGroups[transferProductGroupKey];
    const location = getLocation(
      transferProducts[0].startingPoint,
      transferProducts[0].finishingPoint
    );
    const transferProductFlightsGroups = groupBy(transferProducts, val => {
      const flightTime = format(val.flightDateTime, 'HH:mm');
      return `${val.flightNumber.trim().toUpperCase()} • ${flightTime}`;
    });

    return (
      <div key={transferProductGroupKey}>
        <ListSubheader disableSticky>{location}</ListSubheader>
        {getTransferList(transferProductFlightsGroups)}
      </div>
    );
  });
}

function getLocation(startingPoint, finishingPoint) {
  return (
    <div>
      <KeyValueItem
        label={
          <span
            style={{
              fontWeight: '400',
              fontSize: '0.75rem',
              color: 'rgba(0, 0, 0, 0.54)'
            }}
          >
            From
          </span>
        }
        value={
          <span style={{ fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)' }}>
            {startingPoint}
          </span>
        }
        orientation={null}
      />

      <KeyValueItem
        label={
          <span
            style={{
              fontWeight: '400',
              fontSize: '0.75rem',
              color: 'rgba(0, 0, 0, 0.54)'
            }}
          >
            To
          </span>
        }
        value={
          <span style={{ fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.87)' }}>
            {finishingPoint}
          </span>
        }
        orientation={null}
      />
    </div>
  );
}

function getTransferList(transferProductFlightsGroups) {
  return Object.keys(transferProductFlightsGroups).map(
    transferProductFlightsGroupsKey => {
      const transferProductFlights =
        transferProductFlightsGroups[transferProductFlightsGroupsKey];

      return (
        <ListItem
          key={transferProductFlightsGroupsKey}
          className={styles.listContainer}
        >
          <ListItemText
            primary={
              <Typography variant="body2">
                {transferProductFlightsGroupsKey}
              </Typography>
            }
            secondary={
              <div>
                {transferProductFlights.map(transferProductFlight => (
                  <Typography
                    variant="body1"
                    key={`${transferProductFlightsGroupsKey}-${transferProductFlight.fullNameWithTitle}`}
                  >
                    {transferProductFlight.fullNameWithTitle}
                  </Typography>
                ))}
              </div>
            }
          />
        </ListItem>
      );
    }
  );
}

TransferList.propTypes = {
  transfers: PropTypes.array.isRequired
};

export default withStyles(themes)(TransferList);
