export default class UserResponses {
  constructor(service) {
    this.service = service;
  }

  async get() {
    const resourcePath = `/UserResponse`;

    const data = await this.service.get(resourcePath);

    return data.userResponses;
  }
}
