import * as actionTypes from '../actionTypes/leaderActions';

export default (state = null, action) => {
  let newState;
  switch (action.type) {
    case actionTypes.SHAREPOINT_FILE_UPLOADS_SUCCESS:
      newState = Object.assign(
        {
          sharepointFileUploads:
            action.payload.sharepointFileUploads.sharepointFileUploads
        },
        state
      );
      return newState;
    case actionTypes.SHAREPOINT_FILE_UPLOADS_CLEAR:
      return null;
    case actionTypes.DEPARTURE_PAX_REVIEW_DETAIL_CLEAR:
      return null;
    case actionTypes.DEPARTURE_PAX_REVIEW_DETAIL_SUCCESS:
      newState = Object.assign(
        {
          departurePaxDetailReviewDetails:
            action.payload.DeparturePaxReviewDetail.departurePaxDetailReviews
        },
        state
      );
      return newState;
    default:
      return state;
  }
};
