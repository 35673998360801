import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';
import TripReportSection from '../components/TripReportSection';
import TripReportCostChangeFields from '../components/TripReportCostChangeFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import CostChangeAction from '../actions/CostChangeAction';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportCostChangeContainer extends React.Component {
  renderContent = costChange => {
    return (
      costChange.comments && (
        <div style={styles.trimText}>
          <span id={'costChange.header.comments_' + costChange.id}>
            {costChange.comments}
          </span>
        </div>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportCostChangeFields
      submitting={submitting}
      currencies={this.props.currencies}
    />
  );

  renderSubHeader = costChange => {
    return (
      <span id={'costChange.header.type_' + costChange.id}>
        {costChange.location}
      </span>
    );
  };

  renderSectionCard = costChange => {
    return (
      <TripReportSectionCard
        sectionName="costchange"
        itemName="cost change"
        subTitle="Include any costs different to your trip budget."
        subHeader={this.renderSubHeader}
        title={costChange.serviceName}
        cardContent={this.renderContent}
        item={costChange}
        itemId={costChange.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateCostChange}
        delete={() =>
          this.props.actions.deleteCostChange(
            this.props.departure.id,
            costChange.id
          )
        }
        deleteMessage={costChange =>
          `Are you sure you want to delete the cost change "${costChange.serviceName}"?`
        }
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { departure, costChangeList, currencies } = this.props;

    return (
      departure &&
      costChangeList &&
      currencies && (
        <TripReportSection
          sectionName="costChange"
          itemName="cost change"
          subTitle="Include any costs different to your trip budget."
          noItemsMessage="There are no cost changes added yet"
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={costChangeList}
          departureId={departure.id}
          insert={this.props.actions.insertCostChange}
        />
      )
    );
  }
}

TripReportCostChangeContainer.propTypes = {
  departure: PropTypes.object,
  costChangeList: PropTypes.array,
  currencies: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    insertCostChange: PropTypes.func,
    deleteCostChange: PropTypes.func,
    updateCostChange: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {
    id: params.router.params.id
  };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      getCurrencies: actions.Currencies.getCurrencies,
      insertCostChange: values => async dispatch => {
        await new CostChangeAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteCostChange: (departureId, costChangeId) => async dispatch => {
        await new CostChangeAction().delete(
          departureId,
          costChangeId
        )(dispatch);
      },
      updateCostChange: values => async dispatch => {
        await new CostChangeAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportCostChangeContainer)
);
