import React from 'react';
import PropTypes from 'prop-types';
import DepartureCardContent from './DepartureCardContent';
import Card from '@material-ui/core/Card';
import DepartureCardHeader from './DepartureCardHeader';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { CardContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import createURLWithQueryParams from '../../common/helpers/createURLWithQueryParams';
import createLink from '../../common/helpers/createLink';
import { ROUTE_DEPARTURESUMMARYVIEW } from '../../common/constants/routes';
import { FormControlLabel } from '@material-ui/core';
import convertToAPIDate from '../../common/helpers/convertToAPIDate';
import ReactGA from 'react-ga';
import cardStyles from '../../common/styles/Card.module.css';

class DepartureReportCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPaxDetails: false,
      showDateOfBirth: false,
      showNationality: false,
      showPassport: false,
      showVisa: false,
      showInsurance: false,
      showEmergencyContact: false,
      showPaxCheckedDetails: false,
      showEmail: false,
      showHeightWeight: false
    };
  }

  handleCheck = (checkbox, e) => {
    this.setState({ [checkbox]: e.target.checked });
  };

  handleTealiumViewRequest = () => {
    ReactGA.event({
      category: 'Report',
      action: 'Departure Report Generated',
      label: `${this.props.departure.id}`
    });
  };

  handleOpenReport = async () => {
    let route = createLink(ROUTE_DEPARTURESUMMARYVIEW, {
      id: this.props.departure.id
    });
    let urlParams = {
      ...this.state,
      executionDateTimeStr: convertToAPIDate(new Date())
    };
    let url = createURLWithQueryParams(route, urlParams);

    this.props.actions.showLoading();
    await this.props.getFile(url, this.props.departure.departureCode);
    this.props.actions.hideLoading();
  };

  handleDepartureSummaryReport = () => {
    this.handleTealiumViewRequest();
    this.handleOpenReport();
  };

  render() {
    const { pax, departure } = this.props;

    return (
      <div>
        <Card className={cardStyles.card}>
          <DepartureCardHeader departure={departure} />
          <DepartureCardContent departure={departure} paxCount={pax.length} />
          <Divider />
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      disabled={true}
                      id={'ckb.paxOnTrip'}
                    />
                  }
                  label={<Typography variant="caption">Pax on Trip</Typography>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e =>
                        this.handleCheck('showPaxCheckedDetails', e)
                      }
                      id={'ckb.showPaxCheckedDetails'}
                    />
                  }
                  label={
                    <Typography variant="caption">
                      Pax Checked Details
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => this.handleCheck('showDateOfBirth', e)}
                      id={'ckb.showDateOfBirth'}
                    />
                  }
                  label={
                    <Typography variant="caption">Date of Birth</Typography>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => this.handleCheck('showNationality', e)}
                      id={'ckb.showNationality'}
                    />
                  }
                  label={<Typography variant="caption">Nationality</Typography>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => this.handleCheck('showEmail', e)}
                      id={'ckb.showEmail'}
                    />
                  }
                  label={<Typography variant="caption">Pax Email</Typography>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => this.handleCheck('showHeightWeight', e)}
                      id={'ckb.showHeightWeight'}
                    />
                  }
                  label={
                    <Typography variant="caption">Height / Weight</Typography>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => this.handleCheck('showPaxDetails', e)}
                      id={'ckb.showPaxDetails'}
                    />
                  }
                  label={<Typography variant="caption">Pax Details</Typography>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => this.handleCheck('showPassport', e)}
                      id={'ckb.showPassport'}
                    />
                  }
                  label={<Typography variant="caption">Passport</Typography>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => this.handleCheck('showInsurance', e)}
                      id={'ckb.showInsurance'}
                    />
                  }
                  label={<Typography variant="caption">Insurance</Typography>}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e =>
                        this.handleCheck('showEmergencyContact', e)
                      }
                      id={'ckb.showEmergencyContact'}
                    />
                  }
                  label={
                    <Typography variant="caption">Emergency Contact</Typography>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => this.handleCheck('showVisa', e)}
                      id={'ckb.showVisa'}
                    />
                  }
                  label={<Typography variant="caption">Visa</Typography>}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <div className={cardStyles.confirmButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleDepartureSummaryReport}
                id="btn.submit"
              >
                Generate
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

DepartureReportCard.propTypes = {
  getFile: PropTypes.func,
  departure: PropTypes.object,
  pax: PropTypes.array,
  actions: PropTypes.object
};

export default DepartureReportCard;
