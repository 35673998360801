import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';
import selectors from '../selectors';
import DepartureDetails from '../components/DepartureDetails';
import { useParams } from 'react-router-dom';

const PaxListContainer = ({
  actions,
  pax,
  departure,
  transfers,
  accommodations,
  sightseeings,
  paxChanges
}) => {
  const { id } = useParams();

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadData = id => {
    actions.getPax(id);
    actions.getTransfers(id);
    actions.getAccommodations(id);
    actions.getSightseeings(id);
    actions.getPaxChanges(id);
  };

  return (
    pax &&
    departure && (
      <DepartureDetails
        pax={pax}
        departure={departure}
        transfers={transfers}
        accommodations={accommodations}
        sightseeings={sightseeings}
        paxUpdates={paxChanges}
      />
    )
  );
};

PaxListContainer.propTypes = {
  pax: PropTypes.array,
  actions: PropTypes.shape({
    getPax: PropTypes.func.isRequired,
    getTransfers: PropTypes.func.isRequired,
    getAccommodations: PropTypes.func.isRequired,
    getSightseeings: PropTypes.func.isRequired,
    getPaxChanges: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => {
  const stateDeparturePax = selectors.pax.getPax(state);
  const pax =
    stateDeparturePax && stateDeparturePax.pax ? stateDeparturePax.pax : null;

  return {
    pax,
    departure: selectors.selectedDeparture.getDeparture(state),
    transfers: selectors.selectedDepartureTransfers.getTransfers(state),
    accommodations: selectors.selectedDepartureAccommodations.getAccommodations(
      state
    ),
    sightseeings: selectors.selectedDepartureSightseeings.getSightseeings(
      state
    ),
    paxChanges: selectors.selectedDeparturePaxChanges.getPaxChanges(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPax: actions.selectedDeparture.getSelectedDeparture,
      getTransfers: actions.transfers.getDepartureTransfers,
      getAccommodations: actions.accommodations.getDepartureAccommodations,
      getSightseeings: actions.sightseeings.getDepartureSightseeings,
      getPaxChanges: actions.paxChanges.getDeparturePaxChanges
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(PaxListContainer);
