export const getPax = state => state.departures.pax;

export const getConfirmedPaxByBookingId = (state, bookingId, paxId) => {
  let paxList = state.departures.pax;
  return (
    paxList &&
    paxList.pax &&
    paxList.pax.filter(
      p =>
        p.bookingId === bookingId &&
        p.detailsConfirmed === true &&
        p.id !== paxId
    )
  );
};
