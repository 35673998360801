import { useLocation, useNavigate, useParams } from 'react-router';

const CustomRouterWrapper = WrappedComponent => {
  const ComponentWithRouterProps = props => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    return (
      <WrappedComponent {...props} router={{ params, navigate, location }} />
    );
  };

  return ComponentWithRouterProps;
};

export default CustomRouterWrapper;
