export default {
  actionAlignment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    align: 'center',
    paddingTop: '10px'
  },
  iconText: {
    fontSize: '0.60rem'
  }
};
