import * as actionTypes from '../actionTypes/meal';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.GET_SUCCESS:
      return action.payload.recordList.meals;
    case actionTypes.INSERT_SUCCESS:
      return [...state, action.payload.record];
    case actionTypes.DELETE_SUCCESS:
      return state.filter(x => x.id !== action.payload.recordId);
    case actionTypes.UPDATE_SUCCESS:
      let newState = Object.assign([], state);
      let itemIndex = state.findIndex(x => x.id === action.payload.record.id);
      newState[itemIndex] = action.payload.record;
      return newState;
    default:
      return state;
  }
};
