import * as actionTypes from '../actionTypes/covidQuestionnaireResponses';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.COVID_QUESTIONNAIRE_RESPONSES_GET_SUCCESS:
      return action.payload.covidQuestionnaireResponses;
    case actionTypes.COVID_QUESTIONNAIRE_RESPONSES_CLEAR:
      return null;
    default:
      return state;
  }
};
