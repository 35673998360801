export default value => {
  // Convert null values to empty string
  const phone = value || '';

  // Empty string is  a valid value
  if (phone.trim() === '') {
    return '';
  }

  // Get all numbers entered (replace all instances of + and spaces)
  const onlyNums = phone.replace(new RegExp('[\\+ ]', 'g'), '');

  // Validate the pattern and min length
  let pattern = new RegExp('^\\+?[0-9 ]+$');
  if (!pattern.test(phone.trimRight()) || onlyNums.length < 6) {
    return 'Invalid. Valid format is +61370102496';
  }

  return '';
};
