export default class Currency {
  constructor(service) {
    this.service = service;
  }

  async get() {
    const resourcePath = `/tripReport/currency`;

    const data = await this.service.get(resourcePath);

    return data.currencies;
  }
}
