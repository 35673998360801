import * as actionTypes from '../actionTypes/costChange';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import TripReportSectionBaseAction from './TripReportSectionBaseAction';

export default class CostChangeAction extends TripReportSectionBaseAction {
  constructor() {
    super(
      LeaderPortalService.TripReport.CostChange,
      actionTypes,
      'Cost change'
    );
  }
}
