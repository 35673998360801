const getSelectedPaxDeparture = state => state.departures.selectedPaxDeparture;
const getSelectedPaxPassport = state => state.departures.selectedPaxPassport;
const getSelectedPaxVisa = state => state.departures.selectedPaxVisa;
const getSelectedPaxInsurance = state => state.departures.selectedPaxInsurance;
const getSelectedPaxEmergencyContact = state =>
  state.departures.selectedPaxEmergencyContact;
const getSelectedPaxContact = state => state.departures.selectedPaxContact;

export const getSelectedPax = state => {
  return {
    pax: getSelectedPaxDeparture(state),
    passport: getSelectedPaxPassport(state),
    insurance: getSelectedPaxInsurance(state),
    visa: getSelectedPaxVisa(state),
    emergencyContact: getSelectedPaxEmergencyContact(state),
    contact: getSelectedPaxContact(state)
  };
};

export const getSelectedDeparturePax = state => {
  return {
    pax: getSelectedPaxDeparture(state)
  };
};
