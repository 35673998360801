import React from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { withStyles } from '@material-ui/core/styles';
import styles from './ProgressIndicator.styles';

const ProgressIndicator = ({ classes }) => (
  <div className={classes.container}>
    <LoadingBar
      className={classes.bar}
      updateTime={30}
      maxProgress={100}
      progressIncrease={10}
    />
  </div>
);

export default withStyles(styles)(ProgressIndicator);
