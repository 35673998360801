import * as actionTypes from '../actionTypes/transfers';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.DEPARTURE_TRANSFERS_GET_SUCCESS:
      return action.payload.transfers;
    case actionTypes.DEPARTURE_TRANSFERS_CLEAR:
      return null;
    default:
      return state;
  }
};
