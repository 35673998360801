import * as React from 'react';
import styles from './PaxUpdateCardDetails.styles';
import InputField from '../../common/forms/InputField';
import FullName, {
  FullNameMembers,
  FullNameField
} from '../../common/forms/FullName';
import SingleSelect from '../../common/forms/SingleSelect';
import lengthRange from '../../common/forms/validators/lengthRange';
import DateField from '../../common/forms/DateField';
import dateRange from '../../common/forms/validators/dateRange';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardHeader } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import dateRequired from '../../common/forms/validators/dateRequired';
import required from '../../common/forms/validators/required';

const PaxUpdateCardPassportDetails = ({
  pax,
  countries,
  sectionCardStyle,
  classes
}) => {
  var today = new Date();
  const maxDateofBirth = today.setFullYear(new Date().getFullYear() - 3);
  const minDateofBirth = today.setFullYear(new Date().getFullYear() - 100);

  const countryList = countries
    ? countries
        .map(country => ({
          value: country.name,
          label: (
            country.webName ||
            country.alternativeName ||
            country.name
          ).toUpperCase()
        }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
    : [];

  const firstName = new FullNameField(
    'pax.firstName',
    'First Name',
    '',
    classes.wideField,
    required,
    50
  );
  const middleName = new FullNameField(
    'pax.middleName',
    'Middle Name',
    '',
    classes.wideField,
    undefined,
    50
  );
  const surname = new FullNameField(
    'pax.surname',
    'Surname',
    '',
    classes.wideField,
    required,
    50
  );
  const fullNameMembers = new FullNameMembers(firstName, surname, middleName);

  return (
    <div>
      <Card>
        <CardHeader
          style={sectionCardStyle.header}
          title={
            <Typography
              align="center"
              variant="body1"
              style={{ fontWeight: '500' }}
            >
              Passport Information
            </Typography>
          }
        />
        <CardContent>
          <Typography align="center" variant="caption">
            <div style={{ textAlign: 'center' }}>
              (All information should appear as per passport)
            </div>
          </Typography>

          <FullName
            members={fullNameMembers}
            disabled={Object.keys(pax.passport).length == 0}
          />
          <div>
            <DateField
              className={classes.wideField}
              maxDate={maxDateofBirth}
              minDate={minDateofBirth}
              name="pax.dateOfBirth"
              validate={value =>
                dateRequired(value) ||
                dateRange(minDateofBirth, maxDateofBirth)(value)
              }
              disableFutureDate={true}
              placeholder="Date of Birth"
              disabled={Object.keys(pax.passport).length == 0}
            />
          </div>
          <div>
            <SingleSelect
              name="passport.nationality"
              label="Nationality"
              validate={required}
              options={countryList}
              className={classes.wideField}
              disabled={Object.keys(pax.passport).length == 0}
            />
          </div>
          <div>
            <InputField
              name="passport.passportNumber"
              placeholder="Passport Number"
              validate={value =>
                required(value) || lengthRange(5, undefined)(value)
              }
              className={classes.wideField}
              maxLength={16}
              disabled={Object.keys(pax.passport).length == 0}
            />
          </div>
          <div>
            <DateField
              className={classes.wideField}
              disableFutureDate={false}
              name="passport.passportExpiry"
              placeholder="Passport Expiry"
              disabled={Object.keys(pax.passport).length == 0}
              validate={dateRequired}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(PaxUpdateCardPassportDetails);
