import * as actionTypes from '../actionTypes/responsibleTravelCategories';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const tripReportResponsibleTravelCategoriesGetRequest = () => ({
  type: actionTypes.RESPONSIBLE_TRAVEL_CATEGORIES_GET_REQUEST
});

export const tripReportResponsibleTravelCategoriesGetSuccess = responsibleTravelCategories => ({
  type: actionTypes.RESPONSIBLE_TRAVEL_CATEGORIES_GET_SUCCESS,
  payload: {
    responsibleTravelCategories
  }
});

export const getResponsibleTravelCategories = () => async dispatch => {
  try {
    dispatch(tripReportResponsibleTravelCategoriesGetRequest());

    const categories = await LeaderPortalService.TripReport.ResponsibleTravelCategories.get();

    dispatch(tripReportResponsibleTravelCategoriesGetSuccess(categories));
  } catch (e) {
    console.log(e);
  }
};
