import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import styles from './ActionIcon.styles';

const ActionIcon = ({ iconType, label, color, classes }) => (
  <div className={classes.actionAlignment}>
    <Icon color={color} className={classes.icon}>
      {iconType}
    </Icon>
    <Typography variant="caption" className={classes.iconText}>
      {label}
    </Typography>
  </div>
);

ActionIcon.propTypes = {
  iconType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default withStyles(styles)(ActionIcon);
