import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Field } from 'react-final-form';
import ErrorIcon from '../../common/components/ErrorIcon';
import { withStyles } from '@material-ui/core/styles';
import styles from './PaxUpdateCardConfirmation.styles';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import checked from '../../common/forms/validators/checked';

class PaxUpdateCardConfirmation extends React.Component {
  render() {
    const { values, classes, sectionCardStyle } = this.props;

    return (
      <div>
        <Card>
          <CardHeader
            style={sectionCardStyle.header}
            className={styles.sectionCardHeader}
            title={
              <Typography align="center" variant="body2">
                {' '}
                {/* Changed type to variant */}
                Confirmation
              </Typography>
            }
          />
          <CardContent>
            <Field
              name={'pax.detailsConfirmed'}
              validate={value => checked(value)}
            >
              {({ input, meta }) => {
                let { value, ...restInput } = input;

                return (
                  <FormControl error={!!meta.error}>
                    <FormHelperText className={classes.confirmationMessage}>
                      {meta.touched && meta.error && (
                        <div>
                          <ErrorIcon />
                          Please check the confirmation box
                        </div>
                      )}
                    </FormHelperText>
                    <div className={classes.confirmationBox}>
                      <Checkbox
                        checked={value}
                        {...restInput}
                        id={'ckb.detailsConfirmed'}
                      />
                      <Paper
                        className={classes.confirmationPaper}
                        elevation={2}
                      >
                        <Typography component="p">
                          I, {values.pax.firstName} {values.pax.surname},
                          confirm I have reviewed this information and the
                          details listed above are correct.
                        </Typography>
                      </Paper>
                    </div>
                  </FormControl>
                );
              }}
            </Field>
          </CardContent>
        </Card>
      </div>
    );
  }
}

PaxUpdateCardConfirmation.propTypes = {
  values: PropTypes.object,
  sectionCardStyle: PropTypes.object // Added propTypes for sectionCardStyle
};

export default withStyles(styles)(PaxUpdateCardConfirmation);
