import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styles from './Rating.styles';
import Icon from '@material-ui/core/Icon';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';

class FieldAdapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: 0
    };
  }

  ratingIcon = value => {
    if (value <= this.getSelectedValue()) {
      return <Icon className={this.props.classes.star}>star</Icon>;
    } else {
      return <Icon className={this.props.classes.star}>star_border</Icon>;
    }
  };

  handleChange = event => {
    this.setState({ selectedValue: parseInt(event.target.value) });
    if (this.props.update != undefined) {
      this.props.update(event.target.value);
    }
  };

  getSelectedValue = () => {
    let inputValue = this.props && this.props.input && this.props.input.value;
    return this.state.selectedValue > 0 ? this.state.selectedValue : inputValue;
  };

  getRadio = value => {
    return (
      <Radio
        name={this.props.name}
        value={value.toString()}
        checked={this.getSelectedValue() === value}
        onChange={this.handleChange}
        icon={this.ratingIcon(value)}
        checkedIcon={this.ratingIcon(value)}
      />
    );
  };

  render() {
    const { input, meta, classes, ...rest } = this.props;

    return (
      <div>
        {rest.label} <br />
        <div className={classes.stars}>
          {this.getRadio(1)}
          {this.getRadio(2)}
          {this.getRadio(3)}
          {this.getRadio(4)}
          {this.getRadio(5)}
        </div>
      </div>
    );
  }
}

const fieldAdapter = withStyles(styles)(FieldAdapter);

class Rating extends React.Component {
  render() {
    const { name, classes, label = '', update } = this.props;

    return (
      <Field
        name={name}
        classes={classes}
        component={fieldAdapter}
        label={label}
        update={update}
      />
    );
  }
}

Rating.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  update: PropTypes.func
};

export default withStyles(styles)(Rating);
