import React from 'react';
import PropTypes from 'prop-types';
import PaxCard from './PaxCard';
import Card from '@material-ui/core/Card';
import DepartureCardContent from './DepartureCardContent';
import DepartureCardHeader from './DepartureCardHeader';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ActionIcon from '../../common/components/ActionIcon';
import TransferList from './TransferList';
import AccommodationList from './AccommodationList';
import SightseeingList from './SightseeingList';
import PaxChanges from './PaxChanges';
import GlobalMessageCard from '../../common/components/GlobalMessageCard';
import { withStyles } from '@material-ui/core/styles';
import styles from './DepartureDetails.styles';

class DepartureDetails extends React.Component {
  state = {
    selectedTabIndex: 0
  };

  handleChange = (event, selectedTabIndex) => {
    this.setState({ selectedTabIndex });
  };

  render() {
    const {
      pax,
      departure,
      transfers,
      accommodations,
      sightseeings,
      paxUpdates,
      classes
    } = this.props;
    const { selectedTabIndex } = this.state;

    const paxSorted = pax.sort((a, b) =>
      a.bookingId < b.bookingId ? -1 : a.bookingId > b.bookingId ? 1 : 0
    );

    return (
      <div>
        <Card>
          <DepartureCardHeader departure={departure} />
          <DepartureCardContent departure={departure} paxCount={pax.length} />
        </Card>
        <Paper className={classes.paper}>
          <Tabs
            value={this.state.selectedTabIndex}
            onChange={this.handleChange}
            fullWidth
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              id={'btn.passengers_' + departure.departureCode}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="people"
                  label={
                    <span style={{ textTransform: 'none' }}>Pax Info</span>
                  }
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
            <Tab
              id={'btn.transfers_' + departure.departureCode}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="local_taxi"
                  label={
                    <span style={{ textTransform: 'none' }}>Transfers</span>
                  }
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
            <Tab
              id={'btn.accommodation_' + departure.departureCode}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="hotel"
                  label={<span style={{ textTransform: 'none' }}>Accom.</span>}
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
            <Tab
              id={'btn.sightseeing_' + departure.departureCode}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="add_shopping_cart"
                  label={
                    <span style={{ textTransform: 'none' }}>Sightseeing</span>
                  }
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
            <Tab
              id={'btn.paxUpdates_' + departure.departureCode}
              classes={{ root: classes.tab }}
              icon={
                <ActionIcon
                  iconType="update"
                  label={<span style={{ textTransform: 'none' }}>Changes</span>}
                  color="secondary"
                  className={classes.icon}
                />
              }
            />
          </Tabs>
        </Paper>

        {selectedTabIndex === 0 &&
          (paxSorted.length ? (
            paxSorted.map(pax => (
              <PaxCard
                key={pax.id}
                pax={pax}
                departureStartDate={departure.departureDate}
                departureEndDate={departure.finishDate}
              />
            ))
          ) : (
            <GlobalMessageCard message="No pax on departure" />
          ))}
        {selectedTabIndex === 1 &&
          (transfers.length ? (
            <TransferList transfers={transfers} />
          ) : (
            <GlobalMessageCard message="No transfers booked" />
          ))}
        {selectedTabIndex === 2 &&
          (accommodations.length ? (
            <AccommodationList accommodations={accommodations} />
          ) : (
            <GlobalMessageCard message="No accommodation booked" />
          ))}
        {selectedTabIndex === 3 &&
          sightseeings &&
          (sightseeings.length ? (
            <SightseeingList sightseeings={sightseeings} />
          ) : (
            <GlobalMessageCard message="No sightseeing booked" />
          ))}
        {selectedTabIndex === 4 &&
          paxUpdates &&
          (paxUpdates.length ? (
            <PaxChanges paxChanges={paxUpdates} paxList={pax} />
          ) : (
            <GlobalMessageCard message="No changes" />
          ))}
      </div>
    );
  }
}

DepartureDetails.propTypes = {
  departure: PropTypes.object,
  pax: PropTypes.array
};

export default withStyles(styles)(DepartureDetails);
