import * as actionTypes from '../actionTypes/paxChanges';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const departurePaxChangesGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_PAX_CHANGES_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departurePaxChangesGetSuccess = paxChanges => ({
  type: actionTypes.DEPARTURE_PAX_CHANGES_GET_SUCCESS,
  payload: {
    paxChanges
  }
});

export const getDeparturePaxChanges = id => async dispatch => {
  dispatch(departurePaxChangesGetRequest(id));

  const paxChanges = await LeaderPortalService.Departures.getPaxChanges(id);

  dispatch(departurePaxChangesGetSuccess(paxChanges));
};
