import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './CovidQuestionnaire.styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup'; // Correct import for FormGroup

class CovidQuestionRepresentationCheckBox extends React.Component {
  checkChange(
    checked,
    covidQuestionnaireQuestionId,
    covidQuestionAnswerText,
    handleAnswerClick,
    covidQuestionResponse
  ) {
    if (checked) {
      handleAnswerClick(
        covidQuestionnaireQuestionId,
        covidQuestionAnswerText.covidQuestionAnswerTextId,
        covidQuestionAnswerText.isAlertingAnswer,
        covidQuestionResponse
      );
    } else {
      handleAnswerClick(
        covidQuestionnaireQuestionId,
        null,
        null,
        covidQuestionResponse
      );
    }
  }

  getAnswers(
    covidQuestionAnswerText,
    covidQuestionnaireQuestionId,
    classes,
    covidQuestionResponse,
    answer,
    handleAnswerClick
  ) {
    return (
      <FormControlLabel
        control={<Checkbox />}
        label={covidQuestionAnswerText.answerText}
        disabled={covidQuestionResponse != null}
        checked={
          answer === covidQuestionAnswerText.covidQuestionAnswerTextId ||
          (covidQuestionResponse &&
            covidQuestionResponse.covidQuestionAnswerTextId ===
              covidQuestionAnswerText.covidQuestionAnswerTextId)
        }
        onChange={e =>
          this.checkChange(
            e.target.checked,
            covidQuestionnaireQuestionId,
            covidQuestionAnswerText,
            handleAnswerClick,
            covidQuestionResponse
          )
        }
      />
    );
  }

  render() {
    const {
      covidQuestionnaireQuestionId,
      covidQuestionAnswerTexts,
      covidQuestionResponse,
      classes,
      handleAnswerClick,
      answer
    } = this.props;

    const checkBoxList = covidQuestionAnswerTexts.map(covidQuestionAnswerText =>
      this.getAnswers(
        covidQuestionAnswerText,
        covidQuestionnaireQuestionId,
        classes,
        covidQuestionResponse,
        answer,
        handleAnswerClick
      )
    );

    return (
      <div className={classes.QuestionCheckBoxContainer}>
        <FormGroup>{checkBoxList}</FormGroup>
      </div>
    );
  }
}

export default withStyles(styles)(CovidQuestionRepresentationCheckBox);
