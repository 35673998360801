export default {
  root: {
    flexGrow: 1,
    margin: '10px 0px 10px 0px',
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative'
  },
  inputLabel: {
    marginBottom: 14
  },
  helperTextLabel: {
    marginTop: 8
  }
};
