import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    padding: '5px 10px 5px 10px'
  }
};

const GlobalMessageCard = ({ iconType, message }) => (
  <Paper>
    <div style={styles.container}>
      <div style={styles.content}>
        <Icon style={styles.icon}>{iconType}</Icon>
      </div>
      <div style={styles.content}>
        <Typography align="left" type="body2">
          {message}
        </Typography>
      </div>
    </div>
  </Paper>
);

GlobalMessageCard.propTypes = {
  iconType: PropTypes.string,
  message: PropTypes.string.isRequired
};

GlobalMessageCard.defaultProps = {
  iconType: 'info'
};

export default withStyles(styles)(GlobalMessageCard);
