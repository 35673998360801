import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';
import selectors from '../selectors';
import DepartureList from '../components/DepartureList';

const DepartureListContainer = props => {
  useEffect(() => {
    loadData();
  }, []); // Empty dependency array to run only once on mount

  const loadData = () => {
    props.actions.getDepartures();
  };

  return props.departures ? <DepartureList {...props} /> : null;
};

DepartureListContainer.propTypes = {
  actions: PropTypes.shape({
    getDepartures: PropTypes.func.isRequired
  }).isRequired,
  departures: PropTypes.array // Adjust this based on the actual structure of departures
};

const mapStateToProps = state => ({
  departures: selectors.departures.getDepartures(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDepartures: actions.departures.getDeparturesForLeader
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartureListContainer);
