import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './core/components/App';
import store from './core/store';
import {
  runWithReactAdal,
  authContext
} from './common/authentication/adalConfig';
import trackjs from './common/helpers/trackjs';
import Unauthorized401 from './common/components/Unauthorized401';
import './index.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// Access denied
if (window.location.href.includes('error=access_denied')) {
  root.render(<Unauthorized401 />);
} else {
  runWithReactAdal(() => {
    trackjs(authContext);
    root.render(
      <Provider store={store}>
        <App />
      </Provider>
    );
  });
}
