const transformList = data => {
  const crewRoles = data.crewRoles;

  return crewRoles.map(crewRole => transform(crewRole));
};

const transform = data => {
  const crewRole = data;

  return crewRole;
};

export default class CrewRoles {
  constructor(service) {
    this.service = service;
  }

  async get() {
    const resourcePath = `/crewRole`;

    const data = await this.service.get(resourcePath);

    return transformList(data);
  }
}
