import * as actionTypes from '../actionTypes/currencies';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const tripReportCurrenciesGetRequest = () => ({
  type: actionTypes.CURRENCIES_GET_REQUEST
});

export const tripReportCurrenciesGetSuccess = currencies => ({
  type: actionTypes.CURRENCIES_GET_SUCCESS,
  payload: {
    currencies
  }
});

export const getCurrencies = () => async dispatch => {
  try {
    dispatch(tripReportCurrenciesGetRequest());

    const currencies = await LeaderPortalService.TripReport.Currency.get();

    dispatch(tripReportCurrenciesGetSuccess(currencies));
  } catch (e) {
    console.log(e);
  }
};
