import * as React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { CardContent } from '@material-ui/core/';
import Icon from '@material-ui/core/Icon';
import KeyValueItem from '../../common/components/KeyValueItem';
import Grid from '@material-ui/core/Grid';

const DepartureCardContent = ({ departure, expandable, paxCount }) => {
  const startDate = format(
    departure.departureDate,
    process.env.REACT_APP_DATE_FORMAT
  );
  const endDate = format(
    departure.finishDate,
    process.env.REACT_APP_DATE_FORMAT
  );

  const labelType = 'body2';

  const labelStyles = {
    fontSize: '0.875rem',
    fontWeight: '500'
  };
  const valueStyles = {
    fontSize: '0.875rem',
    fontWeight: '400'
  };

  return (
    <CardContent>
      <Grid container spacing={0}>
        <Grid item xs={6} sm={6}>
          <KeyValueItem
            label={<span style={labelStyles}>Start Date</span>}
            labelType={labelType}
            value={<span style={valueStyles}>{startDate}</span>}
            icon={<Icon>date_range</Icon>}
            valueId={'departure.startDate_' + departure.departureCode}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <KeyValueItem
            label={<span style={labelStyles}>End Date</span>}
            labelType={labelType}
            value={<span style={valueStyles}>{endDate}</span>}
            icon={<Icon>date_range</Icon>}
            valueId={'departure.endDate_' + departure.departureCode}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <KeyValueItem
            label={<span style={labelStyles}>Start Point</span>}
            labelType={labelType}
            value={<span style={valueStyles}>{departure.startCity}</span>}
            icon={<Icon>room</Icon>}
            valueId={'departure.startCity_' + departure.departureCode}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <KeyValueItem
            label={<span style={labelStyles}>End Point</span>}
            labelType={labelType}
            value={<span style={valueStyles}>{departure.endCity}</span>}
            icon={<Icon>room</Icon>}
            valueId={'departure.endCity_' + departure.departureCode}
          />
        </Grid>
        {paxCount != null ? (
          <Grid item xs={6} sm={6}>
            <KeyValueItem
              label={<span style={labelStyles}>Pax Count</span>}
              labelType={labelType}
              value={<span style={valueStyles}>{paxCount}</span>}
              icon={<Icon>group</Icon>}
              valueId={'departure.paxCount_' + departure.departureCode}
            />
          </Grid>
        ) : null}
      </Grid>
    </CardContent>
  );
};

DepartureCardContent.propTypes = {
  departure: PropTypes.object,
  expandable: PropTypes.bool,
  paxCount: PropTypes.number
};

export default DepartureCardContent;
