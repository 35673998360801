export const PAX_GET_REQUEST = 'pax/PAX_GET_REQUEST';
export const PAX_GET_SUCCESS = 'pax/PAX_GET_SUCCESS';
export const PAX_CLEAR = 'pax/PAX_CLEAR';

export const PAX_PASSPORT_GET_REQUEST = 'pax/PAX_PASSPORT_GET_REQUEST';
export const PAX_PASSPORT_GET_SUCCESS = 'pax/PAX_PASSPORT_GET_SUCCESS';
export const PAX_PASSPORT_CLEAR = 'pax/PAX_PASSPORT_CLEAR';

export const PAX_EMERGENCYCONTACT_GET_REQUEST =
  'pax/PAX_EMERGENCYCONTACT_GET_REQUEST';
export const PAX_EMERGENCYCONTACT_GET_SUCCESS =
  'pax/PAX_EMERGENCYCONTACT_GET_SUCCESS';
export const PAX_EMERGENCYCONTACT_CLEAR = 'pax/PAX_EMERGENCYCONTACT_CLEAR';

export const PAX_CONTACT_GET_REQUEST = 'pax/PAX_CONTACT_GET_REQUEST';
export const PAX_CONTACT_GET_SUCCESS = 'pax/PAX_CONTACT_GET_SUCCESS';
export const PAX_CONTACT_CLEAR = 'pax/PAX_CONTACT_CLEAR';

export const PAX_VISA_GET_REQUEST = 'pax/PAX_VISA_GET_REQUEST';
export const PAX_VISA_GET_SUCCESS = 'pax/PAX_VISA_GET_SUCCESS';
export const PAX_VISA_CLEAR = 'pax/PAX_VISA_CLEAR';

export const PAX_INSURANCE_GET_REQUEST = 'pax/PAX_INSURANCE_GET_REQUEST';
export const PAX_INSURANCE_GET_SUCCESS = 'pax/PAX_INSURANCE_GET_SUCCESS';
export const PAX_INSURANCE_CLEAR = 'pax/PAX_INSURANCE_CLEAR';

export const DEPARTURE_PAX_GET_REQUEST = 'pax/DEPARTURE_PAX_GET_REQUEST';
export const DEPARTURE_PAX_GET_SUCCESS = 'pax/DEPARTURE_PAX_GET_SUCCESS';
export const DEPARTURE_PAX_CLEAR = 'pax/DEPARTURE_PAX_CLEAR';

export const PAX_UPDATE_REQUEST = 'pax/PAX_UPDATE_REQUEST';
export const PAX_UPDATE_SUCCESS = 'pax/PAX_UPDATE_SUCCESS';
