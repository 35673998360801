import * as actionTypes from '../actionTypes/paxTripNote';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import TripReportSectionBaseAction from './TripReportSectionBaseAction';

export default class PaxTripNoteAction extends TripReportSectionBaseAction {
  constructor() {
    super(
      LeaderPortalService.TripReport.PaxTripNote,
      actionTypes,
      'Pax trip notes'
    );
  }
}
