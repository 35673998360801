import React from 'react';
import styles from './TickButton.styles.js';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import ActionIcon from '../../common/components/ActionIcon';

class TickButton extends React.Component {
  render() {
    const {
      tickState,
      iconType,
      displayText,
      tooltip,
      action,
      id,
      classes
    } = this.props;

    return (
      <IconButton
        className={classes.iconButton}
        tooltip={tooltip}
        id={id}
        component={Link}
        to={action}
      >
        <ActionIcon iconType={iconType} label={displayText} color="secondary" />
        {tickState ? (
          <div className={classes.tickDiv}>
            <Icon className={classes.tick}>check_circle</Icon>
          </div>
        ) : (
          <div className={classes.tickDiv}>
            <span className={classes.blankTick} />
          </div>
        )}
      </IconButton>
    );
  }
}

export default withStyles(styles)(TickButton);
