export const COVIDQUESTIONAIR_GET_REQUEST =
  'departures/COVIDQUESTIONAIR_GET_REQUEST';
export const COVIDQUESTIONAIR_GET_FAILURE =
  'departures/COVIDQUESTIONAIR_GET_FAILURE';
export const COVIDQUESTIONAIR_GET_SUCCESS =
  'departures/COVIDQUESTIONAIR_GET_SUCCESS';

export const COVIDQUESTIONAIR_POST_REQUEST =
  'departures/COVIDQUESTIONAIR_POST_REQUEST';
export const COVIDQUESTIONAIR_POST_FAILURE =
  'departures/COVIDQUESTIONAIR_POST_FAILURE';
export const COVIDQUESTIONAIR_POST_SUCCESS =
  'departures/COVIDQUESTIONAIR_POST_SUCCESS';
