import * as actionTypes from '../actionTypes/responsibleTravel';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import TripReportSectionBaseAction from './TripReportSectionBaseAction';

export default class ResponsibleTravelAction extends TripReportSectionBaseAction {
  constructor() {
    super(
      LeaderPortalService.TripReport.ResponsibleTravel,
      actionTypes,
      'Responsible travel'
    );
  }
}
