export default {
  wrapper: {
    width: '100%',
    // border: '1px solid black',
    overflow: 'hidden'
  },
  first: {
    width: '5%',
    // border: '1px solid red',
    float: 'left'
  },
  second: {
    width: '95%',
    // border: '1px solid green',
    float: 'left'
  },
  icon: {
    width: '25px',
    height: '25px'
  },
  divCenter1: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    padding: '40px 0 0 0'
  },
  divCenter2: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    padding: '0 0 0px 0'
  },
  cardContent: {
    padding: '10px 03px'
  },
  verticalLine: {
    height: '50%',
    width: '0',
    border: '1px solid black',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  stepCompleted: {
    '& svg': {
      fill: '#448644'
    }
  },
  stepFailed: {
    '& svg': {
      fill: '#dc3814'
    }
  },
  warningMessage: {
    fontSize: '9pt',
    fontStyle: 'italic !important'
  },
  covidResponseWarning: {
    fontSize: '10pt',
    fontStyle: 'italic !important',
    color: '#dc3814'
  },
  covidResponseWarningIcon: {
    color: '#dc3814',
    fontSize: '18px'
  },
  list: {
    fontSize: '9pt'
  },
  errorMessage: {
    fontSize: '9pt',
    fontStyle: 'italic !important',
    color: '#dc3814'
  }
};
