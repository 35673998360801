export default {
  field: {
    margin: '5px 0px 5px 0px'
  },

  titleRow: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold'
  },

  titleRowWithMargin: {
    extend: 'titleRow',
    margin: '15px 0px 10px 0px'
  },

  wideField: {
    extend: 'field',
    width: '100%'
  }
};
