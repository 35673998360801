export const REVIEW_COMPLETE_REQUEST = 'leaderActions/REVIEW_COMPLETE_REQUEST';
export const REVIEW_COMPLETE_SUCCESS = 'leaderActions/REVIEW_COMPLETE_SUCCESS';
export const REVIEW_COMPLETE_FAILURE = 'leaderActions/REVIEW_COMPLETE_FAILURE';

export const TRIP_REPORT_UPLOAD_REQUEST =
  'leaderActions/TRIP_REPORT_UPLOAD_REQUEST';
export const TRIP_REPORT_UPLOAD_SUCCESS =
  'leaderActions/TRIP_REPORT_UPLOAD_SUCCESS';
export const TRIP_REPORT_UPLOAD_FAILURE =
  'leaderActions/TRIP_REPORT_UPLOAD_FAILURE';

export const SHAREPOINT_FILE_UPLOADS_REQUEST =
  'leaderActions/SHAREPOINT_FILE_UPLOADS_REQUEST';
export const SHAREPOINT_FILE_UPLOADS_SUCCESS =
  'leaderActions/SHAREPOINT_FILE_UPLOADS_SUCCESS';
export const SHAREPOINT_FILE_UPLOADS_FAILURE =
  'leaderActions/SHAREPOINT_FILE_UPLOADS_FAILURE';
export const SHAREPOINT_FILE_UPLOADS_CLEAR =
  'leaderActions/SHAREPOINT_FILE_UPLOADS_CLEAR';

export const DEPARTURE_PAX_REVIEW_DETAIL_REQUEST =
  'leaderActions/DEPARTURE_PAX_REVIEW_DETAIL_REQUEST';
export const DEPARTURE_PAX_REVIEW_DETAIL_SUCCESS =
  'leaderActions/DEPARTURE_PAX_REVIEW_DETAIL_SUCCESS';
export const DEPARTURE_PAX_REVIEW_DETAIL_FAILURE =
  'leaderActions/DEPARTURE_PAX_REVIEW_DETAIL_FAILURE';
export const DEPARTURE_PAX_REVIEW_DETAIL_CLEAR =
  'leaderActions/DEPARTURE_PAX_REVIEW_DETAIL_CLEAR';
