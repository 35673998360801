import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import KeyValueItem from '../../common/components/KeyValueItem';
import { withStyles } from '@material-ui/core/styles';
import createFullName from '../../common/helpers/createFullName';
import { sortBy, groupBy } from 'lodash';
import styles from '../../common/styles/AdditionalServices.module.css';
import { format } from 'date-fns';
import Grid from '@material-ui/core/Grid';

const themes = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0
  }
});

const formatDate = dateString => {
  return dateString !== null
    ? format(new Date(dateString), process.env.REACT_APP_DATE_FORMAT)
    : null;
};

const SightseeingList = ({ sightseeings, classes }) => {
  const sortedSightseeings = sortBy(sightseeings, ['serviceName', 'startDate']);
  const sightseeingGroups = groupBy(sortedSightseeings, 'serviceName');

  return (
    <List className={classes.root}>
      {Object.keys(sightseeingGroups).map(sightseeingGroupKey => {
        const sortedSightseeings = sightseeingGroups[sightseeingGroupKey];
        const sortedSightseeingsDates = groupBy(
          sortedSightseeings,
          'startDate'
        );

        const sightseeingListItems = Object.keys(sortedSightseeingsDates).map(
          sortedSightseeingsDatesKey => {
            const sortedSightseeingsDate =
              sortedSightseeingsDates[sortedSightseeingsDatesKey];

            return getListItems(
              sortedSightseeingsDate,
              sightseeingGroupKey,
              sortedSightseeingsDatesKey
            );
          }
        );

        return (
          <div key={sightseeingGroupKey}>
            <ListSubheader
              disableSticky
              id={`pax.sightseeing.sightseeingGroupKey_${sightseeingGroupKey}`}
              className={styles.subHeader}
            >
              {sightseeingGroupKey}
            </ListSubheader>
            {sightseeingListItems}
            <Divider />
          </div>
        );
      })}
    </List>
  );
};

function getListItems(
  sortedSightseeingsDate,
  sightseeingGroupKey,
  sortedSightseeingsDatesKey
) {
  const names = sortedSightseeingsDate.map((sightseeing, index) =>
    getName(sightseeing, index, sightseeingGroupKey)
  );
  const sightseeing = sortedSightseeingsDate[0];

  return (
    <ListItem key={sightseeing.paxId}>
      <ListItemText
        disableTypography
        primary={<span>{names}</span>}
        secondary={
          <div className={styles.secondaryContainer}>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <KeyValueItem
                  label={
                    <span
                      style={{
                        fontWeight: '400',
                        fontSize: '0.75rem',
                        color: 'rgba(0, 0, 0, 0.54)'
                      }}
                    >
                      From
                    </span>
                  }
                  value={
                    <span style={{ fontWeight: '400', fontSize: '0.875rem' }}>
                      {formatDate(sortedSightseeingsDatesKey)}
                    </span>
                  }
                  valueId={`pax.sightseeing.from_${sightseeing.paxId}_${sightseeingGroupKey}`}
                />
              </Grid>
            </Grid>
          </div>
        }
      />
    </ListItem>
  );
}
function getName(sightseeing, index, sightseeingGroupKey) {
  const fullName = sightseeing.fullNameWithTitle;

  return (
    <Typography
      variant="body2"
      key={`name-${sightseeing.paxId}-${index}`}
      id={`pax.sightseeing.fullName_${sightseeing.paxId}_${sightseeingGroupKey}`}
      style={{
        fontWeight: '500',
        fontSize: ' 0.875rem'
      }}
    >
      {fullName}
    </Typography>
  );
}

SightseeingList.propTypes = {
  sightseeings: PropTypes.array.isRequired
};

export default withStyles(themes)(SightseeingList);
