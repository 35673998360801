import React from 'react';
import ErrorMessage from './ErrorMessage';
import createLink from '../../common/helpers/createLink';
import { ROUTE_DEPARTURES } from '../../common/constants/routes';

const NotFound404 = () => (
  <ErrorMessage
    code="404"
    name="Not Found"
    message="The requested page could not be found"
    actions={[
      { name: 'Go to My Departures', href: createLink(ROUTE_DEPARTURES) }
    ]}
  />
);

export default NotFound404;
