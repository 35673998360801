import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from '@material-ui/core'; // Corrected import
import styles from '../../common/styles/Card.module.css';
import Typography from '@material-ui/core/Typography';
import createLink from '../../common/helpers/createLink';
import Grid from '@material-ui/core/Grid';
import {
  ROUTE_PAXUPDATE,
  ROUTE_PAX_PRE_TRIP_COVID_TEST,
  ROUTE_PAXQUESTIONNAIRE
} from '../../common/constants/routes';
import TickButton from '../components/TickButton';
import { IS_LEADER_PORTAL_COVID_SURVEY_ENABLED } from '../../common/constants/systemConfigCode';

class PaxUpdateCard extends React.Component {
  render() {
    const { pax, isLeaderPortalCovidSurveyEnabled } = this.props;

    let fullName = pax.fullNameWithTitle;

    const gridIcon = {
      xs: 4,
      sm: 4,
      md: 4,
      lg: 4
    };

    fullName = (
      <div>
        <Typography>
          <span>{pax.fullNameWithTitle}</span>
        </Typography>
        <div className={styles.paxIcons}>
          <Grid
            container
            spacing={0}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item {...gridIcon}>
              <TickButton
                tickState={pax.detailsConfirmed}
                iconType={'person'}
                displayText="Update Details"
                tooltip="Update Pax Details"
                action={createLink(ROUTE_PAXUPDATE, {
                  departureId: pax.departureId,
                  id: pax.id
                })}
                id={'btn.paxDetails_' + pax.id}
              />
            </Grid>
            {isLeaderPortalCovidSurveyEnabled && (
              <Grid item {...gridIcon}>
                <TickButton
                  tickState={pax.isCovidQuestionnaireSubmitted}
                  iconType={'description'}
                  displayText="Declaration"
                  tooltip="COVID-19 Customer Self Declaration "
                  action={createLink(ROUTE_PAXQUESTIONNAIRE, {
                    departureId: pax.departureId,
                    id: pax.id
                  })}
                  id={'btn.covid19_' + pax.id}
                />
              </Grid>
            )}

            {isLeaderPortalCovidSurveyEnabled && (
              <Grid item {...gridIcon}>
                <TickButton
                  tickState={pax.isCovidHelthScreeningSubmitted}
                  iconType={'local_hospital'}
                  displayText="Health Screening"
                  tooltip="Health Screening"
                  action={createLink(ROUTE_PAX_PRE_TRIP_COVID_TEST, {
                    departureId: pax.departureId,
                    id: pax.id
                  })}
                  id={'btn.pre_trip_covid_test_' + pax.id}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );

    return (
      <Card className={styles.card}>
        <CardHeader title={fullName} id={'departure.pax.fullName_' + pax.id} />
      </Card>
    );
  }
}

PaxUpdateCard.propTypes = {
  pax: PropTypes.object,
  isLeaderPortalCovidSurveyEnabled: PropTypes.bool
};

export default PaxUpdateCard;
