export default class TripReportResource {
  constructor(service, resourceName) {
    this.service = service;
    this.baseResourcePath = 'tripReport';
    this.resourceName = resourceName;
  }

  async getByDepartureId(id) {
    const resourcePath = `/${this.baseResourcePath}/${encodeURIComponent(
      id
    )}/${encodeURIComponent(this.resourceName)}`;

    const data = await this.service.get(resourcePath);

    return data;
  }

  async insert(departureId, data) {
    const resourcePath = `/${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/${encodeURIComponent(this.resourceName)}`;
    return this.service.post(resourcePath, data);
  }

  async delete(departureId, resouceItemId) {
    const resourcePath = `/${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/${encodeURIComponent(this.resourceName)}/${encodeURIComponent(
      resouceItemId
    )}`;
    return this.service.delete(resourcePath);
  }

  async update(departureId, data) {
    const resourcePath = `/${this.baseResourcePath}/${encodeURIComponent(
      departureId
    )}/${encodeURIComponent(this.resourceName)}/${data.id}`;
    return this.service.patch(resourcePath, data);
  }
}
