import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KeyValueItem from '../../common/components/KeyValueItem';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import createFullName from '../../common/helpers/createFullName';
import styles from './AccommodationList.module.css';
import Grid from '@material-ui/core/Grid';
import { sortBy, groupBy } from 'lodash';

const themes = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0
  }
});

const AccommodationList = ({ accommodations, classes }) => {
  // Add Booking to accommodations for grouping
  const accommodationsExtended = accommodations.map(item => {
    const bookingId = {
      bookingId: item.bookingId
    };
    return { ...item, ...bookingId };
  });

  const sorterAccomodations = sortBy(accommodationsExtended, val => {
    return `${val.hotelName.trim().toLowerCase()}`;
  });

  const accommodationGroups = groupBy(sorterAccomodations, val => {
    return `${val.hotelName.trim().toLowerCase()}`;
  });

  return (
    <List className={classes.root}>
      {Object.keys(accommodationGroups).map(accommodationGroupKey => {
        let accommodationListItems = [];
        const accommodations = sortBy(
          accommodationGroups[accommodationGroupKey],
          'checkInDate'
        );

        const checkInDates = groupBy(
          accommodations,
          'checkInDate',
          'checkOutDate',
          'bookingId',
          'roomTypeName'
        );

        Object.keys(checkInDates).map(checkInDateKey => {
          const accommsByCheckInDate = checkInDates[checkInDateKey];

          const checkOutDates = groupBy(
            accommsByCheckInDate,
            'checkOutDate',
            'bookingId',
            'roomTypeName'
          );

          Object.keys(checkOutDates).map(checkOutDateKey => {
            const accommsByCheckOutDate = checkOutDates[checkOutDateKey];

            const bookingNumbers = groupBy(
              accommsByCheckOutDate,
              'bookingId',
              'roomTypeName'
            );

            Object.keys(bookingNumbers).map(bookingNumberKey => {
              const accommsByBookingNumber = bookingNumbers[bookingNumberKey];
              const roomTypes = groupBy(accommsByBookingNumber, 'roomTypeName');

              accommodationListItems.push(
                Object.keys(roomTypes).map(roomTypeKey => {
                  const accomms = roomTypes[roomTypeKey];
                  return GetListItem(accomms, accommodationGroupKey, styles);
                })
              );
            });
          });
        });

        return (
          <div key={accommodationGroupKey}>
            <ListSubheader
              disableSticky={true}
              id={
                'pax.accommodation.accommodationGroupKey_' +
                accommodationGroupKey
              }
              className={styles.subHeader}
            >
              {accommodations[0].hotelName}
            </ListSubheader>
            {accommodationListItems}
            <Divider />
          </div>
        );
      })}
    </List>
  );
};

function GetListItem(accommodations, accommodationGroupKey, styles) {
  let names = [];
  accommodations.map(accommodation => {
    const fullName = accommodation.fullNameWithTitle;

    names.push(
      <Typography
        type="body2"
        id={
          'pax.accommodation.fullName_' +
          accommodation.paxId +
          '_' +
          accommodationGroupKey
        }
        className={styles.paxName}
        style={{ fontSize: '0.875rem', fontWeight: '500' }}
      >
        {fullName}
      </Typography>
    );
  });

  const accommodation = accommodations[0];
  const checkInDate = format(
    accommodation.checkInDate,
    process.env.REACT_APP_DATE_FORMAT
  );
  const checkOutDate = format(
    accommodation.checkOutDate,
    process.env.REACT_APP_DATE_FORMAT
  );

  return (
    <div key={`${accommodation.paxId}${accommodation.hotelName}`}>
      <ListItem>
        <ListItemText
          disableTypography={true}
          primary={
            <span>
              {names.reduce((prev, curr) => [
                <span key={'names'}>
                  {prev}
                  {curr}
                </span>
              ])}
            </span>
          }
          secondary={
            <div className={styles.secondaryContainer}>
              <Grid container spacing={0}>
                <Grid item xs={6} sm={6}>
                  <KeyValueItem
                    label={
                      <span
                        style={{
                          fontWeight: '400',
                          fontSize: '0.75rem',
                          color: 'rgba(0, 0, 0, 0.54)'
                        }}
                      >
                        Check In
                      </span>
                    }
                    value={
                      <span style={{ fontSize: '0.875rem' }}>
                        {checkInDate}
                      </span>
                    }
                    valueId={
                      'pax.accommodation.checkIn_' +
                      accommodation.paxId +
                      '_' +
                      accommodationGroupKey
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <KeyValueItem
                    label={
                      <span
                        style={{
                          fontWeight: '400',
                          fontSize: '0.75rem',
                          color: 'rgba(0, 0, 0, 0.54)'
                        }}
                      >
                        Check Out
                      </span>
                    }
                    value={
                      <span style={{ fontSize: '0.875rem' }}>
                        {checkOutDate}
                      </span>
                    }
                    valueId={
                      'pax.accommodation.checkOut_' +
                      accommodation.paxId +
                      '_' +
                      accommodationGroupKey
                    }
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <KeyValueItem
                    label={
                      <span
                        style={{
                          fontWeight: '400',
                          fontSize: '0.75rem',
                          color: 'rgba(0, 0, 0, 0.54)'
                        }}
                      >
                        Room Type
                      </span>
                    }
                    value={
                      <span style={{ fontSize: '0.875rem' }}>
                        {accommodation.roomTypeName}
                      </span>
                    }
                    valueId={
                      'pax.accommodation.roomType_' +
                      accommodation.paxId +
                      '_' +
                      accommodationGroupKey
                    }
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <KeyValueItem
                    label={
                      <span
                        style={{
                          fontWeight: '400',
                          fontSize: '0.75rem',
                          color: 'rgba(0, 0, 0, 0.54)'
                        }}
                      >
                        Booking Number
                      </span>
                    }
                    value={
                      <span style={{ fontSize: '0.875rem' }}>
                        {accommodation.bookingId}
                      </span>
                    }
                    valueId={
                      'pax.accommodation.bookingId_' +
                      accommodation.paxId +
                      '_' +
                      accommodationGroupKey
                    }
                  />
                </Grid>
              </Grid>
            </div>
          }
        />
      </ListItem>
    </div>
  );
}

AccommodationList.propTypes = {
  accommodations: PropTypes.array
};

export default withStyles(themes)(AccommodationList);
