import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './PaxUpdateCardDetails.styles';
import InputField from '../../common/forms/InputField';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardHeader } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import PaxListConfirmedByBookingContainer from '../containers/PaxListConfirmedByBookingContainer';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import required from '../../common/forms/validators/required';
import phone from '../../common/forms/validators/phone';
import parsePhone from '../../common/forms/parsers/parsePhone';
import { Field } from 'react-final-form';

class PaxUpdateCardInsuranceDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  handleOnchange(input) {
    return async pax => {
      const insurance = await LeaderPortalService.Pax.getInsurance(
        pax.id,
        pax.departureId
      );

      input.onChange(insurance);
    };
  }

  render() {
    const { paxInsurance, sectionCardStyle, bookingId, classes } = this.props;

    return (
      <div>
        <Card>
          <CardHeader
            style={sectionCardStyle.header}
            title={
              <div>
                <Typography align="center" variant="body2">
                  Travel Insurance
                </Typography>
              </div>
            }
          />
          <CardContent>
            <Field name="insurance">
              {({ input }) => (
                <PaxListConfirmedByBookingContainer
                  bookingId={bookingId}
                  paxId={paxInsurance.paxId}
                  onChange={this.handleOnchange(input)}
                />
              )}
            </Field>
            <div>
              <InputField
                name="insurance.companyName"
                placeholder="Travel Insurance Company"
                validate={required}
                className={classes.wideField}
                maxLength={100}
                disabled={Object.keys(paxInsurance).length == 0}
              />
            </div>
            <div>
              <InputField
                name="insurance.policyNumber"
                placeholder="Policy Number"
                validate={required}
                className={classes.wideField}
                maxLength={50}
                disabled={Object.keys(paxInsurance).length == 0}
              />
            </div>
            <div>
              <InputField
                name="insurance.companyPhoneNumber"
                placeholder="24hr Emergency Contact"
                validate={(value, allValues, meta) =>
                  required(value) || (!meta.pristine && phone(value))
                }
                parse={parsePhone}
                className={classes.wideField}
                helperText="+61370102496"
                maxLength={20}
                type="tel"
                disabled={Object.keys(paxInsurance).length == 0}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}
PaxUpdateCardInsuranceDetails.propTypes = {
  paxInsurance: PropTypes.object
};

export default withStyles(styles)(PaxUpdateCardInsuranceDetails);
