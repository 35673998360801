import * as actionTypes from '../actionTypes/crewRoles';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const tripReportCrewRolesGetRequest = () => ({
  type: actionTypes.CREW_ROLES_GET_REQUEST
});

export const tripReportCrewGetSuccess = crewRoles => ({
  type: actionTypes.CREW_ROLES_GET_SUCCESS,
  payload: {
    crewRoles
  }
});

export const getCrewRoles = () => async dispatch => {
  try {
    dispatch(tripReportCrewRolesGetRequest());

    const crewRoles = await LeaderPortalService.TripReport.CrewRoles.get();

    dispatch(tripReportCrewGetSuccess(crewRoles));
  } catch (e) {
    console.log(e);
  }
};
