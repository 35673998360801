import { red } from '@material-ui/core/colors';

export default {
  Paper: {
    margin: '5px',
    overflow: 'auto',
    position: 'sticky',
    padding: '20px',
    top: 0,
    zIndex: 0
  },
  Typography: {
    position: 'sticky',
    fontSize: '14px'
  },
  steperContainer: {
    padding: '1px 10px',
    margin: '5px'
  },
  buttonDefaultTouchDevice: {
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px rgba(0, 0, 0, 0.4) solid',
    marginRight: '5px'
  },
  buttonDefault: {
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px rgba(0, 0, 0, 0.4) solid',
    marginRight: '5px',
    '&:hover': {
      background: '#1666B0',
      color: '#fff',
      border: '0px',
      border: '1px #1666B0 solid'
    }
  },
  buttonSelected: {
    background: '#1666B0',
    color: '#fff',
    marginRight: '5px',
    '&:hover': {
      background: '#0a5091',
      border: '1px #1666B0 solid'
    }
  },
  exitButton: {
    'text-align': 'center',
    margin: '2px 0 0 0'
  },
  OuterPaper: {
    padding: '0 0 20px 0',
    top: 0,
    zIndex: 0
  },
  BottomButtons: {
    position: 'fixed',
    width: '100%',
    bottom: '0'
  },
  MainContainer: {
    position: 'relative'
  },
  PageContainer: {
    position: 'relative',
    minHeight: 'calc((100vh - 64px))',
    padding: '0',
    top: 0,
    zIndex: 0,
    width: '100%'
  },
  buttonContainer: {
    position: 'fixed',
    width: '100%',
    bottom: '0',
    padding: '0px'
  },
  ContentContainer: {
    position: 'relative',
    minHeight: 'calc((70vh - 100px))',
    overflow: 'auto'
  },
  QuestionContainer: {
    position: 'relative',
    padding: '20px 20px 0 20px',
    minHeight: 'calc((75vh - 40px))',
    maxHeight: 'calc((75vh - 40px))',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
  },
  QuestionButtonContainer: {
    position: 'absolute',
    bottom: '65px',
    right: '13px',
    margin: '5px'
  },
  QuestionRadioButtonContainer: {
    position: 'relative',
    top: '-10px',
    left: '20px',
    maxWidth: 'calc((100vw - 80px))'
  },
  QuestionCheckBoxContainer: {
    position: 'absolute',
    bottom: '65px'
  },
  QuestionTextContainer: {
    top: '0',
    margin: '5px',
    zIndex: '0',
    overflow: 'auto',
    position: 'sticky',
    maxHeight: 'calc((60vh - 40px))'
  },
  AcceptCondition: {
    position: 'absolute',
    bottom: '0'
  },
  FirtPageContainer: {
    position: 'relative',
    padding: '20px 20px 0 20px',
    minHeight: 'calc((70vh - 40px))',
    maxHeight: 'calc((70vh - 40px))'
  },
  RadioButtonLabel: {
    padding: '8px 0px'
  },
  FixedWidth: {
    width: '88px',
    display: 'box'
  },
  SpecialMessage: {
    color: '#dc3814',
    whiteSpace: 'pre-line',
    fontWeight: 'bold'
  },
  PaxName: {
    borderBottom: '1px solid #ccc',
    marginBottom: '10px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  }
};
