export default {
  addButtonContainer: {
    margin: '5px 5px',
    position: 'sticky',
    top: '52px',
    zIndex: 1
  },
  wideButton: {
    width: '100%'
  }
};
