import animationStyles from '../../common/styles/Animations.styles';

export default {
  container: {
    'flex-grow': 1,
    margin: '5px 0px 5px 0px',
    display: 'inline-flex',
    'flex-direction': 'column',
    position: 'relative',
    padding: 0,
    border: 0,
    width: '100%'
  },

  selectRenderValue: {
    overflow: 'hidden',
    'white-space': 'nowrap',
    'text-overflow': 'ellipsis'
  },

  selectHiddenElement: {
    visibility: 'hidden'
  },

  selectHelperText: {
    fontSize: 12,
    'margin-top': '8px'
  },

  SingleSelectMenuItem: {
    'font-weight': 400
  },

  SingleSelectMenuItemSelected: {
    'font-weight': 500
  },

  multiSelectMenu: {
    width: '85%'
  },

  multiSelectList: {
    position: 'relative',
    height: 'fit-content',
    top: '37% !important',
    maxHeight: 200,
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media (max-width: 700px)': {
      position: 'fixed',
      left: '8% !important',
      width: '85%',
      top: '37% !important'
    },
    '& ul li': {
      padding: '0px'
    },
    '& ul li span': {
      width: '30px'
    },
    '& ul li div': {
      padding: '0px'
    },
    '& ul li div h3': {
      overflow: 'hidden',
      'white-space': 'nowrap',
      'text-overflow': 'ellipsis'
    }
  },

  selectAllText: {
    '& > *': {
      fontWeight: 500
    }
  },

  ...animationStyles
};
