import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Field } from 'react-final-form';
import ErrorIcon from '../../common/components/ErrorIcon';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import ReactSelect, { components as rscomponents } from 'react-select';
import styles from './SingleSelect.styles';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const inputClasses = classnames({
    [props.selectProps.classes.input]: true,
    [props.selectProps.classes.progressiveLoading]:
      props.selectProps.textFieldProps.disabled
  });

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: inputClasses,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const DropdownIndicator = props => {
  return (
    rscomponents.DropdownIndicator && (
      <rscomponents.DropdownIndicator
        {...props}
        className={props.selectProps.classes.dropdownIndicator}
      >
        <SvgIcon>
          <path d="M7 10l5 5 5-5z" />
        </SvgIcon>
      </rscomponents.DropdownIndicator>
    )
  );
};

const components = {
  Control,
  Menu,
  DropdownIndicator,
  IndicatorSeparator: null,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer
};

class IntegrationReactSelect extends React.Component {
  render() {
    const {
      name,
      classes,
      theme,
      options,
      label,
      searchable,
      disabled,
      input,
      helperText,
      meta
    } = this.props;

    const error = meta.touched && meta.error;
    const hasError = error && meta.error !== undefined;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      }),
      dropdownIndicator: base => {
        return {
          ...base,
          color: theme.palette.text.secondary
        };
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = 'rgba(0, 0, 0, 0.14)';
        const selectedColor = 'rgba(0, 0, 0, 0.08)';
        return {
          ...styles,
          color: '#000',
          backgroundColor: isFocused
            ? color
            : isSelected
            ? selectedColor
            : 'default',
          fontWeight: isSelected ? 'bold' : ''
        };
      }
    };

    return (
      <div className={classes.root}>
        <div className={classes.selectContainer}>
          <ReactSelect
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label: label,
              name: input.name,
              InputLabelProps: {
                shrink: true
              },
              helperText:
                meta.touched && meta.error
                  ? [<ErrorIcon />, meta.error]
                  : helperText,
              error: hasError,
              disabled: disabled
            }}
            maxMenuHeight={200}
            options={options}
            components={components}
            value={
              !disabled && options.filter(({ value }) => value === input.value)
            }
            onChange={option => input.onChange(option.value)}
            onBlur={event => input.onBlur(event)}
            onFocus={event => input.onFocus(event)}
            isSearchable={searchable}
            placeholder=""
          />
        </div>
      </div>
    );
  }
}

class SingleSelect extends React.Component {
  render() {
    const {
      name,
      label,
      helperText,
      options,
      required,
      validate,
      disabled,
      searchable = true
    } = this.props;

    return (
      <Field
        component={IntegrationReactSelect}
        type="input"
        name={name}
        validate={validate}
        label={label}
        helperText={helperText}
        options={options}
        clearable={true}
        simpleValue={true}
        disabled={disabled}
        searchable={searchable}
        {...this.props}
      />
    );
  }
}

SingleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(SingleSelect);
