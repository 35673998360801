const transformList = data => {
  const responsibleTravelCategories = data.categories;

  return responsibleTravelCategories.map(category => transform(category));
};

const transform = data => {
  const category = data;

  return category;
};

export default class ResponsibleTravelCategories {
  constructor(service) {
    this.service = service;
  }

  async get() {
    const resourcePath = `/responsibleTravelCategories`;

    const data = await this.service.get(resourcePath);

    return transformList(data);
  }
}
