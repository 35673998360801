import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation, useParams } from 'react-router-dom';

ReactGA.initialize('UA-105657695-2');

const Analytics = ({ userId, children }) => {
  const location = useLocation();
  const params = useParams();

  const trackPage = (page, departureId) => {
    ReactGA.set({ dimension1: departureId });
    ReactGA.set({ dimension2: userId });
    ReactGA.pageview(page);
  };

  useEffect(() => {
    const page = location.pathname;
    const departureId = params.id;
    trackPage(page, departureId);
  }, [location, params.id, userId]);

  return children;
};

export default Analytics;
