export default {
  bar: {
    backgroundColor: '#ffbd40 !important',
    height: '3px'
  },
  container: {
    position: 'fixed',
    top: 0,
    zIndex: 1400,
    width: '100%'
  }
};
