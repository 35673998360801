export default class Countries {
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/countries';
  }

  get() {
    const resourcePath = `${this.baseResourcePath}`;

    return this.service.get(resourcePath);
  }
}
