import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import SingleSelect from '../../common/forms/SingleSelect';
import { withStyles } from '@material-ui/core/styles';
import themes from './TripReportField.styles';
import required from '../../common/forms/validators/required';

class TripReportCrewFields extends React.Component {
  render() {
    const { classes, crewRoles, submitting } = this.props;

    const roles = crewRoles
      ? crewRoles
          .map(role => ({
            value: role.role,
            label: role.displayText
          }))
          .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
      : [];

    return (
      <Fragment>
        <div>
          <InputField
            name="name"
            placeholder="Name"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <SingleSelect
            name="role"
            label="Role"
            validate={required}
            searchable={false}
            options={roles}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>

        <div>
          <InputField
            name="contactNumber"
            placeholder="Contact Number"
            maxLength={20}
            className={classes.wideField}
            type="tel"
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Comments"
            multiline={true}
            rows={6}
            rowsMax={6}
            maxLength={1000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

export default withStyles(themes)(TripReportCrewFields);
