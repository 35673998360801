import * as actionTypes from '../actionTypes/globalMessages';

export const addMessage = (content, type, redirect, closable) => ({
  type: actionTypes.GLOBAL_MESSAGES_ADD_MESSAGE,
  payload: {
    content,
    type,
    redirect,
    closable
  }
});

export const removeMessage = () => ({
  type: actionTypes.GLOBAL_MESSAGES_REMOVE_MESSAGE
});

export const messageTypes = {
  success: 'success',
  info: 'info',
  error: 'error',
  offline: 'offline'
};
