import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import { withStyles } from '@material-ui/core/styles';
import themes from './TripReportField.styles';
import required from '../../common/forms/validators/required';

class TripReportCommentFields extends React.Component {
  render() {
    const { classes, submitting } = this.props;

    return (
      <Fragment>
        <div>
          <InputField
            name="location"
            placeholder="Location"
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Comments"
            validate={required}
            multiline={true}
            rows={6}
            rowsMax={6}
            maxLength={2000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

export default withStyles(themes)(TripReportCommentFields);
