import React from 'react';
// import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

class CovidQuestionnaireInstruction extends React.Component {
  render() {
    const { open, onClose, instructionContent, instructionTitle } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown={false}
      >
        <DialogTitle>{instructionTitle}</DialogTitle>
        <DialogContent>{instructionContent}</DialogContent>
        <DialogActions>
          <Button raised onClick={onClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CovidQuestionnaireInstruction.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  instructionTitle: PropTypes.string,
  instructionContent: PropTypes.node.isRequired
};

export default CovidQuestionnaireInstruction;
