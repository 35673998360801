import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaxTripNoteAction from '../actions/PaxTripNoteAction';
import TripReportSection from '../components/TripReportSection';
import TripReportPaxTripNoteFields from '../components/TripReportPaxTripNoteFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import '../../common/helpers/stringExtensions';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportPaxTripNoteContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subTitleText:
        'Include any changes, additions or optional activity price amendments that need to be made.'
    };
  }

  renderSectionFields = submitting => (
    <TripReportPaxTripNoteFields submitting={submitting} />
  );

  renderSectionCard = paxTripNote => {
    return (
      <TripReportSectionCard
        sectionName="pax trip notes"
        itemName="pax trip notes"
        title={paxTripNote.changes}
        subTitle={this.state.subTitleText}
        cardContent={() => {
          return null;
        }}
        item={paxTripNote}
        itemId={paxTripNote.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updatePaxTripNote}
        delete={() =>
          this.props.actions.deletePaxTripNote(
            this.props.departure.id,
            paxTripNote.id
          )
        }
        deleteMessage={paxTripNote =>
          `Are you sure you want to delete the pax trip note "${paxTripNote.changes.trunc(
            60
          )}"?`
        }
      />
    );
  };

  render() {
    const { departure, paxTripNoteList } = this.props;

    return (
      departure &&
      paxTripNoteList && (
        <TripReportSection
          subTitle={this.state.subTitleText}
          sectionName="pax trip notes"
          itemName="pax trip notes"
          noItemsMessage="There are no pax trip notes added yet"
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={paxTripNoteList}
          insert={this.props.actions.insertPaxTripNote}
        />
      )
    );
  }
}

TripReportPaxTripNoteContainer.propTypes = {
  departure: PropTypes.object,
  paxTripNoteList: PropTypes.array,
  actions: PropTypes.shape({
    insertPaxTripNote: PropTypes.func,
    deletePaxTripNote: PropTypes.func,
    updatePaxTripNote: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {};
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      insertPaxTripNote: values => async dispatch => {
        await new PaxTripNoteAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deletePaxTripNote: (departureId, paxTripNoteId) => async dispatch => {
        await new PaxTripNoteAction().delete(
          departureId,
          paxTripNoteId
        )(dispatch);
      },
      updatePaxTripNote: values => async dispatch => {
        await new PaxTripNoteAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportPaxTripNoteContainer)
);
