// Manually extending prototype to get instanceof working for Errors
function NetworkError(message, ...params) {
  this.name = 'Network Error';
  this.message = message;

  if (this.message === undefined) {
    this.message = 'Unable to connect to server';
  }

  this.stack = new Error().stack;
}

NetworkError.prototype = Object.create(Error.prototype);
NetworkError.prototype.constructor = NetworkError;

NetworkError.prototype.toString = function() {
  return this.message;
};

export default NetworkError;
