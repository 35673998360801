import * as actionTypes from '../actionTypes/departures';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import selectors from '../selectors';

export const departuresGetRequest = () => ({
  type: actionTypes.DEPARTURES_GET_REQUEST,
  payload: {}
});

export const departuresGetSuccess = departures => ({
  type: actionTypes.DEPARTURES_GET_SUCCESS,
  payload: {
    departures
  }
});

export const departureGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departureGetSuccess = departure => ({
  type: actionTypes.DEPARTURE_GET_SUCCESS,
  payload: {
    departure
  }
});

export const departureClear = () => ({
  type: actionTypes.DEPARTURE_CLEAR
});

function shouldFetch(state, id) {
  const selectedDeparture = selectors.selectedDeparture.getDeparture(state);

  return !selectedDeparture || selectedDeparture.id.toString() !== id;
}

export const getDeparture = id => async (dispatch, getState) => {
  if (!shouldFetch(getState(), id)) {
    return null;
  }

  dispatch(departureClear());
  dispatch(departureGetRequest(id));

  const data = await LeaderPortalService.Departures.get(id);

  dispatch(departureGetSuccess(data.body));
};

export const getDeparturesForLeader = () => async dispatch => {
  dispatch(departuresGetRequest());
  const data = await LeaderPortalService.Departures.getByLeaderId();

  dispatch(departuresGetSuccess(data.body));
};
