import * as departures from './departures';
import * as pax from './pax';
import * as selectedDeparture from './selectedDeparture';
import * as selectedDepartureTransfers from './selectedDepartureTransfers';
import * as selectedDepartureAccommodations from './selectedDepartureAccommodations';
import * as selectedDepartureSightseeings from './selectedDepartureSightseeings';
import * as selectedDeparturePaxChanges from './selectedDeparturePaxChanges';
import * as selectedPax from './selectedPax';
import * as countries from './countries';
import * as leaderActions from './leaderActions';
import * as covidQuestionnaire from './covidQuestionnaire';
import * as covidQuestionnaireResponses from './covidQuestionnaireResponses';

export default {
  departures,
  pax,
  selectedDeparture,
  selectedDepartureTransfers,
  selectedDepartureAccommodations,
  selectedDepartureSightseeings,
  selectedDeparturePaxChanges,
  selectedPax,
  countries,
  leaderActions,
  covidQuestionnaire,
  covidQuestionnaireResponses
};
