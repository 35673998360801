export const CREW_GET_REQUEST = 'tripReport/CREW_GET_REQUEST';
export const CREW_GET_FAILURE = 'tripReport/CREW_GET_FAILURE';
export const CREW_GET_SUCCESS = 'tripReport/CREW_GET_SUCCESS';
export const CREW_CLEAR = 'tripReport/CREW_GET_CLEAR';

export const CREW_INSERT_REQUEST = 'tripReport/CREW_INSERT_REQUEST';
export const CREW_INSERT_SUCCESS = 'tripReport/CREW_INSERT_SUCCESS';

export const CREW_DELETE_REQUEST = 'tripReport/CREW_DELETE_REQUEST';
export const CREW_DELETE_SUCCESS = 'tripReport/CREW_DELETE_SUCCESS';

export const CREW_UPDATE_REQUEST = 'tripReport/CREW_UPDATE_REQUEST';
export const CREW_UPDATE_SUCCESS = 'tripReport/CREW_UPDATE_SUCCESS';
