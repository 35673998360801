export const GET_REQUEST = 'tripReport/RESPONSIBLE_TRAVEL_GET_REQUEST';
export const GET_FAILURE = 'tripReport/RESPONSIBLE_TRAVEL_GET_FAILURE';
export const GET_SUCCESS = 'tripReport/RESPONSIBLE_TRAVEL_GET_SUCCESS';
export const CLEAR = 'tripReport/RESPONSIBLE_TRAVEL_GET_CLEAR';

export const INSERT_REQUEST = 'tripReport/RESPONSIBLE_TRAVEL_INSERT_REQUEST';
export const INSERT_SUCCESS = 'tripReport/RESPONSIBLE_TRAVEL_INSERT_SUCCESS';

export const DELETE_REQUEST = 'tripReport/RESPONSIBLE_TRAVEL_DELETE_REQUEST';
export const DELETE_SUCCESS = 'tripReport/RESPONSIBLE_TRAVEL_DELETE_SUCCESS';

export const UPDATE_REQUEST = 'tripReport/RESPONSIBLE_TRAVEL_UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'tripReport/RESPONSIBLE_TRAVEL_UPDATE_SUCCESS';
