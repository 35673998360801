import * as actionTypes from '../actionTypes/accommodations';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const departureAccommodationsGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_ACCOMMODATIONS_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departureAccommodationsGetSuccess = accommodations => ({
  type: actionTypes.DEPARTURE_ACCOMMODATIONS_GET_SUCCESS,
  payload: {
    accommodations
  }
});

export const getDepartureAccommodations = id => async dispatch => {
  try {
    dispatch(departureAccommodationsGetRequest(id));

    const accommodations = await LeaderPortalService.Accommodations.getByDepartureId(
      id
    );

    dispatch(departureAccommodationsGetSuccess(accommodations));
  } catch (e) {
    console.log(e);
  }
};
