import * as actionTypes from '../actionTypes/userAccess';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const userAccessGetRequest = () => ({
  type: actionTypes.USER_ACCESS_GET_REQUEST,
  meta: {
    disableLoadingBar: true
  }
});

export const userAccessGetSuccess = userAccess => ({
  type: actionTypes.USER_ACCESS_GET_SUCCESS,
  payload: {
    userAccess
  }
});

export const getUserAccess = () => async dispatch => {
  const data = await LeaderPortalService.UserAccess.get();
  dispatch(userAccessGetSuccess(data));
};
