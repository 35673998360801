import * as actionTypes from '../actionTypes/meal';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import TripReportSectionBaseAction from './TripReportSectionBaseAction';

export default class MealAction extends TripReportSectionBaseAction {
  constructor() {
    super(
      LeaderPortalService.TripReport.Meal,
      actionTypes,
      'Meals/food hygiene'
    );
  }
}
