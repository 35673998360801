import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MedicationAction from '../actions/MedicationAction';
import departureActions from '../../departures/actions';
import departureSelectors from '../../departures/selectors';
import TripReportSection from '../components/TripReportSection';
import TripReportMedicationFields from '../components/TripReportMedicationFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import '../../common/helpers/stringExtensions';
import { format } from 'date-fns';
import isISODate from '../../common/helpers/isISODate';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportMedicationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subTitleText: 'Include any medication you administered to passengers.'
    };
  }

  renderSubHeader = medication => {
    return (
      medication.location && (
        <div style={styles.trimText}>
          <span id={'medication.header.location_' + medication.id}>
            {medication.location}
          </span>
        </div>
      )
    );
  };

  renderContent = medication => {
    const pax =
      medication.passenger &&
      this.props.pax.find(p => medication.passenger.paxId === p.id);

    const administeredDateFormatted = isISODate(medication.administeredDate)
      ? format(medication.administeredDate, process.env.REACT_APP_DATE_FORMAT)
      : medication.administeredDate;

    return (
      pax &&
      medication.passenger && (
        <span id={'medication.header.paxName_' + medication.id}>
          {pax.fullName}
          {' • '} {administeredDateFormatted}
        </span>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportMedicationFields pax={this.props.pax} submitting={submitting} />
  );

  renderSectionCard = medication => {
    return (
      <TripReportSectionCard
        sectionName="medication"
        itemName="medication"
        title={medication.medicationAdministered}
        subTitle={this.state.subTitleText}
        subHeader={this.renderSubHeader}
        cardContent={this.renderContent}
        item={medication}
        itemId={medication.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateMedication}
        delete={() =>
          this.props.actions.deleteMedication(
            this.props.departure.id,
            medication.id
          )
        }
        deleteMessage={medication =>
          `Are you sure you want to delete the medication entry "${medication.medicationAdministered.trunc(
            60
          )}"?`
        }
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { departure, medicationList } = this.props;

    return (
      departure &&
      medicationList && (
        <TripReportSection
          subTitle={this.state.subTitleText}
          sectionName="Medication"
          itemName="medication administered to pax"
          noItemsMessage="There are no medication issues added yet"
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={medicationList}
          insert={this.props.actions.insertMedication}
        />
      )
    );
  }
}

TripReportMedicationContainer.propTypes = {
  departure: PropTypes.object,
  medicationList: PropTypes.array,
  actions: PropTypes.shape({
    insertMedication: PropTypes.func,
    deleteMedication: PropTypes.func,
    updateMedication: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  let paxList = departureSelectors.pax.getPax(state);

  return {
    pax: paxList != null ? paxList.pax : []
  };
};

const updatePassengers = values => {
  if (values.pax == undefined) {
    values['passenger'] = undefined;
  }

  if (typeof values.pax !== 'number') {
    return;
  }

  values['passenger'] = { paxId: values.pax };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      getPax: departureActions.selectedDeparture.getSelectedDeparture,
      insertMedication: values => async dispatch => {
        updatePassengers(values);
        await new MedicationAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteMedication: (departureId, medicationId) => async dispatch => {
        await new MedicationAction().delete(
          departureId,
          medicationId
        )(dispatch);
      },
      updateMedication: values => async dispatch => {
        updatePassengers(values);
        await new MedicationAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportMedicationContainer)
);
