import * as React from 'react';
import InputField from '../../common/forms/InputField';
import FullName, {
  FullNameMembers,
  FullNameField
} from '../../common/forms/FullName';
import styles from './PaxUpdateCardDetails.styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardHeader } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import required from '../../common/forms/validators/required';
import phone from '../../common/forms/validators/phone';
import parsePhone from '../../common/forms/parsers/parsePhone';
import PaxListConfirmedByBookingContainer from '../containers/PaxListConfirmedByBookingContainer';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

class PaxUpdateCardEmergencyContactDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  handleOnchange(input) {
    return async pax => {
      const emergencyContact = await LeaderPortalService.Pax.getEmergencyContact(
        pax.id,
        pax.departureId
      );

      input.onChange(emergencyContact);
    };
  }

  render() {
    const {
      emergencyContact,
      sectionCardStyle,
      bookingId,
      classes
    } = this.props;
    const firstName = new FullNameField(
      'emergencyContact.firstName',
      'First Name',
      undefined,
      classes.wideField,
      required,
      100
    );
    const surname = new FullNameField(
      'emergencyContact.surname',
      'Surname',
      undefined,
      classes.wideField,
      required,
      100
    );
    const fullNameMembers = new FullNameMembers(firstName, surname);

    return (
      <div>
        <Card>
          <CardHeader
            style={sectionCardStyle.header}
            title={
              <div>
                <Typography align="center" variant="body2">
                  Emergency Contact
                </Typography>
              </div>
            }
          />
          <CardContent>
            <Field name="emergencyContact">
              {({ input }) => (
                <PaxListConfirmedByBookingContainer
                  bookingId={bookingId}
                  paxId={emergencyContact.paxId}
                  onChange={this.handleOnchange(input)}
                />
              )}
            </Field>
            <div>
              <FullName
                members={fullNameMembers}
                // disabled={Object.keys(emergencyContact).length == 0}
              />
            </div>
            <div>
              <InputField
                name="emergencyContact.relationship"
                placeholder="Relationship"
                validate={required}
                className={classes.wideField}
                maxLength={50}
                // disabled={Object.keys(emergencyContact).length == 0}
              />
            </div>
            <div>
              <InputField
                name="emergencyContact.primaryPhoneNumber"
                placeholder="Primary Contact Number"
                validate={(value, allValues, meta) =>
                  required(value) || (!meta.pristine && phone(value))
                }
                parse={parsePhone}
                maxLength={20}
                className={classes.wideField}
                helperText="+61370102496"
                type="tel"
                // disabled={Object.keys(emergencyContact).length == 0}
              />
            </div>
            <div>
              <InputField
                name="emergencyContact.secondaryPhoneNumber"
                placeholder="Secondary Contact Number"
                validate={(value, allValues, meta) =>
                  !meta.pristine && phone(value)
                }
                parse={parsePhone}
                maxLength={20}
                className={classes.wideField}
                helperText="+61370102496"
                type="tel"
                // disabled={Object.keys(emergencyContact).length == 0}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

PaxUpdateCardEmergencyContactDetails.propTypes = {
  emergencyContact: PropTypes.object
};

export default withStyles(styles)(PaxUpdateCardEmergencyContactDetails);
