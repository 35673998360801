import * as actionTypes from '../actionTypes/overallTripRating';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.GET_SUCCESS:
      return action.payload.overallTripRating;
    case actionTypes.UPDATE_SUCCESS:
      return (state = action.payload.overallTripRating);
    case actionTypes.CLEAR:
      return -1;
    default:
      return state;
  }
};
