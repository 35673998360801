import { runWithAdal, AuthenticationContext } from 'react-adal';

const DO_NOT_LOGIN = false;

export const adalConfig = {
  tenant: process.env.REACT_APP_LEADER_PORTAL_API_TENANT_URI,
  clientId: process.env.REACT_APP_LEADER_PORTAL_API_AAD_GUID,
  redirectUri: process.env.REACT_APP_LEADER_PORTAL_API_REDIRECT_URI,
  prompt: 'login',
  cacheLocation: 'sessionStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const runWithReactAdal = app =>
  runWithAdal(
    authContext,
    () => {
      app();
    },
    DO_NOT_LOGIN
  );
