import React from 'react';
import PropTypes from 'prop-types';
import { ROUTE_PAXUPDATE } from '../../common/constants/routes';
import PaxUpdateCard from './PaxUpdateCard';
import LinkWrapper from '../../common/components/LinkWrapper';
import AvatarLogo from '../../common/components/AvatarLogo';
import { Card, CardHeader } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import { sortBy } from 'lodash';
import GlobalMessageCard from '../../common/components/GlobalMessageCard';

const PaxUpdateList = ({
  pax,
  departure,
  isLeaderPortalCovidSurveyEnabled
}) => {
  // Sorting
  let paxList = !pax
    ? []
    : sortBy(pax, val => {
        return (
          (val.firstName ? `${val.firstName.trim().toLowerCase()}` : '') ||
          (val.surname ? `${val.surname.trim().toLowerCase()}` : '')
        );
      });

  return (
    <div>
      <Card>
        <CardHeader
          avatar={
            <AvatarLogo
              brand={departure.brand}
              imgId={'img.header_' + departure.departureCode}
            />
          }
          title={departure.departureCode}
          subheader={departure.productName}
          id={'departure.header_' + departure.departureCode}
        />
      </Card>
      {paxList.length ? (
        paxList.map((pax, i) => (
          <PaxUpdateCard
            pax={pax}
            isLeaderPortalCovidSurveyEnabled={isLeaderPortalCovidSurveyEnabled}
          />
        ))
      ) : (
        <GlobalMessageCard message="No pax on departure" />
      )}
    </div>
  );
};

PaxUpdateList.propTypes = {
  departure: PropTypes.object,
  pax: PropTypes.array,
  covidSurveyLeaderPortalToggle: PropTypes.bool
};

export default PaxUpdateList;
