import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './CovidQuestionnaire.styles';
import { covidQuestionRepresentation } from '../../common/constants/covidQuestionRepresentation';
import CovidQuestionRepresentationRadioButton from './CovidQuestionRepresentationRadioButton';
import CovidQuestionRepresentationButton from './CovidQuestionRepresentationButton';
import CovidQuestionRepresentationCheckBox from './CovidQuestionRepresentationCheckBox';

class CovidQuestionAnswers extends React.Component {
  render(props) {
    const {
      covidQuestion,
      covidQuestionResponse,
      classes,
      handleAnswerClick,
      answer,
      age
    } = this.props;
    switch (covidQuestion.covidQuestionRepresentationId) {
      case covidQuestionRepresentation.RADIO_BUTTON: {
        return (
          <CovidQuestionRepresentationRadioButton
            covidQuestionnaireQuestionId={
              covidQuestion.covidQuestionnaireQuestionId
            }
            covidQuestionAnswerTexts={covidQuestion.covidQuestionAnswerTexts}
            covidQuestionResponse={covidQuestionResponse}
            handleAnswerClick={handleAnswerClick}
            answer={answer}
            age={age}
          />
        );
      }
      case covidQuestionRepresentation.CHECKBOX: {
        return (
          <CovidQuestionRepresentationCheckBox
            covidQuestionnaireQuestionId={
              covidQuestion.covidQuestionnaireQuestionId
            }
            covidQuestionAnswerTexts={covidQuestion.covidQuestionAnswerTexts}
            covidQuestionnaireQuestion={covidQuestion}
            covidQuestionResponse={covidQuestionResponse}
            handleAnswerClick={handleAnswerClick}
            answer={answer}
          />
        );
      }
      default: {
        return (
          <CovidQuestionRepresentationButton
            covidQuestionnaireQuestionId={
              covidQuestion.covidQuestionnaireQuestionId
            }
            covidQuestionAnswerTexts={covidQuestion.covidQuestionAnswerTexts}
            covidQuestionResponse={covidQuestionResponse}
            handleAnswerClick={handleAnswerClick}
            answer={answer}
          />
        );
      }
    }
  }
}

export default withStyles(styles)(CovidQuestionAnswers);
