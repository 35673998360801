import * as actionTypes from '../actionTypes/pax';

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PAX_EMERGENCYCONTACT_GET_SUCCESS:
      return action.payload.paxEmergencyContact;
    case actionTypes.PAX_EMERGENCYCONTACT_CLEAR:
      return {};
    default:
      return state;
  }
};
