export default {
  icon: {
    'vertical-align': 'bottom',
    'margin-right': '2px'
  },
  smallIcon: {
    extend: 'icon',
    fontSize: '15px'
  },
  normalIcon: {
    extend: 'icon'
  }
};
