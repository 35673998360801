import { addMessage, messageTypes } from '../../common/actions/globalMessages';

export default class TripReportSectionBaseAction {
  constructor(service, actionTypes, sectionLabel) {
    this.service = service;
    this.actionTypes = actionTypes;
    this.sectionLabel = sectionLabel;
  }

  tripReportSectionGetRequest = departureId => ({
    type: this.actionTypes.GET_REQUEST,
    payload: {
      departureId
    }
  });

  tripReportSectionGetSuccess = recordList => ({
    type: this.actionTypes.GET_SUCCESS,
    payload: {
      recordList
    }
  });

  tripReportSectionInsertRequest = (departureId, record) => ({
    type: this.actionTypes.INSERT_REQUEST,
    payload: {
      departureId,
      record
    }
  });

  tripReportSectionUpdateRequest = (departureId, recordId) => ({
    type: this.actionTypes.UPDATE_REQUEST,
    payload: {
      departureId,
      recordId
    }
  });

  tripReportSectionDeleteRequest = (departureId, recordId) => ({
    type: this.actionTypes.DELETE_REQUEST,
    payload: {
      departureId,
      recordId
    }
  });

  tripReportSectionInsertSuccess = record => ({
    type: this.actionTypes.INSERT_SUCCESS,
    payload: {
      record
    }
  });

  tripReportSectionDeleteSuccess = recordId => ({
    type: this.actionTypes.DELETE_SUCCESS,
    payload: {
      recordId
    }
  });

  tripReportSectionUpdateSuccess = record => ({
    type: this.actionTypes.UPDATE_SUCCESS,
    payload: {
      record
    }
  });

  getList = id => async dispatch => {
    dispatch(this.tripReportSectionGetRequest(id));

    const record = await this.service.getByDepartureId(id);

    dispatch(this.tripReportSectionGetSuccess(record));
  };

  insert = (departureId, record) => async dispatch => {
    dispatch(this.tripReportSectionInsertRequest(departureId, record));

    const data = await this.service.insert(departureId, record);

    dispatch(
      addMessage(
        `${this.sectionLabel} added to the Trip Report`,
        messageTypes.success
      )
    );

    dispatch(this.tripReportSectionInsertSuccess(data.body));
  };

  delete = (departureId, recordId) => async dispatch => {
    dispatch(this.tripReportSectionDeleteRequest(departureId, recordId));

    await this.service.delete(departureId, recordId);

    dispatch(
      addMessage(
        `${this.sectionLabel} removed from the Trip Report`,
        messageTypes.success
      )
    );

    dispatch(this.tripReportSectionDeleteSuccess(recordId));
  };

  update = (departureId, record) => async dispatch => {
    dispatch(this.tripReportSectionUpdateRequest(departureId, record));

    await this.service.update(departureId, record);

    dispatch(
      addMessage(
        `${this.sectionLabel} updated in the Trip Report`,
        messageTypes.success
      )
    );

    dispatch(this.tripReportSectionUpdateSuccess(record));
  };
}
