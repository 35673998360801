import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import themes from './TripReportField.styles';
import CurrencyCombo from '../../common/forms/CurrencyCombo';
import required from '../../common/forms/validators/required';

class TripReportCostChangeFields extends React.Component {
  getOptions = currency => {
    return currency
      .map(c => ({
        label: c.currencyCode,
        value: c.currencyCode
      }))
      .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
  };

  render() {
    const { classes, currencies, submitting } = this.props;

    return (
      <Fragment>
        <div>
          <InputField
            name="location"
            placeholder="Location"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="serviceName"
            placeholder="Service"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <CurrencyCombo
            label="Budgeted Cost"
            currencyName="budgetedCurrency"
            currencyRequired={true}
            currencySearchable={true}
            currencyOptions={this.getOptions(currencies)}
            currencyDisabled={submitting}
            costName="budgetedCost"
            costDisabled={submitting}
          />
        </div>
        <div>
          <CurrencyCombo
            label="New Cost"
            currencyName="newCurrency"
            currencyRequired={true}
            currencySearchable={true}
            currencyOptions={this.getOptions(currencies)}
            currencyDisabled={submitting}
            costName="newCost"
            costRequired={true}
            costDisabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Comments"
            multiline={true}
            validate={required}
            rows={6}
            rowsMax={6}
            maxLength={1000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

TripReportCostChangeFields.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool
};

export default withStyles(themes)(TripReportCostChangeFields);
