// Manually extending prototype to get instanceof working for Errors
function NotFoundError(message, ...params) {
  this.name = 'Not Found Error';
  this.message = message;

  if (this.message === undefined) {
    this.message = 'Unable to find resource';
  }

  this.stack = new Error().stack;
}

NotFoundError.prototype = Object.create(Error.prototype);
NotFoundError.prototype.constructor = NotFoundError;

NotFoundError.prototype.toString = function() {
  return this.message;
};

export default NotFoundError;
