import * as actionTypes from '../actionTypes/accommodation';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import TripReportSectionBaseAction from './TripReportSectionBaseAction';

export default class AccommodationAction extends TripReportSectionBaseAction {
  constructor() {
    super(
      LeaderPortalService.TripReport.Accommodation,
      actionTypes,
      'Accommodation'
    );
  }
}
