import React from 'react';
import PropTypes from 'prop-types';
import Rating from '../../common/forms/Rating';
import { Form } from 'react-final-form';

class TripReportStarRating extends React.Component {
  render() {
    const { name, label, ratingInitialValue, updateRating } = this.props;

    let rating = {};
    rating[name] = ratingInitialValue;
    return (
      <Form
        onSubmit={() => {}}
        initialValues={rating}
        render={({ handleSubmit, reset, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Rating name={name} label={label} update={updateRating} />
          </form>
        )}
      />
    );
  }
}

TripReportStarRating.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  ratingInitialValue: PropTypes.number,
  updateRating: PropTypes.func.isRequired
};

export default TripReportStarRating;
