import * as actionTypes from '../actionTypes/globalMessages';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_MESSAGES_ADD_MESSAGE:
      return action.payload;
    case actionTypes.GLOBAL_MESSAGES_REMOVE_MESSAGE:
      return null;
    default:
      return state;
  }
};
