export default {
  confirmationSection: {
    margin: '10px 18px 10px 0px'
  },
  confirmationBox: {
    display: 'flex'
  },
  confirmationPaper: {
    padding: '10px'
  },
  confirmationMessage: {
    paddingBottom: '10px'
  }
};
