import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './ErrorIcon.styles';

const ErrorIcon = ({ size, classes }) => {
  const iconClasses = classnames({
    [classes.smallIcon]: size === 'small',
    [classes.normalIcon]: size === 'normal'
  });

  return (
    <Icon className={iconClasses} color="error">
      error
    </Icon>
  );
};

ErrorIcon.propTypes = {
  size: PropTypes.string
};

ErrorIcon.defaultProps = {
  size: 'small'
};

export default withStyles(styles)(ErrorIcon);
