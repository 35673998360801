import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActivityAction from '../actions/ActivityAction';
import departureActions from '../../departures/actions';
import departureSelectors from '../../departures/selectors';
import TripReportSection from '../components/TripReportSection';
import TripReportActivityFields from '../components/TripReportActivityFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import '../../common/helpers/stringExtensions';
import updatePassengers from '../helpers/updateTripReportPaxList';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportActivityContainer extends React.Component {
  constructor(props) {
    super(props);

    this.defaultValues = { isActivityOptional: false };
  }

  format = values => {
    const formattedValues = values;

    // Prevent the pax information from going to API if included activity
    if (!formattedValues['isActivityOptional']) {
      formattedValues['pax'] = [];
    }

    return formattedValues;
  };

  renderSubHeader = activity => {
    return (
      activity.comments && (
        <div style={styles.trimText}>
          <span id={'activity.header.comments_' + activity.id}>
            {activity.comments}
          </span>
        </div>
      )
    );
  };

  renderContent = activity => {
    return (
      activity.comments && (
        <div style={styles.trimText}>
          <span id={'activity.header.comments_' + activity.id}>
            {activity.comments}
          </span>
        </div>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportActivityFields
      pax={this.props.pax}
      userResponses={this.props.userResponses}
      submitting={submitting}
    />
  );

  renderSectionCard = activity => {
    return (
      <TripReportSectionCard
        sectionName="Activity"
        itemName="activity"
        subTitle="Include any highlights or challenges with the activities. Consider both optional and included."
        title={activity.activityName}
        subHeader={this.renderSubHeader}
        item={activity}
        itemId={activity.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateActivity}
        delete={() =>
          this.props.actions.deleteActivity(
            this.props.departure.id,
            activity.id
          )
        }
        deleteMessage={activity =>
          `Are you sure you want to delete activity "${activity.comments.trunc(
            60
          )}"?`
        }
        format={this.format}
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { departure, activityList, userResponses } = this.props;

    return (
      departure &&
      activityList &&
      userResponses && (
        <TripReportSection
          sectionName="Activity"
          itemName="activity"
          subTitle="Include any highlights or challenges with the activities. Consider both optional and included."
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={activityList}
          insert={this.props.actions.insertActivity}
          defaultValues={this.defaultValues}
          format={this.format}
        />
      )
    );
  }
}

TripReportActivityContainer.propTypes = {
  departure: PropTypes.object,
  activityList: PropTypes.array,
  userResponses: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    insertActivity: PropTypes.func,
    deleteActivity: PropTypes.func,
    updateActivity: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  let paxList = departureSelectors.pax.getPax(state);

  return {
    pax: paxList != null ? paxList.pax : []
  };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      getPax: departureActions.selectedDeparture.getSelectedDeparture,
      insertActivity: values => async dispatch => {
        updatePassengers(values);
        await new ActivityAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteActivity: (departureId, activityId) => async dispatch => {
        await new ActivityAction().delete(departureId, activityId)(dispatch);
      },
      updateActivity: values => async dispatch => {
        updatePassengers(values);
        await new ActivityAction().update(
          params.router.params.id,
          values
        )(dispatch);
      }
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportActivityContainer)
);
