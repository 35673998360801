import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import MultipleSelect from '../../common/forms/MultipleSelect';
import SingleSelect from '../../common/forms/SingleSelect';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import themes from './TripReportField.styles';
import { FormSpy } from 'react-final-form';
import { Field } from 'react-final-form';
import required from '../../common/forms/validators/required';
import FormRadioGroup from '../../common/components/FormRadioGroup';
import FormRadio from '../../common/components/FormRadio';

class TripReportActivityFields extends React.Component {
  getOptions = pax => {
    return pax
      .sort((a, b) =>
        a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0
      )
      .map(p => ({
        label: `${p.bookingId} - ${p.fullName}`,
        value: p.id
      }));
  };

  renderActivities() {
    return (
      <Field
        name="isActivityOptional"
        parse={value => JSON.parse(value)}
        format={value => value.toString()}
      >
        {({ input }) => (
          <FormControl component="fieldset">
            <InputLabel shrink={true}>Activity Type</InputLabel>
            <FormRadioGroup
              aria-label="Activity Type"
              name="isActivityOptional"
              row
              {...input}
            >
              <FormControlLabel
                control={<FormRadio />}
                label="Included"
                value="false"
              />
              <FormControlLabel
                control={<FormRadio />}
                label="Optional"
                value="true"
              />
            </FormRadioGroup>
          </FormControl>
        )}
      </Field>
    );
  }

  render() {
    const { classes, userResponses, submitting } = this.props;

    const responses = userResponses
      ? userResponses.map(response => ({
          value: response.response,
          label: response.responseText
        }))
      : [];

    return (
      <Fragment>
        <div>{this.renderActivities()}</div>
        <div>
          <InputField
            name="activityName"
            placeholder="Activity Name"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="location"
            placeholder="Location"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="supplier"
            placeholder="Supplier"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            const isActivityOptional = values['isActivityOptional'];

            return (
              <Collapse in={isActivityOptional} unmountOnExit={true}>
                <div>
                  <MultipleSelect
                    name="pax"
                    placeholder="Pax Names"
                    validate={value => isActivityOptional && required(value)}
                    options={this.getOptions(this.props.pax)}
                    className={classes.wideField}
                    disabled={submitting}
                    selectAll={true}
                    selectAllText="Select all passengers"
                  />
                </div>
              </Collapse>
            );
          }}
        </FormSpy>
        <div>
          <SingleSelect
            name="safetyInstructions"
            label="Safety briefing was provided"
            validate={required}
            searchable={false}
            options={responses}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="comments"
            placeholder="Comments"
            multiline={true}
            validate={required}
            rows={6}
            rowsMax={6}
            maxLength={1000}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

TripReportActivityFields.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  pax: PropTypes.array.isRequired,
  userResponses: PropTypes.array
};

export default withStyles(themes)(TripReportActivityFields);
