import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Logo from './Logo';
import styles from './AvatarLogo.module.css';

const AvatarLogo = ({ brand, imgId }) => (
  <Avatar style={{ backgroundColor: 'white' }} className={styles.avatar}>
    <Logo brand={brand} imgId={imgId} />
  </Avatar>
);

AvatarLogo.propTypes = {
  brand: PropTypes.string
};

export default AvatarLogo;
