import * as actionTypes from '../actionTypes/countries';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.COUNTRIES_GET_SUCCESS:
      return action.payload.countries;
    default:
      return state;
  }
};
