import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GlobalMessage from '../components/GlobalMessage';
import { getMessage } from '../selectors/globalMessages';
import { removeMessage } from '../actions/globalMessages';
import Button from '@material-ui/core/Button';

class GlobalMessagesContainer extends React.Component {
  buildAction = message => {
    let action = [];

    if (message !== null) {
      if (message.redirect) {
        action.push(
          <Button
            key="refresh"
            color="primary"
            size="small"
            variant="contained"
            onClick={() => window.location.reload()}
          >
            REFRESH
          </Button>
        );
      }
    }

    return action;
  };

  render() {
    const { message, actions } = this.props;

    return (
      <GlobalMessage
        open={message !== null}
        message={message !== null ? message.content : ''}
        type={message !== null ? message.type : null}
        autoHideDuration={
          message !== null && (message.closable || message.redirect)
            ? 3000000
            : 2000
        }
        closable={(message && message.closable) || undefined}
        action={this.buildAction(message)}
        actions={actions}
      />
    );
  }
}

const mapStateToProps = state => ({
  message: getMessage(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ removeMessage }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalMessagesContainer);
