export default {
  tickDiv: {
    zIndex: 1000,
    position: 'absolute',
    color: '#448644',
    fontSize: '22px'
  },
  tick: {
    zIndex: 1000,
    position: 'relative',
    bottom: '8px',
    left: '21px',
    color: '#448644',
    fontSize: '22px'
  },
  blankTick: {
    zIndex: 1000,
    position: 'relative',
    width: '22px',
    height: '22px',
    display: 'box'
  },
  iconButton: {
    minWidth: '180px',
    '@media (max-width: 500px)': {
      minWidth: '120px'
    },
    '@media (max-width: 350px)': {
      minWidth: '100px'
    },
    '@media (max-width: 280px)': {
      minWidth: '90px'
    },
    margin: 'auto',
    padding: 'auto'
  }
};
