export default {
  paper: {
    margin: '5px 5px',
    overflow: 'auto',
    position: 'sticky',
    top: 0,
    paddingTop: '2px',
    zIndex: 1
  },
  content: {
    margin: '5px 5px'
  },
  badgeRoot: {
    padding: '0 5px'
  },
  badge: {
    right: '-12px',
    width: '20px',
    height: '20px'
  },
  secondaryBadge: {
    extend: 'badge',
    backgroundColor: 'rgba(0, 0, 0, 0.54)'
  },
  ratingPapper: {
    margin: '5px 5px',
    overflow: 'auto',
    padding: '5px 16px 5px 16px'
  }
};
