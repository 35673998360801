export default theme => ({
  fieldsContainer: {
    marginTop: '11px'
  },
  formControl: {
    margin: '5px 0px 5px 0px'
  },
  cost: {
    paddingLeft: theme.spacing.unit,
    margin: '5px 0 5px 0'
  }
});
