import * as actionTypes from '../actionTypes/covidQuestionnaireResponses';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const covidQuestionnaireResponsesClear = covidQuestionnaireResponses => ({
  type: actionTypes.COVID_QUESTIONNAIRE_RESPONSES_CLEAR
});

export const covidQuestionnaireResponsesGetSuccess = covidQuestionnaireResponses => ({
  type: actionTypes.COVID_QUESTIONNAIRE_RESPONSES_GET_SUCCESS,
  payload: {
    covidQuestionnaireResponses
  }
});

export const getCovidQuestionnaireResponses = (
  departureId,
  paxId,
  targetId
) => async dispatch => {
  dispatch(covidQuestionnaireResponsesClear());
  const data = await LeaderPortalService.CovidQuestionnaire.getCovidQuestionnaireResponses(
    departureId,
    paxId,
    targetId
  );
  dispatch(covidQuestionnaireResponsesGetSuccess(data.body));
};

export const getCovidQuestionnaireResponsesByDepartureId = (
  departureId,
  targetId
) => async dispatch => {
  const data = await LeaderPortalService.CovidQuestionnaire.getCovidQuestionnaireResponsesByDepartureId(
    departureId,
    targetId
  );

  dispatch(covidQuestionnaireResponsesGetSuccess(data.body));
};
