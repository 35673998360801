import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommentAction from '../actions/CommentAction';
import TripReportSection from '../components/TripReportSection';
import TripReportCommentFields from '../components/TripReportCommentFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import TripReportStarRating from '../components/TripReportStarRating';
import styles from '../components/TripReportCardActions.styles';
import { withStyles } from '@material-ui/core/styles';
import '../../common/helpers/stringExtensions';
import selectors from '../selectors';
import * as tripRatingActions from '../actions/TripRating';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportCommentContainer extends React.Component {
  renderSectionFields = submitting => (
    <TripReportCommentFields submitting={submitting} />
  );

  renderSubHeader = comment => {
    return (
      comment.location && (
        <div style={styles.trimText}>
          <span id={'comment.header.location_' + comment.id}>
            {comment.location}
          </span>
        </div>
      )
    );
  };

  renderSectionCard = comment => {
    return (
      <TripReportSectionCard
        sectionName="comments"
        itemName="comment"
        title={comment.comments}
        subHeader={this.renderSubHeader}
        cardContent={() => {}}
        item={comment}
        itemId={comment.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateComment}
        delete={() => this.props.actions.deleteComment(comment.id)}
        deleteMessage={comment =>
          `Are you sure you want to delete the comment "${comment.comments.trunc(
            60
          )}"?`
        }
      />
    );
  };

  renderRatings = (departure, classes) => {
    return (
      <div className={classes.ratingPapper}>
        {departure && (
          <TripReportStarRating
            name={'overallTripRating'}
            label="How would you rate the trip overall?"
            ratingInitialValue={this.props.overallTripRating}
            updateRating={this.props.actions.updateOverallTripRating}
            getRating={this.props.actions.getOverallTripRating}
          />
        )}

        {departure && (
          <TripReportStarRating
            name={'groupDynamicRating'}
            label="How was the group dynamic?"
            ratingInitialValue={this.props.groupDynamicRating}
            updateRating={this.props.actions.updateGroupDynamicRating}
            getRating={this.props.actions.getGroupDynamicRating}
          />
        )}
      </div>
    );
  };

  componentDidMount() {
    this.props.actions.getGroupDynamicRating();
    this.props.actions.getOverallTripRating();
  }

  render() {
    const { classes, departure, commentList } = this.props;

    let groupDynamicRating = this.props.groupDynamicRating;
    let overallTripRating = this.props.overallTripRating;

    return (
      commentList &&
      groupDynamicRating !== -1 &&
      overallTripRating !== -1 && (
        <div>
          {departure && commentList && (
            <TripReportSection
              sectionName="comments"
              itemName="comment"
              subTitle="Share your experiences both positive or negative and provide your ideas for improvements to the trip."
              sectionCard={this.renderSectionCard}
              sectionFields={this.renderSectionFields}
              items={commentList}
              departureId={departure.id}
              insert={this.props.actions.insertComment}
              preContent={this.renderRatings}
            />
          )}
        </div>
      )
    );
  }
}

TripReportCommentContainer.propTypes = {
  departure: PropTypes.object,
  commentList: PropTypes.array,
  actions: PropTypes.shape({
    insertComment: PropTypes.func,
    deleteComment: PropTypes.func,
    updateComment: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {
    groupDynamicRating: selectors.groupDynamicRating.getGroupDynamicRating(
      state
    ),
    overallTripRating: selectors.overallTripRating.getOverallTripRating(state)
  };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      insertComment: values => async dispatch => {
        await new CommentAction().insert(
          params.router.params.id,
          values
        )(dispatch);
      },
      deleteComment: commentId => async dispatch => {
        await new CommentAction().delete(
          params.router.params.id,
          commentId
        )(dispatch);
      },
      updateComment: values => async dispatch => {
        await new CommentAction().update(
          params.router.params.id,
          values
        )(dispatch);
      },

      getOverallTripRating: () =>
        tripRatingActions.get(params.router.params.id),

      updateOverallTripRating: rating =>
        tripRatingActions.updateOverallRating(params.router.params.id, rating),

      getGroupDynamicRating: () =>
        tripRatingActions.get(params.router.params.id),

      updateGroupDynamicRating: rating =>
        tripRatingActions.updateGroupDynamicRating(
          params.router.params.id,
          rating
        )
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(TripReportCommentContainer))
);
