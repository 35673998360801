import React from 'react';
import ReactGA from 'react-ga';
import cardStyles from '../../common/styles/Section.styles';
import Card from '@material-ui/core/Card';
import DepartureCardContent from './DepartureCardContent';
import DepartureCardHeader from './DepartureCardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ConfirmationDialog from '../../common/components/ConfirmationDialog';
import ErrorIcon from '../../common/components/ErrorIcon';
import styles from './LeaderActions.styles';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { format } from 'date-fns';
import { fileUploadStatus } from '../../common/constants/fileUploadStatus';
import { ROUTE_CREWQUESTIONNAIRE } from '../../common/constants/routes';
import createLink from '../../common/helpers/createLink';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { targetType } from '../../common/constants/targetType';
import { covidQuestionAnswerText } from '../../common/constants/covidQuestionAnswerText';

class LeaderActions extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    reviewCompleteConfirmAction: false,
    confirmTripReportAction: false,
    tripReportUploaded: false,
    uploadingTripReport: false
  };

  handleUISuccessfulReviewComplete = () => {
    this.setState({
      reviewCompleteConfirmAction: false
    });
  };

  handleUISuccessfulTripReportUploaded = () => {
    this.setState({
      tripReportUploaded: true,
      confirmTripReportAction: false
    });
  };

  handleGoogleAnalyticsRequest = actionName => {
    ReactGA.event({
      category: 'Report',
      action: actionName,
      label: `${this.props.departure.id}`
    });
  };

  handleReviewCompleteConfirmDialog = () => {
    this.setState({ reviewCompleteConfirmAction: true });
  };

  handleTripReportConfirmationDialog = event => {
    if (!this.props.departure.tripReportEnabled) {
      event.preventDefault();
      return;
    }

    this.setState({ confirmTripReportAction: true });
  };

  handleReviewComplete = async () => {
    let apiResponse = await this.props.departurePaxDetailReviewComplete();

    if (apiResponse && apiResponse === true) {
      this.props.getDeparturePaxDetailReviewDetail(this.props.departure.id);
      this.handleUISuccessfulReviewComplete();
    }
  };

  handleUploadTripReportToSharepoint = async () => {
    this.disableTripReportButtonDuringUpload();
    this.handleGoogleAnalyticsRequest('Upload trip report to SharePoint');
    let apiResponse = await this.props.tripReportUploadToSharepoint();

    if (apiResponse && apiResponse === true) {
      this.handleUISuccessfulTripReportUploaded();
    }
  };

  disableTripReportButtonDuringUpload = () => {
    this.setState({ uploadingTripReport: true });
  };

  closeReviewCompleteConfirmDialog = () => {
    this.setState({ reviewCompleteConfirmAction: false });
  };

  closeTripReportConfirmDialog = () => {
    this.setState({ confirmTripReportAction: false });
  };

  getStepStyle = (successFlag, classes) => {
    return successFlag == true ? classes.stepCompleted : classes.stepFailed;
  };

  getPaxUpdatedMessage = (
    departurePaxCount,
    allPaxConfirmed,
    confirmedPaxCount
  ) => {
    if (departurePaxCount == 0) {
      return <Typography variant="caption">No pax on departure.</Typography>;
    } else if (allPaxConfirmed) {
      return (
        <Typography variant="caption">
          All customers have confirmed their details.
        </Typography>
      );
    } else {
      return (
        <div>
          <Typography variant="caption">
            <div>
              <span>
                <b>
                  {confirmedPaxCount} of {departurePaxCount} customers
                  completed.
                </b>
              </span>
              <br />
              <span>
                All customers are required to confirm their details are correct
                prior to travel.
              </span>
            </div>
          </Typography>
        </div>
      );
    }
  };

  getCovidDeclarationSubmissionMessage = (
    departurePaxCount,
    covidQuestionnaireSubmittedPaxCount,
    covidYesResponsePaxNames,
    hasAllPaxSubmittedQuestionnaire
  ) => {
    if (departurePaxCount == 0) {
      return <Typography variant="caption">No pax on departure.</Typography>;
    } else if (hasAllPaxSubmittedQuestionnaire) {
      return (
        <div>
          {this.getCovidYesResponsePax(covidYesResponsePaxNames)}
          <br />
          <Typography variant="caption">
            All passengers have completed the Self Declaration.
          </Typography>
        </div>
      );
    } else {
      return (
        <div>
          {this.getCovidYesResponsePax(covidYesResponsePaxNames)}
          <br />
          <Typography variant="caption">
            <div>
              <span>
                <b>
                  {covidQuestionnaireSubmittedPaxCount} of {departurePaxCount}{' '}
                  customers completed.
                </b>
              </span>
              <br />
              <span>
                All customers are required to complete the COVID-19 Customer
                Self Declaration.
              </span>
            </div>
          </Typography>
        </div>
      );
    }
  };

  getCovidHealthScreeningSubmissionMessage = (
    departurePaxCount,
    covidQuestionnaireSubmittedPaxCount,
    covidYesResponsePaxNames,
    hasAllPaxSubmittedCovidHealthScreening
  ) => {
    if (departurePaxCount == 0) {
      return <Typography variant="caption">No pax on departure.</Typography>;
    } else if (hasAllPaxSubmittedCovidHealthScreening) {
      return (
        <div>
          {this.getCovidHealthScreeningYesResponsePax(covidYesResponsePaxNames)}
          <br />
          <Typography variant="caption">
            All customers have completed the Covid 19 Health Screening.
          </Typography>
        </div>
      );
    } else {
      return (
        <div>
          {this.getCovidHealthScreeningYesResponsePax(covidYesResponsePaxNames)}
          <br />
          <Typography variant="caption">
            <div>
              <span>
                <b>
                  {covidQuestionnaireSubmittedPaxCount} of {departurePaxCount}{' '}
                  customers completed.
                </b>
              </span>
              <br />
              <span>
                Depending on your region; customers may be required to provide
                proof they are eligible to travel. If you are unsure if this is
                required please check with your Operations team.
              </span>
            </div>
          </Typography>
        </div>
      );
    }
  };

  getCovidYesResponsePax = covidYesResponsePaxNames => {
    if (covidYesResponsePaxNames.length > 0) {
      return (
        <div>
          <Icon className={this.props.classes.covidResponseWarningIcon}>
            warning
          </Icon>
          <span className={this.props.classes.covidResponseWarning}>
            {' '}
            The following passengers have responded 'Yes' to a question in the
            Self Declaration. You must follow the Incident Management Plan for
            next steps.
          </span>
          <ul>
            {covidYesResponsePaxNames.map((pax, i) => (
              <li className={this.props.classes.list}>{pax}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  getCovidHealthScreeningYesResponsePax = covidYesResponsePaxNames => {
    if (covidYesResponsePaxNames.length > 0) {
      return (
        <div>
          <Icon className={this.props.classes.covidResponseWarningIcon}>
            warning
          </Icon>
          <span className={this.props.classes.covidResponseWarning}>
            {' '}
            The following customers have not provided the necessary
            documentation to travel or have a pre-approved vaccination
            exemption. Please follow up with your Operations team as required.
          </span>
          <ul>
            {covidYesResponsePaxNames.map((pax, i) => (
              <li className={this.props.classes.list}>{pax}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  hasCrewSubmittedQuestionnaire = () => {
    if (
      this.props.covidQuestionnaireCrewResponse != null &&
      this.props.covidQuestionnaireCrewResponse.isQuestionnaireSubmitted
    ) {
      return (
        <span>
          Self Declaration submitted on{' '}
          {this.props.covidQuestionnaireCrewResponse.submittedDate}.
        </span>
      );
    } else {
      return (
        <span>
          The Self Declaration will be available to complete on{' '}
          {format(
            this.props.departure.departureDate,
            process.env.REACT_APP_DATE_FORMAT
          )}
          . You must submit the declaration before joining your group.
        </span>
      );
    }
  };

  render() {
    const {
      classes,
      departure,
      pax,
      covidQuestionnaireCrewResponse,
      leaderActions,
      isLeaderPortalCovidSurveyEnabled
    } = this.props;
    let allPaxConfirmed = false;
    let departurePaxCount = 0;
    let covidQuestionnaireSubmittedPaxCount = 0;
    let covidHelthScreeningSubmittedPaxCount = 0;
    let covidQuestionnaireYesResponsePaxNames = null;
    let covidHelthScreeningYesResponsePaxNames = null;
    let confirmedPaxCount = 0;

    if (pax) {
      let paxConfirmed = pax.pax.filter(currentPax => {
        return currentPax.detailsConfirmed;
      });

      confirmedPaxCount = paxConfirmed.length;

      allPaxConfirmed = confirmedPaxCount == pax.pax.length;
      departurePaxCount = pax.pax.length;
      let covidQuestionnaireSubmittedPax = pax.pax.filter(p => {
        return p.isCovidQuestionnaireSubmitted;
      });
      covidQuestionnaireSubmittedPaxCount =
        covidQuestionnaireSubmittedPax.length;

      let covidHelthScreeningSubmittedPax = pax.pax.filter(p => {
        return p.isCovidHelthScreeningSubmitted;
      });
      covidHelthScreeningSubmittedPaxCount =
        covidHelthScreeningSubmittedPax.length;

      covidQuestionnaireYesResponsePaxNames = pax.pax
        .filter(p => {
          return (
            p.isCovidQuestionnaireSubmitted &&
            p.covidQuestionnaireResponses.some(
              a =>
                a.isAlertingAnswer &&
                a.covidQuestionnaireTypeId == targetType.CUSTOMER
            )
          );
        })
        .map(i => i.fullNameWithTitle);

      covidHelthScreeningYesResponsePaxNames = pax.pax
        .filter(p => {
          return (
            p.isCovidHelthScreeningSubmitted &&
            p.covidQuestionnaireResponses.some(
              a =>
                a.isAlertingAnswer &&
                a.covidQuestionnaireTypeId == targetType.PRE_TRIP_PAX_COVID_TEST
            )
          );
        })
        .map(i => i.fullNameWithTitle);
    }

    const { activeStep } = this.state;

    let tripReport =
      leaderActions &&
      leaderActions.sharepointFileUploads &&
      leaderActions.sharepointFileUploads.find(
        f => f.fileType === 'TripReport'
      );
    let departurePaxDetailReview =
      leaderActions &&
      leaderActions.departurePaxDetailReviewDetails &&
      leaderActions.departurePaxDetailReviewDetails[0];

    let tripReportUploadStatus =
      tripReport == undefined
        ? fileUploadStatus.NOT_UPLOADED
        : tripReport.sharepointFileUploadStatus;

    let standardMessage =
      ' Clicking confirm will indicate that you have reviewed all customer information and the details are correct.';
    let tripReportMessage =
      'Once the trip report has been submitted you will be unable to make any further changes.';
    let manifestMessage = !allPaxConfirmed
      ? `Not all customers have checked their details. ${standardMessage}`
      : standardMessage;

    let hasAllPaxSubmittedCovidQuestionnaire =
      departurePaxCount > 0 &&
      covidQuestionnaireSubmittedPaxCount == departurePaxCount;

    let hasAllPaxSubmittedCovidHealthScreening =
      departurePaxCount > 0 &&
      covidHelthScreeningSubmittedPaxCount == departurePaxCount;

    return (
      <div>
        <Card className={cardStyles.card}>
          <DepartureCardHeader departure={departure} />
          <DepartureCardContent departure={departure} />
        </Card>

        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          nonLinear={false}
        >
          {isLeaderPortalCovidSurveyEnabled && (
            <Step
              key="Covid19LeaderAndCrewSelfDeclaration"
              active="true"
              completed={
                covidQuestionnaireCrewResponse != null &&
                covidQuestionnaireCrewResponse.isQuestionnaireSubmitted
              }
              classes={{
                root:
                  covidQuestionnaireCrewResponse != null &&
                  covidQuestionnaireCrewResponse.isQuestionnaireSubmitted
                    ? classes.stepCompleted
                    : classes.Step
              }}
            >
              <StepLabel>COVID-19 Leader &amp; Crew Self Declaration</StepLabel>

              <StepContent>
                <Typography variant="caption">
                  {this.hasCrewSubmittedQuestionnaire()}
                </Typography>
                <br />
                <Button
                  variant="contained"
                  raised
                  color="primary"
                  id="btn.handleManifestConfirmationDialog"
                  component={Link}
                  disabled={!departure.isCovidQuestionnaireEnabled}
                  to={createLink(ROUTE_CREWQUESTIONNAIRE, {
                    departureId: departure.id
                  })}
                >
                  {covidQuestionnaireCrewResponse != null &&
                  covidQuestionnaireCrewResponse.isQuestionnaireSubmitted
                    ? 'View'
                    : 'Start'}
                </Button>
              </StepContent>
            </Step>
          )}
          {isLeaderPortalCovidSurveyEnabled && (
            <Step
              key="Covid19CustomerSelfDeclaration"
              active="true"
              completed={hasAllPaxSubmittedCovidQuestionnaire}
              classes={{
                root: hasAllPaxSubmittedCovidQuestionnaire
                  ? classes.stepCompleted
                  : classes.Step
              }}
            >
              <StepLabel>COVID-19 Customer Self Declaration</StepLabel>

              <StepContent>
                <Typography variant="caption">
                  {this.getCovidDeclarationSubmissionMessage(
                    departurePaxCount,
                    covidQuestionnaireSubmittedPaxCount,
                    covidQuestionnaireYesResponsePaxNames,
                    hasAllPaxSubmittedCovidQuestionnaire
                  )}
                </Typography>
              </StepContent>
            </Step>
          )}
          {isLeaderPortalCovidSurveyEnabled && (
            <Step
              key="Covid19CustomerHealthScreening"
              active="true"
              completed={hasAllPaxSubmittedCovidHealthScreening}
              classes={{
                root: hasAllPaxSubmittedCovidHealthScreening
                  ? classes.stepCompleted
                  : classes.Step
              }}
            >
              <StepLabel>COVID-19 Customer Health Screening</StepLabel>

              <StepContent>
                <Typography variant="caption">
                  {this.getCovidHealthScreeningSubmissionMessage(
                    departurePaxCount,
                    covidHelthScreeningSubmittedPaxCount,
                    covidHelthScreeningYesResponsePaxNames,
                    hasAllPaxSubmittedCovidHealthScreening
                  )}
                </Typography>
              </StepContent>
            </Step>
          )}
          <Step
            key="PassengerInsuranceEmergencyDetails"
            active="true"
            completed={departurePaxDetailReview != null}
            classes={{
              root:
                departurePaxDetailReview != null &&
                this.getStepStyle(departurePaxDetailReview != null, classes)
            }}
          >
            <StepLabel>Customer Insurance &amp; Emergency Details</StepLabel>
            <StepContent>
              <Typography variant="caption">
                {this.getPaxUpdatedMessage(
                  departurePaxCount,
                  allPaxConfirmed,
                  confirmedPaxCount
                )}
              </Typography>
              <br />

              <Button
                style={{ marginTop: '20px' }}
                raised
                variant="contained"
                color="primary"
                onClick={this.handleReviewCompleteConfirmDialog}
                id="btn.handleReviewCompleteConfirmDialog"
                disabled={
                  !departure.departurePaxDetailReviewCompleteEnabled ||
                  departurePaxCount == 0
                }
              >
                Review Complete
              </Button>
              <br />

              {departurePaxDetailReview && (
                <span className={classes.warningMessage}>
                  Last review completed on{' '}
                  {format(
                    departurePaxDetailReview.lastReviewedOnLocalTime,
                    process.env.REACT_APP_DATETIME_FORMAT
                  )}
                  .
                </span>
              )}

              <ConfirmationDialog
                open={this.state.reviewCompleteConfirmAction}
                close={this.closeReviewCompleteConfirmDialog}
                title="Review Complete"
                message={manifestMessage}
                action={this.handleReviewComplete}
                actionText="Confirm"
              />
            </StepContent>
          </Step>

          <Step
            key="TripReport"
            active="true"
            completed={
              tripReportUploadStatus === fileUploadStatus.UPLOADED &&
              !this.state.tripReportUploaded
            }
            classes={{
              root:
                tripReportUploadStatus !== fileUploadStatus.NOT_UPLOADED &&
                tripReportUploadStatus !== fileUploadStatus.UPLOADING &&
                tripReportUploadStatus !== fileUploadStatus.REACTIVATED &&
                !this.state.tripReportUploaded &&
                this.getStepStyle(
                  tripReportUploadStatus === fileUploadStatus.UPLOADED,
                  classes
                )
            }}
          >
            <StepLabel>Trip Report</StepLabel>
            <StepContent>
              <Typography variant="caption" style={{ fontWeight: '400' }}>
                Upload a copy of the trip report to SharePoint.
              </Typography>
              <br />
              <Button
                style={{ marginTop: '20px' }}
                raised
                variant="contained"
                color="primary"
                onClick={this.handleTripReportConfirmationDialog}
                id="btn.handleTripReportConfirmationDialog"
                disabled={
                  !departure.tripReportEnabled ||
                  this.state.uploadingTripReport ||
                  tripReportUploadStatus === fileUploadStatus.UPLOADING
                }
              >
                Upload
              </Button>
              <br />

              {!departure.tripReportEnabled && (
                <span className={classes.warningMessage}>
                  Trip Report will be available to upload from{' '}
                  {format(
                    departure.finishDate,
                    process.env.REACT_APP_DATE_FORMAT
                  )}
                  .
                </span>
              )}

              {tripReport &&
                !this.state.tripReportUploaded &&
                tripReportUploadStatus === fileUploadStatus.UPLOADED && (
                  <span className={classes.warningMessage}>
                    Last submitted to SharePoint on{' '}
                    {format(
                      tripReport.lastUploadLocalDateTime,
                      process.env.REACT_APP_DATETIME_FORMAT
                    )}
                    .
                  </span>
                )}
              {tripReport &&
                !this.state.tripReportUploaded &&
                tripReportUploadStatus === fileUploadStatus.FAILED && (
                  <span className={classes.errorMessage}>
                    <ErrorIcon />
                    Trip Report failed to upload to SharePoint. Please upload it
                    again.
                  </span>
                )}
              {(this.state.tripReportUploaded ||
                tripReportUploadStatus === fileUploadStatus.UPLOADING) && (
                <span className={classes.warningMessage}>
                  Trip Report is being uploaded. This might take a couple of
                  minutes.
                </span>
              )}
              <ConfirmationDialog
                open={this.state.confirmTripReportAction}
                close={this.closeTripReportConfirmDialog}
                title="Upload trip report to SharePoint"
                message={tripReportMessage + standardMessage}
                action={this.handleUploadTripReportToSharepoint}
                actionText="Confirm"
              />
            </StepContent>
          </Step>
        </Stepper>
      </div>
    );
  }
}

export default withStyles(styles)(LeaderActions);
