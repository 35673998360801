import * as actionTypes from '../actionTypes/pax';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import selectors from '../selectors';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';

export const paxGetRequest = departureId => ({
  type: actionTypes.PAX_GET_REQUEST,
  payload: {
    departureId
  }
});

export const paxGetSuccess = (departureId, pax) => ({
  type: actionTypes.PAX_GET_SUCCESS,
  payload: {
    departureId,
    pax
  }
});

export const departurePaxRequest = (paxId, departureId) => ({
  type: actionTypes.DEPARTURE_PAX_GET_REQUEST,
  payload: {
    departureId,
    paxId
  }
});

export const departurePaxSuccess = pax => ({
  type: actionTypes.DEPARTURE_PAX_GET_SUCCESS,
  payload: {
    pax
  }
});

export const passportGetRequest = paxId => ({
  type: actionTypes.PAX_PASSPORT_GET_REQUEST,
  payload: {
    paxId
  }
});

export const passportGetSuccess = paxPassportInfo => ({
  type: actionTypes.PAX_PASSPORT_GET_SUCCESS,
  payload: {
    paxPassportInfo
  }
});

export const visaGetRequest = paxId => ({
  type: actionTypes.PAX_VISA_GET_REQUEST,
  payload: {
    paxId
  }
});

export const visaGetSuccess = paxVisaInfo => ({
  type: actionTypes.PAX_VISA_GET_SUCCESS,
  payload: {
    paxVisaInfo
  }
});

export const insuranceGetRequest = paxId => ({
  type: actionTypes.PAX_INSURANCE_GET_REQUEST,
  payload: {
    paxId
  }
});

export const insuranceGetSuccess = paxInsuranceInfo => ({
  type: actionTypes.PAX_INSURANCE_GET_SUCCESS,
  payload: {
    paxInsuranceInfo
  }
});

export const emergencyContactGetRequest = paxId => ({
  type: actionTypes.PAX_EMERGENCYCONTACT_GET_REQUEST,
  payload: {
    paxId
  }
});

export const emergencyContactGetSuccess = paxEmergencyContact => ({
  type: actionTypes.PAX_EMERGENCYCONTACT_GET_SUCCESS,
  payload: {
    paxEmergencyContact
  }
});

export const contactGetRequest = paxId => ({
  type: actionTypes.PAX_CONTACT_GET_REQUEST,
  payload: {
    paxId
  }
});

export const contactGetSuccess = paxContact => ({
  type: actionTypes.PAX_CONTACT_GET_SUCCESS,
  payload: {
    paxContact
  }
});

export const paxUpdateRequest = (paxId, departureId) => ({
  type: actionTypes.PAX_UPDATE_REQUEST,
  payload: {
    paxId,
    departureId
  }
});

export const paxUpdateSuccess = () => ({
  type: actionTypes.PAX_UPDATE_SUCCESS
});

function shouldFetch(state, id) {
  let stateDeparturePax = selectors.pax.getPax(state);

  return !stateDeparturePax || stateDeparturePax.departureId.toString() !== id;
}

function shouldFetchDeparturePax(state, id) {
  const selectedPax = selectors.selectedPax.getSelectedPax(state);

  return !selectedPax || !selectedPax.pax || !selectedPax.pax.DepartureId;
}

function shouldFetchSelectedDeparturePax(state, id) {
  const selectedPax = selectors.selectedPax.getSelectedDeparturePax(state);

  return !selectedPax || !selectedPax.pax || !selectedPax.pax.DepartureId;
}

function shouldFetchPassport(state, id) {
  const selectedPax = selectors.selectedPax.getSelectedPax(state);
  return (
    !selectedPax ||
    !selectedPax.passport ||
    !selectedPax.passport.id ||
    selectedPax.passport.id.toString() !== id
  );
}

function shouldFetchVisa(state, id) {
  const selectedPax = selectors.selectedPax.getSelectedPax(state);

  return (
    !selectedPax ||
    !selectedPax.visa ||
    !selectedPax.visa.id ||
    selectedPax.visa.id.toString() !== id
  );
}

function shouldFetchInsurance(state, id) {
  const selectedPax = selectors.selectedPax.getSelectedPax(state);

  return (
    !selectedPax ||
    !selectedPax.insurance ||
    !selectedPax.insurance.id ||
    selectedPax.insurance.id.toString() !== id
  );
}

function shouldFetchEmergencyContact(state, id) {
  const selectedPax = selectors.selectedPax.getSelectedPax(state);

  return (
    !selectedPax ||
    !selectedPax.emergencyContact ||
    !selectedPax.emergencyContact.id ||
    selectedPax.emergencyContact.id.toString() !== id
  );
}

function shouldFetchContact(state, id) {
  const selectedPax = selectors.selectedPax.getSelectedPax(state);

  return (
    !selectedPax ||
    !selectedPax.contact ||
    !selectedPax.contact.id ||
    selectedPax.contact.id.toString() !== id
  );
}

export const paxClear = () => ({
  type: actionTypes.PAX_CLEAR
});

export const depaturePaxClear = () => ({
  type: actionTypes.DEPARTURE_PAX_CLEAR
});

export const passportClear = () => ({
  type: actionTypes.PAX_PASSPORT_CLEAR
});

export const visaClear = () => ({
  type: actionTypes.PAX_VISA_CLEAR
});

export const insuranceClear = () => ({
  type: actionTypes.PAX_INSURANCE_CLEAR
});

export const emergencyContactClear = () => ({
  type: actionTypes.PAX_EMERGENCYCONTACT_CLEAR
});

export const contactClear = () => ({
  type: actionTypes.PAX_CONTACT_CLEAR
});

export const clearAll = dispatch => {
  dispatch(paxClear());
  dispatch(depaturePaxClear());
  dispatch(passportClear());
  dispatch(visaClear());
  dispatch(insuranceClear());
  dispatch(emergencyContactClear());
  dispatch(contactClear());
};

export const getPax = id => async (dispatch, getState) => {
  if (!shouldFetch(getState(), id)) {
    return null;
  }

  dispatch(paxGetRequest(id));
  dispatch(paxClear());

  const data = await LeaderPortalService.Departures.getPaxByDepartureId(id);

  dispatch(paxGetSuccess(id, data));
};

export const getDeparturePax = (paxId, departureId) => async (
  dispatch,
  getState
) => {
  if (!shouldFetchDeparturePax(getState(), paxId)) {
    return null;
  }

  dispatch(departurePaxRequest(paxId, departureId));
  dispatch(depaturePaxClear());

  const data = await LeaderPortalService.Departures.getDeparturePax(
    paxId,
    departureId
  );

  dispatch(departurePaxSuccess(data));
};

export const getSelectedDeparturePax = (paxId, departureId) => async (
  dispatch,
  getState
) => {
  if (!shouldFetchSelectedDeparturePax(getState(), paxId)) {
    return null;
  }

  dispatch(departurePaxRequest(paxId, departureId));
  dispatch(depaturePaxClear());

  const data = await LeaderPortalService.Departures.getDeparturePax(
    paxId,
    departureId
  );

  dispatch(departurePaxSuccess(data));
};

export const getPassport = (paxId, departureId) => async (
  dispatch,
  getState
) => {
  if (!shouldFetchPassport(getState(), paxId)) {
    return null;
  }

  dispatch(passportGetRequest(paxId));
  dispatch(passportClear());

  const data = await LeaderPortalService.Pax.getPassport(paxId, departureId);

  dispatch(passportGetSuccess(data || {}));
};

export const getVisa = (paxId, departureId) => async (dispatch, getState) => {
  if (!shouldFetchVisa(getState(), paxId)) {
    return null;
  }

  dispatch(visaGetRequest(paxId));
  dispatch(visaClear());

  const data = await LeaderPortalService.Pax.getVisa(paxId, departureId);

  dispatch(visaGetSuccess(data || {}));
};

export const getInsurance = (paxId, departureId) => async (
  dispatch,
  getState
) => {
  if (!shouldFetchInsurance(getState(), paxId)) {
    return null;
  }

  dispatch(insuranceGetRequest(paxId));
  dispatch(insuranceClear());

  const data = await LeaderPortalService.Pax.getInsurance(paxId, departureId);

  dispatch(insuranceGetSuccess(data || {}));
};

export const getEmergencyContact = (paxId, departureId) => async (
  dispatch,
  getState
) => {
  if (!shouldFetchEmergencyContact(getState(), paxId)) {
    return null;
  }

  dispatch(emergencyContactGetRequest(paxId));
  dispatch(emergencyContactClear());

  const data = await LeaderPortalService.Pax.getEmergencyContact(
    paxId,
    departureId
  );

  dispatch(emergencyContactGetSuccess(data || {}));
};

export const getContact = (paxId, departureId) => async (
  dispatch,
  getState
) => {
  if (!shouldFetchContact(getState(), paxId)) {
    return null;
  }

  dispatch(contactGetRequest(paxId));
  dispatch(contactClear());

  const data = await LeaderPortalService.Pax.getContact(paxId, departureId);

  dispatch(contactGetSuccess(data || {}));
};

export const updatePax = (paxId, departureId, values) => async dispatch => {
  dispatch(paxUpdateRequest(paxId, departureId));

  await LeaderPortalService.Pax.updatePax(paxId, departureId, values);

  dispatch(paxClear());
  dispatch(addMessage('Information updated', messageTypes.success));
  dispatch(paxUpdateSuccess());
};
