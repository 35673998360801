export default {
  '@keyframes loadingField': {
    '0%': { 'background-position': '0% 0%' },
    '50%': { 'background-position': '50% 00%' },
    '100%': { 'background-position': '100% 0%' }
  },
  progressiveLoading: {
    background:
      'linear-gradient(90deg, #EFEFEF, #CCC, #EFEFEF, #CCC, #EFEFEF) ',
    'border-radius': '4px',
    'background-size': '200% 200%',
    animation:
      'loadingField 1.5s cubic-bezier(0.21, 0.21, 0.8, 0.63) 0s infinite'
  }
};
