import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  errorCode: {
    fontWeight: '300'
  },
  errorName: {
    fontSize: '1.6125rem',
    display: 'inline',
    color: 'rgba(0, 0, 0, 0.54)',
    paddingLeft: '15px',
    fontWeight: '300'
  },
  cardContent: {
    paddingTop: 0
  }
};

const ErrorMessage = ({ code, name, message, actions, classes }) => (
  <Card>
    <div>
      <CardHeader
        title={
          <Typography variant="h5" className={classes.errorCode}>
            {code}
            <Typography
              variant="h5"
              component="span"
              className={classes.errorName}
            >
              {name}
            </Typography>
          </Typography>
        }
      />
      <CardContent className={classes.cardContent}>
        <Typography>{message}</Typography>
      </CardContent>
      {actions && (
        <div>
          <Divider />
          <CardActions>
            {actions.map((v, index) => (
              <Button key={index} size="small" color="primary" href={v.href}>
                {v.name}
              </Button>
            ))}
          </CardActions>
        </div>
      )}
    </div>
  </Card>
);

ErrorMessage.propTypes = {
  code: PropTypes.string,
  name: PropTypes.string,
  message: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string
    })
  )
};

export default withStyles(styles)(ErrorMessage);
