import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import styles from './KeyValueItem.styles';
import { withStyles } from '@material-ui/core/styles';

const KeyValueItem = ({
  label,
  labelType,
  value,
  valueType,
  icon,
  orientation,
  valueId,
  classes
}) => {
  const iconElement = icon && (
    <div className={classes.icon}>
      {React.cloneElement(icon, { color: 'secondary' })}
    </div>
  );

  const keyValue = (
    <div className={icon ? classes.iconContainer : classes.container}>
      {icon ? iconElement : null}
      <div>
        <Typography type={labelType} className={classes.defaultTypography}>
          {label}
        </Typography>{' '}
        {orientation === KeyValueItemOrientation.vertical ? <br /> : null}
        <Typography
          type={valueType}
          className={classes.defaultTypography}
          id={valueId}
        >
          {value}
        </Typography>
      </div>
    </div>
  );

  return keyValue;
};

KeyValueItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  icon: PropTypes.node,
  orientation: PropTypes.string
};

export const KeyValueItemOrientation = {
  vertical: 'vertical',
  horizontal: 'horizontal'
};

KeyValueItem.defaultProps = {
  orientation: KeyValueItemOrientation.vertical,
  labelType: 'h1',
  valueType: 'h1'
};

export default withStyles(styles)(KeyValueItem);
