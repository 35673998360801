import * as actionTypes from '../actionTypes/UserResponses';
import LeaderPortalService from '../../common/services/LeaderPortalService';

export const tripReportUserResponsesGetRequest = () => ({
  type: actionTypes.USER_RESPONSES_GET_REQUEST
});

export const tripReportUserResponsesGetSuccess = userResponses => ({
  type: actionTypes.USER_RESPONSES_GET_SUCCESS,
  payload: {
    userResponses
  }
});

export const getUserResponses = () => async dispatch => {
  try {
    dispatch(tripReportUserResponsesGetRequest());

    const userResponses = await LeaderPortalService.TripReport.UserResponses.get();

    dispatch(tripReportUserResponsesGetSuccess(userResponses));
  } catch (e) {
    console.log(e);
  }
};
