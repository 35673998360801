export default value => {
  if (!value) return value;
  let onlyNums = value
    .replace(/[^\d|.]/g, '')
    .replace(/ +$/, ' ')
    .replace('e', '')
    .trimLeft()
    .slice(0, 10);

  if (onlyNums.slice(-1) == '.') {
    onlyNums = onlyNums.slice(0, -1);
  }

  return onlyNums;
};
