export default (min, max) => {
  return value => {
    let trimValue = value && value.trim();
    return trimValue &&
      ((min && trimValue.length < min) || (max && trimValue.length > max))
      ? !min
        ? `Must not be greater than ${max} characters`
        : !max
        ? `Must not be less than ${min} characters`
        : `Must be between ${min} and ${max} characters`
      : undefined;
  };
};
