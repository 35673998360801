export const GET_REQUEST = 'tripReport/LEADER_NOTE_GET_REQUEST';
export const GET_FAILURE = 'tripReport/LEADER_NOTE_GET_FAILURE';
export const GET_SUCCESS = 'tripReport/LEADER_NOTE_GET_SUCCESS';
export const CLEAR = 'tripReport/LEADER_NOTE_GET_CLEAR';

export const INSERT_REQUEST = 'tripReport/LEADER_NOTE_INSERT_REQUEST';
export const INSERT_SUCCESS = 'tripReport/LEADER_NOTE_INSERT_SUCCESS';

export const DELETE_REQUEST = 'tripReport/LEADER_NOTE_DELETE_REQUEST';
export const DELETE_SUCCESS = 'tripReport/LEADER_NOTE_DELETE_SUCCESS';

export const UPDATE_REQUEST = 'tripReport/LEADER_NOTE_UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'tripReport/LEADER_NOTE_UPDATE_SUCCESS';
