import animationStyles from '../../common/styles/Animations.styles';

export default theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '5px 0 5px 0'
  },
  selectContainer: {
    width: '100%'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: '25px'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16,
    paddingTop: '1px'
  },
  dropdownIndicator: {
    padding: '0px !important'
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    paddingTop: '1px'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  option: {
    fontSize: 16
  },
  ...animationStyles
});
