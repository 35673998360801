import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ScrollToTop from '../../common/components/ScrollToTop';
import departures from '../../departures';
import tripReport from '../../tripReport';
import * as routes from '../../common/constants/routes';
import Analytics from './Analytics';
import NotFound404 from '../../common/components/NotFound404';
import Forbidden403 from '../../common/components/Forbidden403';
import { authContext } from '../../common/authentication/adalConfig';
import { messageTypes } from '../../common/actions/globalMessages';
import versionService from '../../common/services/VersionService';

const user = authContext.getCachedUser();

const Router = props => {
  const location = useLocation();

  useEffect(() => {
    versionService.getVersion().then(data => {
      if (data.version !== process.env.REACT_APP_VERSION) {
        props.actions.addMessage(
          'A new version of this app is available.',
          messageTypes.info,
          true,
          true
        );
      }
    });
  }, [location, props]);

  return (
    <Analytics userId={user.profile.oid}>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Navigate to={routes.ROUTE_DEPARTURES} />} />
          <Route
            path={routes.ROUTE_DEPARTURES}
            element={<departures.containers.DepartureListContainer />}
          />
          <Route
            path={routes.ROUTE_PAX}
            element={<departures.containers.PaxListContainer />}
          />
          <Route
            path={routes.ROUTE_DEPARTUREREPORT}
            element={<departures.containers.DepartureReportContainer />}
          />
          <Route
            path={routes.ROUTE_PAXLISTUPDATE}
            element={<departures.containers.PaxUpdateListContainer />}
          />
          <Route
            path={routes.ROUTE_PAXUPDATE}
            element={<departures.containers.PaxUpdateContainer />}
          />
          <Route
            path={routes.ROUTE_DEPARTURETRIPREPORT}
            element={<tripReport.containers.TripReportContainer />}
          />
          <Route
            path={routes.ROUTE_LEADERACTIONS}
            element={<departures.containers.LeaderActionsContainer />}
          />
          <Route
            path={routes.ROUTE_PAXQUESTIONNAIRE}
            element={<departures.containers.CovidPaxQuestionnaireContainer />}
          />
          <Route
            path={routes.ROUTE_CREWQUESTIONNAIRE}
            element={<departures.containers.CovidCrewQuestionnaireContainer />}
          />
          <Route
            path={routes.ROUTE_PAX_PRE_TRIP_COVID_TEST}
            element={<departures.containers.PreTripCovidTestPaxContainer />}
          />
          <Route path={routes.ROUTE_NOTFOUND} element={<NotFound404 />} />
          <Route path={routes.ROUTE_ACCESSDENIED} element={<Forbidden403 />} />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </ScrollToTop>
    </Analytics>
  );
};

export default Router;
