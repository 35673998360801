import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import LeaderActions from '../components/LeaderActions';
import actions from '../actions';
import selectors from '../selectors';
import { bindActionCreators } from 'redux';
import {
  departurePaxDetailReviewComplete as reviewComplete,
  tripReportUploadToSharepoint as tripReportUpload
} from '../actions/leaderActions';
import { targetType } from '../../common/constants/targetType';
import systemConfigSelectors from '../../systemConfigs/selectors';
import { IS_LEADER_PORTAL_COVID_SURVEY_ENABLED } from '../../common/constants/systemConfigCode';

const targetId = targetType.CREW;

function LeaderActionsContainer({
  actions,
  departure,
  covidQuestionnaireResponses,
  pax,
  leaderActions,
  isLeaderPortalCovidSurveyEnabled
}) {
  const { id } = useParams(); // Get id from route parameters
  const navigate = useNavigate(); // Use navigate instead of push
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadData = departureId => {
    actions.getDeparture(departureId);
    actions.getSharepointFileUploads(departureId);
    actions.getDeparturePaxReviewDetail(departureId);
    actions.getCovidQuestionnaireResponses(departureId, targetId);
    setLoaded(true);
  };

  let covidQuestionnaireCrewResponse = null;

  if (covidQuestionnaireResponses) {
    covidQuestionnaireCrewResponse =
      covidQuestionnaireResponses.covidQuestionnaireCrewResponse;
  }

  let allPaxConfirmed = false;
  let departurePaxCount = 0;
  let covidQuestionnaireSubmittedPaxCount = 0;
  let confirmedPaxCount = 0;

  if (pax) {
    const paxConfirmed = pax.pax.filter(
      currentPax => currentPax.detailsConfirmed
    );
    confirmedPaxCount = paxConfirmed.length;
    allPaxConfirmed = confirmedPaxCount === pax.pax.length;
    departurePaxCount = pax.pax.length;
    const submittedPax = pax.pax.filter(p => p.isCovidQuestionnaireSubmitted);
    covidQuestionnaireSubmittedPaxCount = submittedPax.length;
  }

  return (
    departure && (
      <LeaderActions
        departure={departure}
        covidQuestionnaireCrewResponse={covidQuestionnaireCrewResponse}
        pax={pax}
        push={navigate} // Using navigate instead of push
        departurePaxDetailReviewComplete={() =>
          actions.departurePaxDetailReviewComplete(id)
        }
        getDeparturePaxDetailReviewDetail={() =>
          actions.getDeparturePaxReviewDetail(id)
        }
        tripReportUploadToSharepoint={() =>
          actions.tripReportUploadToSharepoint(id)
        }
        leaderActions={leaderActions}
        isLeaderPortalCovidSurveyEnabled={isLeaderPortalCovidSurveyEnabled}
      />
    )
  );
}

LeaderActionsContainer.propTypes = {
  actions: PropTypes.shape({
    pushManifest: PropTypes.func
  }).isRequired
};

const mapStateToProps = state => {
  const selectedDeparture = selectors.selectedDeparture.getDeparture(state);
  const leaderActions = selectors.leaderActions.getLeaderActions(state);
  const covidQuestionnaireResponses = selectors.covidQuestionnaireResponses.getCovidQuestionnaireResponses(
    state
  );
  const pax = selectors.pax.getPax(state);
  const isLeaderPortalCovidSurveyEnabled = systemConfigSelectors.systemConfigs.getIsLeaderPortalCovidSurveyEnabled(
    state,
    IS_LEADER_PORTAL_COVID_SURVEY_ENABLED
  );

  return {
    departure: selectedDeparture,
    covidQuestionnaireResponses: covidQuestionnaireResponses,
    pax: pax,
    leaderActions: leaderActions,
    isLeaderPortalCovidSurveyEnabled: isLeaderPortalCovidSurveyEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getDeparture: actions.selectedDeparture.getSelectedDeparture,
        getSharepointFileUploads: actions.leaderActions.sharepointFileUploads,
        getCovidQuestionnaireResponses:
          actions.covidQuestionnaireResponses
            .getCovidQuestionnaireResponsesByDepartureId,
        getDeparturePaxReviewDetail:
          actions.leaderActions.departurePaxReviewDetail,
        departurePaxDetailReviewComplete: id => async dispatch => {
          await reviewComplete(id)(dispatch);
          return true;
        },
        tripReportUploadToSharepoint: id => async dispatch => {
          await tripReportUpload(id)(dispatch);
          return true;
        }
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderActionsContainer);
