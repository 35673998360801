import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CovidQuestion from '../components/CovidQuestion';
import styles from './CovidQuestionnaire.styles';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import ConfirmationDialog from '../../common/components/ConfirmationDialog';
import CovidQuestionnaireInstruction from '../components/CovidQuestionnaireInstruction';
// import { Redirect } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import convertToAPIDate from '../../common/helpers/convertToAPIDate';

class CovidQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepNo: 0,
      answers: [],
      yesAnswerConfirmation: false,
      openInstructionDialog: false,
      QuestionnaireAnswerSubmitted: false,
      redirectToReturlUrl: false
    };
  }

  closeYesAnswerConfirmationDialog = () => {
    this.setState({ yesAnswerConfirmation: false });
  };

  closeInstructionDialog = () => {
    this.setState({
      openInstructionDialog: false
    });
    this.handleRedirectToReturlUrl();
  };

  handleRedirectToReturlUrl = () => {
    this.setState({ redirectToReturlUrl: true });
  };

  submitCovidQuestionnaire = async () => {
    const isConsentAnswered =
      this.props.covidQuestionnaire.filter(cq =>
        this.state.answers.some(
          a =>
            cq.covidQuestionnaireQuestionId === a.covidQuestionnaireQuestionId
        )
      ).length > 0;
    const request = {
      questionnaireResponses: {
        consent: isConsentAnswered,
        covidQuestionResponses: this.state.answers,
        executionDateTimeStr: convertToAPIDate(new Date())
      }
    };

    this.setState({
      QuestionnaireAnswerSubmitted: true
    });
    await this.props.submit(request);
    this.setState({ yesAnswerConfirmation: false });

    if (this.state.answers.some(ans => ans.isAlertingAnswer == true)) {
      this.setState({
        openInstructionDialog: true
      });
    } else {
      this.handleRedirectToReturlUrl();
    }
  };

  handleSubmitClick = event => {
    if (this.state.answers.some(ans => ans.isAlertingAnswer == true)) {
      this.setState({ yesAnswerConfirmation: true });
      event.preventDefault();
      return;
    }

    this.submitCovidQuestionnaire();
  };

  getNextButton = (finalStep, departureId, covidQuestionnaireResponses) => {
    if (this.state.stepNo === 0) {
      return (
        <Button raised color="primary" onClick={this.handleClickNext}>
          {covidQuestionnaireResponses != null &&
          covidQuestionnaireResponses.isQuestionnaireSubmitted
            ? 'View Result'
            : 'Start'}
        </Button>
      );
    }

    if (this.state.stepNo === finalStep) {
      return (
        <div>
          <Button
            raised
            color="primary"
            onClick={this.handleSubmitClick}
            disabled={
              (covidQuestionnaireResponses != null &&
                covidQuestionnaireResponses.isQuestionnaireSubmitted) ||
              !this.state.answers[this.state.stepNo - 1] ||
              this.state.QuestionnaireAnswerSubmitted
            }
          >
            Submit
          </Button>

          <ConfirmationDialog
            open={this.state.yesAnswerConfirmation}
            close={this.closeYesAnswerConfirmationDialog}
            title={this.props.confirmatonTitle}
            message={this.props.confirmationMessage}
            action={this.submitCovidQuestionnaire}
            actionText="Submit"
          />

          <CovidQuestionnaireInstruction
            open={this.state.openInstructionDialog}
            onClose={this.closeInstructionDialog}
            departureId={departureId}
            instructionTitle={this.props.instructionTitle}
            instructionContent={this.props.instructionContent}
          />
        </div>
      );
    }

    if (
      covidQuestionnaireResponses != null &&
      covidQuestionnaireResponses.isQuestionnaireSubmitted
    ) {
      return (
        <Button raised color="primary" onClick={this.handleClickNext}>
          Next
        </Button>
      );
    } else {
      return (
        <Button
          raised
          color="primary"
          onClick={this.handleClickNext}
          disabled={!this.state.answers[this.state.stepNo - 1]}
        >
          Next
        </Button>
      );
    }
  };

  handleAnswerClick = (
    covidQuestionnaireQuestionId,
    covidQuestionAnswerTextId,
    isAlertingAnswer,
    covidQuestionResponse
  ) => {
    if (!covidQuestionResponse) {
      const answers = [...this.state.answers];
      const index = answers.findIndex(
        a => a.covidQuestionnaireQuestionId === covidQuestionnaireQuestionId
      );
      if (index >= 0) {
        if (covidQuestionAnswerTextId === null) {
          answers.splice(index, 1);
        } else {
          answers[index] = Object.assign({}, answers[index], {
            covidQuestionAnswerTextId: covidQuestionAnswerTextId,
            isAlertingAnswer: isAlertingAnswer
          });
        }
      } else {
        answers.push({
          covidQuestionnaireQuestionId: covidQuestionnaireQuestionId,
          covidQuestionAnswerTextId: covidQuestionAnswerTextId,
          isAlertingAnswer: isAlertingAnswer
        });
      }
      this.setState({
        answers: answers
      });
      if (!isAlertingAnswer) {
        setTimeout(this.handleClickNext, 500);
      }
    }
  };

  handleClickNext = () => {
    if (this.state.stepNo < this.getNoOfSteps() - 1) {
      this.setState({ stepNo: this.state.stepNo + 1 });
    }
  };

  handleClickPrevious = () => {
    this.setState({ stepNo: this.state.stepNo - 1 });
  };

  getNoOfSteps = () => {
    if (
      this.props.covidQuestionnaireResponses &&
      this.props.covidQuestionnaireResponses.isQuestionnaireSubmitted
    ) {
      return (
        this.props.covidQuestionnaireResponses.covidQuestionResponses.length + 1
      );
    } else {
      return this.props.covidQuestionnaire.length + 1;
    }
  };

  getAnswer = covidQuestionnaireQuestionId => {
    const answerItem = this.state.answers.find(
      x => x.covidQuestionnaireQuestionId === covidQuestionnaireQuestionId
    );
    return answerItem ? answerItem.covidQuestionAnswerTextId : null;
  };
  render() {
    const stepPageContents = [];
    const {
      covidQuestionnaire,
      covidQuestionnaireResponses,
      classes,
      departureId,
      crewOrPaxFullName,
      age
    } = this.props;
    stepPageContents.push(
      <div className={classes.FirtPageContainer}>
        <Typography variant="body1" gutterBottom className={classes.Typography}>
          {this.props.firstPageContent}
        </Typography>
      </div>
    );
    if (
      covidQuestionnaireResponses &&
      covidQuestionnaireResponses.isQuestionnaireSubmitted
    ) {
      covidQuestionnaireResponses.covidQuestionResponses.forEach(
        covidQuestionResponse => {
          stepPageContents.push(
            <CovidQuestion
              covidQuestion={covidQuestionResponse.covidQuestion}
              covidQuestionResponse={covidQuestionResponse}
              handleAnswerClick={this.handleAnswerClick}
              answer={this.getAnswer(
                covidQuestionResponse.covidQuestion.covidQuestionnaireQuestionId
              )}
              specialMessage={
                this.state.stepNo == 1 ? this.props.specialMessage : null
              }
              crewOrPaxFullName={crewOrPaxFullName}
              age={age}
            />
          );
        }
      );
    } else {
      covidQuestionnaire.forEach(covidQuestion => {
        stepPageContents.push(
          <CovidQuestion
            covidQuestion={covidQuestion}
            handleAnswerClick={this.handleAnswerClick}
            answer={this.getAnswer(covidQuestion.covidQuestionnaireQuestionId)}
            specialMessage={
              this.state.stepNo == 1 ? this.props.specialMessage : null
            }
            crewOrPaxFullName={crewOrPaxFullName}
            age={age}
          />
        );
      });
    }

    if (this.state.redirectToReturlUrl) {
      return <Navigate to={this.props.returnURL} />;
    }
    return (
      <div className={classes.MainContainer}>
        <Paper className={classes.PageContainer}>
          <div className={classes.ContentContainer}>
            {stepPageContents[this.state.stepNo]}
          </div>
          <div className={classes.buttonContainer}>
            <div>
              <Paper className={classes.steperContainer}>
                <MobileStepper
                  variant="dots"
                  steps={this.getNoOfSteps()}
                  position="static"
                  activeStep={this.state.stepNo}
                  className={classes.root}
                  nextButton={this.getNextButton(
                    stepPageContents.length - 1,
                    departureId,
                    covidQuestionnaireResponses
                  )}
                  backButton={
                    this.state.stepNo > 0 ? (
                      <Button
                        raised
                        color="primary"
                        onClick={this.handleClickPrevious}
                        size="small"
                        disabled={this.state.stepNo === 0}
                      >
                        Back
                      </Button>
                    ) : (
                      <span className={classes.FixedWidth} />
                    )
                  }
                />
              </Paper>
            </div>
            <div className={classes.exitButton}>
              <Button
                raised
                color="primary"
                onClick={this.handleRedirectToReturlUrl}
              >
                Exit
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

CovidQuestionnaire.propTypes = {
  departureId: PropTypes.string,
  covidQuestionnaire: PropTypes.object,
  covidQuestionnaireResponses: PropTypes.object,
  submit: PropTypes.func.isRequired,
  returnURL: PropTypes.string,
  instructionTitle: PropTypes.string,
  instructionContent: PropTypes.func.isRequired,
  firstPageContent: PropTypes.func.isRequired
};

export default withStyles(styles)(CovidQuestionnaire);
