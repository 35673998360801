import React, { Component, Fragment } from 'react';
import { detect } from 'detect-browser';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    backgroundColor: 'red'
  },
  content: {
    textAlign: 'center',
    color: 'white'
  }
};

const browser = detect();

const isBrowserSupported = () => {
  // Browser Version
  let majorBrowserVersion = browser.version.split('.')[0];

  if (isNaN(majorBrowserVersion)) {
    return false;
  }

  majorBrowserVersion = parseInt(majorBrowserVersion);

  // Browser Name
  switch (browser && browser.name) {
    case 'chrome':
    case 'crios':
      return (
        majorBrowserVersion >= process.env.REACT_APP_BROWSER_MIN_VERSION_CHROME
      );
    case 'safari':
    case 'ios':
      return (
        majorBrowserVersion >= process.env.REACT_APP_BROWSER_MIN_VERSION_SAFARI
      );
    default:
      return false;
  }
};

const BrowserNotSupportedMessage = () => (
  <Fragment>
    {!isBrowserSupported() && (
      <div style={styles.container}>
        <Typography type="body2" style={styles.content}>
          Your browser ({browser.name}, {browser.version}) is not supported.
          <br />
          As a minimum please use Chrome
          {' ' + process.env.REACT_APP_BROWSER_MIN_VERSION_CHROME} or Safari
          {' ' + process.env.REACT_APP_BROWSER_MIN_VERSION_SAFARI}.
        </Typography>
      </div>
    )}
  </Fragment>
);

export default withStyles(styles)(BrowserNotSupportedMessage);
