import React from 'react';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { ROUTE_DEPARTURES } from '../../common/constants/routes';
import createLink from '../helpers/createLink';
import Avatar from '@material-ui/core/Avatar';
import { authContext } from '../../common/authentication/adalConfig';

const user = authContext.getCachedUser();

const DrawerMenu = ({ isInternalUser, toggleDrawer }) => {
  const navigate = useNavigate();

  const sharepointLinkClicked = () => {
    handleGoogleAnalyticsRequest(
      'External Resources',
      'Sharepoint Link',
      `userId: ${user.profile.oid}`
    );
    window.open(process.env.REACT_APP_SHAREPOINT);
  };

  const incidentDatabaseLinkClicked = () => {
    handleGoogleAnalyticsRequest(
      'External Resources',
      'Incident Database Link',
      `userId: ${user.profile.oid}`
    );
    window.open(process.env.REACT_APP_INCIDENT_DATABASE);
  };

  const yammerLinkClicked = () => {
    handleGoogleAnalyticsRequest(
      'External Resources',
      'Yammer Link',
      `userId: ${user.profile.oid}`
    );
    window.open(process.env.REACT_APP_YAMMER);
  };

  const handleGoogleAnalyticsRequest = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const myDepartures = () => {
    navigate(createLink(ROUTE_DEPARTURES));
    toggleDrawer(false);
  };

  const logout = () => {
    authContext.logOut();
  };

  const initials = user.profile.name.match(/\b\w/g) || [];

  return (
    <div style={{ width: 250 }}>
      <MenuList role="menu">
        <div style={{ textAlign: 'center', fontSize: '9pt' }}>
          <Avatar style={{ margin: 'auto' }}>{initials}</Avatar>
          <span>{user.profile.name}</span>
        </div>
        <MenuItem onClick={myDepartures}>My Departures</MenuItem>
        <Divider />
        {isInternalUser && (
          <div>
            <MenuItem onClick={sharepointLinkClicked}>
              SharePoint Homepage
            </MenuItem>
            <Divider />
          </div>
        )}
        <MenuItem onClick={incidentDatabaseLinkClicked}>
          Log Safety Incident
        </MenuItem>
        <Divider />
        {isInternalUser && (
          <div>
            <MenuItem onClick={yammerLinkClicked}>
              Yammer - Provide Feedback
            </MenuItem>
            <Divider />
          </div>
        )}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuList>
    </div>
  );
};

export default DrawerMenu;
