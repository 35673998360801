import NetworkError from '../../common/errors/NetworkError';
import BadRequest from '../../common/errors/BadRequest';
import Unauthorized from '../../common/errors/Unauthorized';
import NotFoundError from '../../common/errors/NotFoundError';
import ForbiddenError from '../../common/errors/ForbiddenError';

export default class BaseService {
  constructUrl(base_url, path) {
    return `${base_url}${path}`;
  }

  createRequest(base_url, href, options = {}) {
    const { method, body, headers } = options;
    const url = this.constructUrl(base_url, href);
    const requestHeaders = Object.assign({}, headers);

    return new Request(url, {
      headers: new Headers(requestHeaders),
      method,
      body,
      credentials: 'same-origin'
    });
  }

  validate(resp) {
    if (resp.ok) {
      return resp;
    }

    if (resp.status == 401) {
      return Promise.reject(new Unauthorized());
    }

    if (resp.status == 403) {
      return Promise.reject(new ForbiddenError());
    }

    if (resp.status == 404) {
      return Promise.reject(new NotFoundError());
    }

    // Handle client errors
    if (resp.status >= 400 && resp.status < 499) {
      return Promise.reject(new BadRequest());
    }

    if (resp.status == 302) {
      return Promise.reject(new Unauthorized());
    }

    // Handle server errors
    if (resp.status >= 500 && resp.status < 600) {
      return Promise.reject(new NetworkError());
    }

    return resp;
  }

  extract(resp) {
    return resp.text().then(text => text && JSON.parse(text));
  }

  fetch(request) {
    return fetch(request).catch(e => {
      return Promise.reject(new NetworkError());
    });
  }
}
