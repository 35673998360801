import NetworkError from '../../common/errors/NetworkError';
import NotFoundError from '../../common/errors/NotFoundError';
import Unauthorized from '../../common/errors/Unauthorized';
import ForbiddenError from '../../common/errors/ForbiddenError';
import {
  ROUTE_NOTFOUND,
  ROUTE_ACCESSDENIED
} from '../../common/constants/routes';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';

export default store => next => async action => {
  try {
    return await next(action);
  } catch (e) {
    if (e instanceof NetworkError) {
      return store.dispatch(function(dispatch, getState) {
        dispatch(addMessage(e.toString(), messageTypes.error));
      });
    }

    if (e instanceof Unauthorized) {
      store.dispatch(addMessage(e.toString(), messageTypes.error, true, false));
      return;
    }

    if (e instanceof NotFoundError) {
      window.location.href = ROUTE_NOTFOUND;
    }

    if (e instanceof ForbiddenError) {
      window.location.href = ROUTE_ACCESSDENIED;
    }

    store.dispatch(function(dispatch, getState) {
      dispatch(addMessage(e.toString(), messageTypes.error));
    });

    throw e;
  }
};
