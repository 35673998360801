import React from 'react';
import { RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './FormRadioGroup.styles';

const FormRadioGroup = ({ children, classes, ...props }) => (
  <RadioGroup {...props} className={classes.radioGroup}>
    {children}
  </RadioGroup>
);

export default withStyles(styles)(FormRadioGroup);
