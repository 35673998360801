export default class CovidQuestionnaire {
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/covidquestionnaire';
  }

  get(targetId) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      targetId
    )}`;

    return this.service.get(resourcePath);
  }

  getCovidQuestionnaireResponses(departureId, paxId, targetId) {
    const resourcePath = `${
      this.baseResourcePath
    }/getCovidQuestionnaireResponses/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}/${encodeURIComponent(targetId)}`;

    return this.service.get(resourcePath);
  }

  getCovidQuestionnaireResponsesByDepartureId(departureId, targetId) {
    const resourcePath = `${
      this.baseResourcePath
    }/getCovidQuestionnaireResponses/${encodeURIComponent(
      departureId
    )}/${encodeURIComponent(targetId)}`;

    return this.service.get(resourcePath);
  }

  postPaxQuestionnaire(paxId, departureId, data) {
    const resourcePath = `/departure/${encodeURIComponent(
      departureId
    )}/pax/${encodeURIComponent(paxId)}${this.baseResourcePath}`;
    return this.service.post(resourcePath, data);
  }
  postCrewQuestionnaire(departureId, data) {
    const resourcePath = `/departure/${encodeURIComponent(departureId)}/crew/${
      this.baseResourcePath
    }`;
    return this.service.post(resourcePath, data);
  }
}
