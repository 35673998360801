import React from 'react';
import PropTypes from 'prop-types';
import { differenceInYears, isWithinInterval } from 'date-fns';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import cardStyles from '../../common/styles/Card.module.css';
import styles from './PaxCard.styles';
import { withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

class PaxCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  isExpandable = (
    singleSupplementBooked,
    dietaryNotes,
    packageDepartureCode,
    medicalNotes,
    leaderNotes,
    isBirthdayOnTrip
  ) => {
    return (
      singleSupplementBooked ||
      dietaryNotes ||
      packageDepartureCode ||
      medicalNotes ||
      leaderNotes ||
      isBirthdayOnTrip
    );
  };

  getAge = age => (!isNaN(age) ? `${age} yrs` : '-');

  checkForBirthdayOnTrip(dateOfBirth, startDate, endDate) {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();

    return (
      isWithinInterval(
        new Date(dateOfBirth).setFullYear(startYear),
        new Date(startDate),
        new Date(endDate)
      ) ||
      isWithinInterval(
        new Date(dateOfBirth).setFullYear(endYear),
        new Date(startDate),
        new Date(endDate)
      )
    );
  }

  createSubHeader = (
    paxId,
    bookingId,
    singleSupplementBooked,
    age,
    nationality,
    dietaryNotes,
    packageDepartureCode,
    medicalNotes,
    leaderNotes,
    isBirthdayOnTrip,
    classes
  ) => (
    <div>
      <span id={`pax.header.booking_${paxId}`}>{bookingId}</span> •
      <span id={`pax.header.age_${paxId}`}> {this.getAge(age)}</span>
      {singleSupplementBooked && (
        <Icon color="secondary" className={classes.icon}>
          person
        </Icon>
      )}
      {dietaryNotes && (
        <Icon color="secondary" className={classes.icon}>
          local_dining
        </Icon>
      )}
      {packageDepartureCode && (
        <Icon color="secondary" className={classes.icon}>
          link
        </Icon>
      )}
      {medicalNotes && (
        <Icon color="secondary" className={classes.icon}>
          local_hospital
        </Icon>
      )}
      {leaderNotes && (
        <Icon color="secondary" className={classes.icon}>
          chat
        </Icon>
      )}
      {isBirthdayOnTrip && (
        <Icon color="secondary" className={classes.icon}>
          cake
        </Icon>
      )}
      <Typography
        type="body3"
        color="inherit"
        className={classes.marginTop4}
        noWrap
      >
        <span
          style={{ fontSize: '0.875rem', fontWeight: '500' }}
          id={`pax.header.nationality_${paxId}`}
        >
          {' '}
          {nationality}
        </span>
      </Typography>
    </div>
  );

  createSection = (key, value, sectionId) => (
    <div>
      <Typography variant="caption">{key}</Typography>
      <Typography type="body1" paragraph style={{ whiteSpace: 'pre-line' }}>
        <span id={sectionId}>{value}</span>
      </Typography>
    </div>
  );

  handleExpandClick = expandable => {
    this.setState({ expanded: expandable && !this.state.expanded });
  };

  render() {
    const { pax, departureStartDate, departureEndDate, classes } = this.props;
    const dietaryNotes = pax.dietaryNotes || '';
    const packageDepartureCode = pax.packageDepartureCode || '';
    const medicalNotes = pax.medicalNotes || '';
    const leaderNotes = pax.leaderNotes ? pax.leaderNotes.join('\r\n') : '';

    const fullName = (
      <Typography
        style={{ fontWeight: '500' }}
        variant="body2"
        display="block"
        b
        id={`pax.header.fullName_${pax.id}`}
      >
        {pax.fullNameWithTitle}
      </Typography>
    );

    const isBirthdayOnTrip = this.checkForBirthdayOnTrip(
      pax.dateOfBirth,
      departureStartDate,
      departureEndDate
    );
    const age = differenceInYears(
      new Date(pax.dateOfFirstTravel),
      pax.dateOfBirth || ''
    );

    return (
      <Card className={cardStyles.card}>
        <CardHeader
          title={fullName}
          subheader={
            <div style={{ fontSize: '0.875rem', fontWeight: '400' }}>
              {this.createSubHeader(
                pax.id,
                pax.bookingId,
                pax.singleSupplementBooked,
                age,
                pax.passport.nationality,
                dietaryNotes,
                packageDepartureCode,
                medicalNotes,
                leaderNotes,
                isBirthdayOnTrip,
                classes
              )}
            </div>
          }
          onClick={() =>
            this.handleExpandClick(
              this.isExpandable(
                pax.singleSupplementBooked,
                dietaryNotes,
                packageDepartureCode,
                medicalNotes,
                leaderNotes,
                isBirthdayOnTrip
              )
            )
          }
          id={`pax.header_${pax.id}`}
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {pax.singleSupplementBooked &&
              this.createSection(
                <span
                  style={{
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  Single Supplement
                </span>,
                <span
                  style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}
                >
                  Booked a single supplement
                </span>,
                `pax.content.singleSupplementBooked_${pax.id}`
              )}
            {dietaryNotes &&
              this.createSection(
                <span
                  style={{
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  Dietary Notes
                </span>,
                <span
                  style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}
                >
                  {dietaryNotes}
                </span>,
                `pax.content.dietaryNotes_${pax.id}`
              )}

            {packageDepartureCode &&
              this.createSection(
                <span
                  style={{
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  Combination Trip
                </span>,
                <span
                  style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}
                >
                  {packageDepartureCode}
                </span>,
                `pax.content.packageDepartureCode_${pax.id}`
              )}

            {medicalNotes &&
              this.createSection(
                <span
                  style={{
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  Medical Notes
                </span>,
                <span
                  style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}
                >
                  {medicalNotes}
                </span>,
                `pax.content.medicalNotes_${pax.id}`
              )}

            {leaderNotes &&
              this.createSection(
                <span
                  style={{
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  Additional Information
                </span>,
                <span
                  style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}
                >
                  {leaderNotes}
                </span>,
                `pax.content.leaderNotes_${pax.id}`
              )}

            {isBirthdayOnTrip &&
              this.createSection(
                <span
                  style={{
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  Birthday on Trip
                </span>,
                <span
                  style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}
                >
                  {format(pax.dateOfBirth, process.env.REACT_APP_DATE_FORMAT)}
                </span>,
                `pax.content.leaderNotes_${pax.id}`
              )}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

PaxCard.propTypes = {
  pax: PropTypes.object
};

export default withStyles(styles)(PaxCard);
