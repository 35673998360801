import * as actionTypes from '../actionTypes/crew';

export default (state = null, action) => {
  switch (action.type) {
    case actionTypes.CREW_GET_SUCCESS:
      return action.payload.crewList.crewList;
    case actionTypes.CREW_INSERT_SUCCESS:
      return [...state, action.payload.crew];
    case actionTypes.CREW_DELETE_SUCCESS:
      return state.filter(x => x.id !== action.payload.crewId);
    case actionTypes.CREW_UPDATE_SUCCESS:
      let newState = Object.assign([], state);
      let itemIndex = state.findIndex(x => x.id === action.payload.crew.id);
      newState[itemIndex] = action.payload.crew;
      return newState;
    default:
      return state;
  }
};
