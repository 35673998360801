import React, { Fragment } from 'react';
import InputField from '../../common/forms/InputField';
import SingleSelect from '../../common/forms/SingleSelect';
import DateField from '../../common/forms/DateField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import themes from './TripReportField.styles';
import dateRequired from '../../common/forms/validators/dateRequired';
import required from '../../common/forms/validators/required';

class TripReportMedicationFields extends React.Component {
  getOptions = pax => {
    return pax.map(p => ({
      label: `${p.bookingId} - ${p.fullName}`,
      value: p.id
    }));
  };

  render() {
    const { classes, submitting } = this.props;

    return (
      <Fragment>
        <div>
          <SingleSelect
            name="pax"
            label="Pax Name"
            validate={required}
            searchable={false}
            options={this.getOptions(this.props.pax)}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <DateField
            validate={dateRequired}
            className={classes.wideField}
            name="administeredDate"
            placeholder="Date"
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="location"
            placeholder="Location"
            validate={required}
            maxLength={100}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
        <div>
          <InputField
            name="medicationAdministered"
            placeholder="Medication Administered and Reason"
            multiline={true}
            validate={required}
            rows={6}
            rowsMax={6}
            maxLength={500}
            className={classes.wideField}
            disabled={submitting}
          />
        </div>
      </Fragment>
    );
  }
}

TripReportMedicationFields.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  pax: PropTypes.array.isRequired
};

export default withStyles(themes)(TripReportMedicationFields);
