import { parse } from 'date-fns';

const transformList = data => {
  const transfers = data;

  return transfers.map(transfer => transform(transfer));
};

const transform = data => {
  const transfer = data;

  //commented due to the version upgrade of date-fns
  // transfer.flightDateTime = parse(transfer.flightDateTime);

  return transfer;
};

export default class Transfers {
  constructor(service) {
    this.service = service;
  }

  async getByDepartureId(id) {
    const resourcePath = `/departures/${encodeURIComponent(id)}/transfers`;

    const data = await this.service.get(resourcePath);

    return transformList(data.body);
  }
}
