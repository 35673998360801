function BadRequest(message, ...params) {
  this.name = 'Bad Request';
  this.message = message;

  if (this.message === undefined) {
    this.message = 'Unable to complete the request';
  }

  this.stack = new Error().stack;
}

BadRequest.prototype = Object.create(Error.prototype);
BadRequest.prototype.constructor = BadRequest;

BadRequest.prototype.toString = function() {
  return this.message;
};

export default BadRequest;
