import { parse } from 'date-fns';

const transformList = data => {
  return data.map(sightseeings => transform(sightseeings));
};

const ConvertToDate = dateString => {
  return dateString !== null ? parse(dateString) : null;
};
const transform = data => {
  const sightseeings = data;

  //commented due to the version upgrade of date-fns
  // sightseeings.startDate = ConvertToDate(sightseeings.startDate);
  // sightseeings.finishDate = ConvertToDate(sightseeings.finishDate);

  return sightseeings;
};

export default class Sightseeings {
  constructor(service) {
    this.service = service;
  }

  async getByDepartureId(id) {
    const resourcePath = `/departures/${encodeURIComponent(id)}/sightseeings`;

    const data = await this.service.get(resourcePath);

    return transformList(data.body);
  }
}
