import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccommodationAction from '../actions/AccommodationAction';
import TripReportSection from '../components/TripReportSection';
import TripReportAccommodationFields from '../components/TripReportAccommodationFields';
import TripReportSectionCard from '../components/TripReportSectionCard';
import styles from '../components/TripReportSectionCard.styles';
import '../../common/helpers/stringExtensions';
import CustomRouterWrapper from './CustomRouterWrapper';

class TripReportAccommodationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subTitleText:
        'Include any highlights or challenges with the accommodation.'
    };
  }

  renderSubHeader = accommodation => {
    return (
      accommodation.location && (
        <div style={styles.trimText}>
          <span id={'accommodation.header.location_' + accommodation.id}>
            {accommodation.location}
          </span>
        </div>
      )
    );
  };

  renderContent = accommodation => {
    return (
      accommodation.comments && (
        <div style={styles.trimText}>
          <span id={'accommodation.header.comments_' + accommodation.id}>
            {accommodation.comments}
          </span>
        </div>
      )
    );
  };

  renderSectionFields = submitting => (
    <TripReportAccommodationFields submitting={submitting} />
  );

  renderSectionCard = accommodation => {
    return (
      <TripReportSectionCard
        sectionName="accommodation"
        itemName="accommodation"
        title={accommodation.name}
        subTitle={this.state.subTitleText}
        subHeader={this.renderSubHeader}
        cardContent={this.renderContent}
        item={accommodation}
        itemId={accommodation.id}
        sectionFields={this.renderSectionFields}
        update={this.props.actions.updateAccommodation}
        delete={() =>
          this.props.actions.deleteAccommodation(
            this.props.departure.id,
            accommodation.id
          )
        }
        deleteMessage={accommodation =>
          `Are you sure you want to delete accommodation "${accommodation.name}"?`
        }
      />
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.departure.id !== prevProps.departure.id) {
      this.loadData(this.props.departure.id);
    }
  }

  loadData(id) {
    this.props.actions.getPax(id);
  }

  render() {
    const { departure, accommodationList } = this.props;

    return (
      departure &&
      accommodationList && (
        <TripReportSection
          subTitle={this.state.subTitleText}
          sectionName="Accommodation"
          itemName="accommodation"
          noItemsMessage="There are no accommodation issues added yet"
          sectionCard={this.renderSectionCard}
          sectionFields={this.renderSectionFields}
          items={accommodationList}
          insert={this.props.actions.insertAccommodation}
        />
      )
    );
  }
}

TripReportAccommodationContainer.propTypes = {
  departure: PropTypes.object,
  accommodationList: PropTypes.array,
  actions: PropTypes.shape({
    insertAccommodation: PropTypes.func,
    deleteAccommodation: PropTypes.func,
    updateAccommodation: PropTypes.func
  }).isRequired
};

const mapStateToProps = (state, params) => {
  return {
    id: params.router.params.id
  };
};

const mapDispatchToProps = (dispatch, params) => ({
  actions: bindActionCreators(
    {
      insertAccommodation: values => async dispatch =>
        await new AccommodationAction().insert(
          params.router.params.id,
          values
        )(dispatch),
      deleteAccommodation: (departureId, accommodationId) => async dispatch =>
        await new AccommodationAction().delete(
          departureId,
          accommodationId
        )(dispatch),
      updateAccommodation: values => async dispatch =>
        await new AccommodationAction().update(
          params.router.params.id,
          values
        )(dispatch)
    },
    dispatch
  )
});

export default CustomRouterWrapper(
  connect(mapStateToProps, mapDispatchToProps)(TripReportAccommodationContainer)
);
