import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';
import BadRequest from '../../common/errors/BadRequest';
import { Form } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import styles from './FormDialog.styles';

class FormDialog extends React.Component {
  onSubmit = async values => {
    try {
      const formattedValues = this.props.format
        ? this.props.format(values)
        : values;

      await this.props.action(formattedValues);

      this.props.close();
    } catch (e) {
      if (!(e instanceof BadRequest)) {
        throw e;
      }
    }
  };

  render() {
    const {
      title,
      children,
      actionText,
      initialValues,
      open,
      close,
      classes
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="form-dialog-title"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown={false}
      >
        <Form
          onSubmit={this.onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <DialogTitle id="form-dialog-title">{title}</DialogTitle>
              <DialogContent className={classes.content}>
                {children(submitting)}
              </DialogContent>
              <DialogActions>
                <Button onClick={close} disabled={submitting}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                >
                  {actionText}
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    );
  }
}

FormDialog.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  actionText: PropTypes.string,
  format: PropTypes.func
};

FormDialog.defaultProps = {
  actionText: 'OK'
};

export const FormDialogEventType = {
  OPEN: 'Open',
  CLOSE: 'Close'
};

export default withStyles(styles)(FormDialog);
