import * as React from 'react';
import PropTypes from 'prop-types';
import createLink from '../../common/helpers/createLink';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import NavigationCardActions from '../../common/components/NavigationCardActions';
import ActionIcon from '../../common/components/ActionIcon';
import {
  ROUTE_PAX,
  ROUTE_DEPARTUREREPORT,
  ROUTE_PAXLISTUPDATE,
  ROUTE_DEPARTURETRIPREPORT,
  ROUTE_LEADERACTIONS
} from '../../common/constants/routes';
import styles from './DepartureCardAction.styles';
import { withStyles } from '@material-ui/core/styles';
import AlertDialog from '../../common/components/AlertDialog';
import { format } from 'date-fns';
import { fileUploadStatus } from '../../common/constants/fileUploadStatus';

class DepartureCardAction extends React.Component {
  state = {
    alertDialogAction: false
  };

  closeAlertDialog = () => {
    this.setState({ alertDialogAction: false });
  };

  checkTripReportAuthorization = event => {
    if (
      this.props.departure &&
      this.props.departure.tripReportFirstSubmission &&
      this.props.departure.tripReportUploadStatus !==
        fileUploadStatus.REACTIVATED
    ) {
      this.setState({ alertDialogAction: true });
      event.preventDefault();
    }
  };

  render() {
    const { departure, classes } = this.props;
    return (
      <NavigationCardActions>
        <IconButton
          tooltip="Passengers"
          component={Link}
          to={createLink(ROUTE_PAX, { id: departure.id })}
          className={classes.iconButton}
          id={'btn.passengers_' + departure.departureCode}
        >
          <ActionIcon iconType="people" label="Summary" color="secondary" />
        </IconButton>
        <IconButton
          tooltip="Report"
          component={Link}
          to={createLink(ROUTE_DEPARTUREREPORT, { id: departure.id })}
          className={classes.iconButton}
          id={'btn.report_' + departure.departureCode}
        >
          <ActionIcon iconType="view_list" label="Manifest" color="secondary" />
        </IconButton>
        <IconButton
          tooltip="Update"
          component={Link}
          to={createLink(ROUTE_PAXLISTUPDATE, { id: departure.id })}
          className={classes.iconButton}
          id={'btn.update_' + departure.departureCode}
        >
          <ActionIcon iconType="group_add" label="Update" color="secondary" />
        </IconButton>

        <IconButton
          tooltip="Trip Report"
          component={Link}
          to={createLink(ROUTE_DEPARTURETRIPREPORT, { id: departure.id })}
          onClick={this.checkTripReportAuthorization}
          className={classes.iconButton}
          id={'btn.tripReport_' + departure.departureCode}
        >
          <ActionIcon
            iconType="assignment"
            label={
              <span
                style={{
                  whiteSpace: 'nowrap'
                }}
              >
                Trip Report
              </span>
            }
            color="secondary"
          />
        </IconButton>

        <IconButton
          tooltip="Leader Actions"
          component={Link}
          to={createLink(ROUTE_LEADERACTIONS, { id: departure.id })}
          className={classes.iconButton}
          id={'btn.leaderActions_' + departure.departureCode}
        >
          <ActionIcon
            iconType="open_in_browser"
            label="Actions"
            color={
              departure.tripReportUploadStatus !== fileUploadStatus.FAILED &&
              departure.departureSummaryUploadStatus !== fileUploadStatus.FAILED
                ? 'secondary'
                : 'error'
            }
          />
        </IconButton>

        <AlertDialog
          open={this.state.alertDialogAction}
          close={this.closeAlertDialog}
          title="Trip Report already submitted"
          message={`First submitted on ${format(
            this.props.departure.tripReportFirstSubmission,
            process.env.REACT_APP_DATETIME_FORMAT
          )}. To view the Trip Report please check SharePoint.`}
        />
      </NavigationCardActions>
    );
  }
}

DepartureCardAction.propTypes = {
  departure: PropTypes.object
};

export default withStyles(styles)(DepartureCardAction);
