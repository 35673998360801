import { TrackJS } from 'trackjs';

const trackjs = authUserContext =>
  TrackJS.install({
    token: process.env.REACT_APP_TRACKJS_TOKEN,
    userId: authUserContext.getCachedUser().profile.oid,
    application: process.env.REACT_APP_TRACKJS_APPLICATION,
    version: process.env.REACT_APP_VERSION,
    onError: function(payload) {
      payload.network = payload.network.filter(function(item) {
        return item.url.indexOf('ipv4.icanhazip.com/') === -1;
      });
      return true;
    }
  });

export default trackjs;
