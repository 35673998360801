import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import createLink from '../../common/helpers/createLink';
import { ROUTE_PAXLISTUPDATE } from '../../common/constants/routes';
import actions from '../actions';
import selectors from '../selectors';
import PaxUpdateInfo from '../components/PaxUpdateInfo';
import { clearAll } from '../actions/pax';

function PaxUpdateContainer({ pax, departure, countries, actions }) {
  const { id, departureId } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadData(id, departureId);
  }, [id, departureId]);

  const loadData = (id, departureId) => {
    actions.getDeparture(departureId);
    actions.getPax(id, departureId);
    actions.getCountries();
    setLoaded(true);
  };

  const back = () => {
    setTimeout(() => {
      if (navigate.length > 1) {
        navigate(-1);
      } else {
        navigate(createLink(ROUTE_PAXLISTUPDATE, { id: departureId }), {
          replace: true
        });
      }
    }, 0);
  };

  const handleUpdate = async values => {
    try {
      await actions.update(id, departureId, values);
      clearAll();
      back();
    } catch (error) {
      console.error('Error updating pax:', error);
    }
  };

  return (
    departure &&
    pax &&
    pax.pax && (
      <PaxUpdateInfo
        departure={departure}
        pax={pax}
        countries={countries}
        back={back}
        update={handleUpdate}
      />
    )
  );
}

PaxUpdateContainer.propTypes = {
  pax: PropTypes.object,
  actions: PropTypes.shape({
    getPax: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => {
  const selectedDeparture = selectors.selectedDeparture.getDeparture(state);
  const statePax = selectors.selectedPax.getSelectedPax(state);
  const countries = selectors.countries.getCountries(state);

  return {
    departure: selectedDeparture,
    pax: statePax,
    countries: countries
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDeparture: actions.selectedDeparture.getSelectedDeparture,
      getPax: actions.selectedPax.getSelectedPax,
      getCountries: actions.countries.getCountries,
      update: (id, departureId, values) =>
        actions.pax.updatePax(id, departureId, values)
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(PaxUpdateContainer);
