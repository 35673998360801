import * as React from 'react';
import { Offline } from 'react-detect-offline';
import { Fragment } from 'react';
import GlobalMessage from '../../common/components/GlobalMessage';

const DetectOfflineMessage = () => (
  <Fragment>
    <Offline>
      <GlobalMessage
        open={true}
        type="offline"
        message="You are working offline, please review your internet connection before
          you continue"
      />
    </Offline>
  </Fragment>
);

export default DetectOfflineMessage;
