import { adalConfig, authContext } from '../authentication/adalConfig';
import Unauthorized from '../../common/errors/Unauthorized';

export default fetch => {
  return request =>
    new Promise((resolve, reject) => {
      authContext.acquireToken(adalConfig.clientId, (message, token, msg) => {
        request.headers.set('Authorization', 'Bearer ' + token);
        if (!msg) {
          resolve(fetch(request));
        } else {
          reject(new Unauthorized());
        }
      });
    });
};
