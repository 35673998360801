import * as actionTypes from '../actionTypes/sightseeings';
import LeaderPortalService from '../../common/services/LeaderPortalService';
import { addMessage, messageTypes } from '../../common/actions/globalMessages';

export const departureSightseeingsGetRequest = departureId => ({
  type: actionTypes.DEPARTURE_SIGHTSEEINGS_GET_REQUEST,
  payload: {
    departureId
  }
});

export const departureSightseeingsGetSuccess = sightseeings => ({
  type: actionTypes.DEPARTURE_SIGHTSEEINGS_GET_SUCCESS,
  payload: {
    sightseeings
  }
});

export const getDepartureSightseeings = id => async dispatch => {
  dispatch(departureSightseeingsGetRequest(id));

  const sightseeings = await LeaderPortalService.Sightseeings.getByDepartureId(
    id
  );

  dispatch(departureSightseeingsGetSuccess(sightseeings));
};
