import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

class ConfirmationDialog extends React.Component {
  state = {
    buttonsDisabled: false
  };

  handleDisableButtons = value => {
    this.setState({ buttonsDisabled: value });
  };

  render() {
    const { message, title, actionText, action, close, open } = this.props;

    return (
      <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={this.state.buttonsDisabled}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => {
              this.handleDisableButtons(true);
              action().then(() => {
                this.handleDisableButtons(false);
              });
            }}
            disabled={this.state.buttonsDisabled}
          >
            {actionText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  close: PropTypes.func.isRequired
};

ConfirmationDialog.defaultProps = {
  actionText: 'OK'
};

export default ConfirmationDialog;
