import React from 'react';
import ErrorMessage from './ErrorMessage';
import createLink from '../../common/helpers/createLink';
import { ROUTE_DEPARTURES } from '../../common/constants/routes';

const Forbidden403 = () => (
  <ErrorMessage
    code="403"
    name="Access Denied"
    message="You do not have access to the requested resource. Please contact your manager."
    actions={[
      { name: 'Go to My Departures', href: createLink(ROUTE_DEPARTURES) }
    ]}
  />
);

export default Forbidden403;
