import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Card, CardHeader, CardContent } from '@material-ui/core/';
import DisableAutoFill from '../../common/components/DisableAutoFill';
import PaxUpdateCardPassportDetails from './PaxUpdateCardPassportDetails';
import PaxUpdateCardVisaDetails from './PaxUpdateCardVisaDetails';
import PaxUpdateCardInsuranceDetails from './PaxUpdateCardInsuranceDetails';
import PaxUpdateCardEmergencyContactDetails from './PaxUpdateCardEmergencyContactDetails';
import PaxUpdateCardContactDetails from './PaxUpdateCardContactDetails';
import PaxUpdateCardConfirmation from './PaxUpdateCardConfirmation';
import AvatarLogo from '../../common/components/AvatarLogo';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import styles from './PaxUpdateInfo.module.css';
import cardStyles from '../../common/styles/Card.module.css';
import BadRequest from '../../common/errors/BadRequest';

const sectionCardStyle = {
  header: {
    backgroundColor: '#EEEEEE',
    paddingTop: 2,
    paddingBottom: 2
  }
};

const validate = async values => {
  if (!values.visa) {
    return;
  }

  let errors = {};
  let props = ['visaNumber', 'expiryDate', 'entryDate', 'exitDate'];

  let CountOfNulls = 0;
  errors.visa = {};

  for (let i = 0; i < props.length; i++) {
    if (!Object(values.visa)[props[i]]) {
      CountOfNulls++;
    }
  }

  if (CountOfNulls !== 0 && CountOfNulls !== props.length) {
    errors.visa = {};
    for (let i = 0; i < props.length; i++) {
      if (!Object(values.visa)[props[i]]) {
        errors.visa[props[i]] = 'Required';
      }
    }
  }

  if (Date.parse(values.visa.entryDate) > Date.parse(values.visa.exitDate)) {
    errors.visa.exitDate = 'Exit date should be later than entry date';
  }

  return Object.keys(errors.visa).length > 0 ? errors : {};
};
const focusOnError = createDecorator();
const PaxUpdateInfo = ({ departure, pax, countries, update }) => {
  const onSubmit = async values => {
    try {
      await update(values);
    } catch (e) {
      if (!(e instanceof BadRequest)) {
        throw e;
      }
    }
  };

  return (
    <div>
      <Card className={cardStyles.card}>
        <CardHeader
          avatar={
            <AvatarLogo
              brand={departure.brand}
              imgId={'img.header_' + departure.departureCode}
            />
          }
          title={pax.pax.fullNameWithTitle}
          subheader={departure.productName}
          id={'departure.pax_' + pax.passport.id}
        />
      </Card>
      <Card className={cardStyles.card}>
        <CardHeader className={styles.header} />
        <Form
          onSubmit={onSubmit}
          decorators={[focusOnError]}
          initialValues={pax}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <DisableAutoFill />
              <CardContent className={styles.cardContentStyle}>
                <PaxUpdateCardPassportDetails
                  pax={pax}
                  countries={countries}
                  sectionCardStyle={sectionCardStyle}
                />
                <PaxUpdateCardInsuranceDetails
                  paxInsurance={pax.insurance}
                  sectionCardStyle={sectionCardStyle}
                  bookingId={pax.pax.bookingId}
                />
                <PaxUpdateCardEmergencyContactDetails
                  emergencyContact={pax.emergencyContact}
                  sectionCardStyle={sectionCardStyle}
                  bookingId={pax.pax.bookingId}
                />
                <PaxUpdateCardVisaDetails
                  visa={pax.visa}
                  sectionCardStyle={sectionCardStyle}
                />
                <PaxUpdateCardContactDetails
                  paxContact={pax.contact}
                  sectionCardStyle={sectionCardStyle}
                />
                <PaxUpdateCardConfirmation
                  values={values}
                  sectionCardStyle={sectionCardStyle}
                />
                <br />
                <div className={cardStyles.confirmButton}>
                  <Button
                    raised
                    color="primary"
                    variant="contained"
                    type="submit"
                    id="btn.submit"
                    disabled={submitting}
                  >
                    Confirm
                  </Button>
                </div>
              </CardContent>
            </form>
          )}
        />
      </Card>
    </div>
  );
};

PaxUpdateInfo.propTypes = {
  departure: PropTypes.object,
  pax: PropTypes.object
};

export default PaxUpdateInfo;
