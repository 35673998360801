import { parse } from 'date-fns';

const transformList = data => {
  return data.map(accommodation => transform(accommodation));
};

const transform = data => {
  const accommodation = data;

  //commented due to the version upgrade of date-fns
  // accommodation.checkInDate = parse(accommodation.checkInDate);
  // accommodation.checkOutDate = parse(accommodation.checkOutDate);

  return accommodation;
};

export default class Accommodations {
  constructor(service) {
    this.service = service;
  }

  async getByDepartureId(id) {
    const resourcePath = `/departures/${encodeURIComponent(id)}/accommodations`;

    const data = await this.service.get(resourcePath);
    return transformList(data.body);
  }
}
