export default class SystemConfigs {
  constructor(service) {
    this.service = service;
    this.baseResourcePath = '/systemConfig';
  }

  async get(codes) {
    const resourcePath = `${this.baseResourcePath}/${encodeURIComponent(
      codes
    )}`;
    let data = await this.service.get(resourcePath);
    let systemConfigs = data.body;
    for (let key in systemConfigs) {
      if (systemConfigs[key] === 'true') {
        systemConfigs[key] = true;
      } else if (systemConfigs[key] === 'false') {
        systemConfigs[key] = false;
      }
    }
    return systemConfigs;
  }
}
