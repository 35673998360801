import BaseService, { IOptions } from './BaseService';
import adalFetch from '../helpers/adalFetch';

class VersionService extends BaseService {
  constructor() {
    super();

    this.baseUrl = process.env.PUBLIC_URL;
    this.fetch = adalFetch(super.fetch).bind(this);
  }

  getVersion() {
    const href = `${process.env.PUBLIC_URL}/version.json`;
    const headers = new Headers();
    const options = {
      method: 'GET',
      body: null,
      headers: headers
    };

    const request = this.createRequest(this.baseUrl, href, options);

    return this.fetch(request)
      .then(this.validate)
      .then(response => {
        return response.json();
      });
  }
}

const versionService = new VersionService();

export default versionService;
