import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';
import selectors from '../selectors';
import { targetType } from '../../common/constants/targetType';
import CovidQuestionnaire from '../components/CovidQuestionnaire';
import { ROUTE_LEADERACTIONS } from '../../common/constants/routes';
import systemConfigSelectors from '../../systemConfigs/selectors';
import { IS_LEADER_PORTAL_COVID_SURVEY_ENABLED } from '../../common/constants/systemConfigCode';
import createLink from '../../common/helpers/createLink';
import { authContext } from '../../common/authentication/adalConfig';
import { useParams } from 'react-router-dom';

const targetId = targetType.CREW;

const CovidCrewQuestionnaireContainer = props => {
  const { departureId } = useParams();

  useEffect(() => {
    loadData(targetId);
  }, []);

  const loadData = targetId => {
    props.actions.getCovidQuestionnaireResponses(departureId, targetId);
    props.actions.getCovidQuestionnaire(targetId);
  };

  const getFirstPageContent = () => {
    return (
      <div>
        <p>
          <b>COVID Self-Declaration</b>
        </p>
        <p>Why are you being asked to provide this information?</p>
        <p>
          The safety and well-being of our customers, leaders, and suppliers is
          our highest priority. Before you depart on a trip, you are required to
          complete a COVID-19 Self Declaration questionnaire...
        </p>
      </div>
    );
  };

  const getInstructions = () => {
    return (
      <div>
        <p>
          You have answered YES to one of the COVID-19 self-declaration
          questions or have not presented with the correct documentation to
          travel...
        </p>
      </div>
    );
  };

  let covidQuestionnaireCrewResponse = null;
  if (props.covidQuestionnaireResponses != null) {
    covidQuestionnaireCrewResponse =
      props.covidQuestionnaireResponses.covidQuestionnaireCrewResponse;
  }
  const returnUrl = createLink(ROUTE_LEADERACTIONS, { id: departureId });
  const user = authContext.getCachedUser();

  return (
    props.isLeaderPortalCovidSurveyEnabled &&
    props.covidQuestionnaire &&
    props.covidQuestionnaireResponses && (
      <CovidQuestionnaire
        covidQuestionnaire={props.covidQuestionnaire}
        covidQuestionnaireResponses={covidQuestionnaireCrewResponse}
        departureId={departureId}
        submit={props.actions.submitCrewCovidQuestionnaire}
        returnURL={returnUrl}
        instructionTitle={'Instructions'}
        instructionContent={getInstructions()}
        confirmatonTitle={'Covid Declaration Confirmation'}
        confirmationMessage={
          'You may not be eligible to lead this trip, are you sure you want to submit?'
        }
        firstPageContent={getFirstPageContent()}
        crewOrPaxFullName={user.profile.name}
      />
    )
  );
};

CovidCrewQuestionnaireContainer.propTypes = {
  covidQuestionnaire: PropTypes.object,
  actions: PropTypes.shape({
    getCovidQuestionnaire: PropTypes.func.isRequired,
    covidQuestionnaireResponses: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => {
  return {
    covidQuestionnaire: selectors.covidQuestionnaire.getCovidQuestionnaire(
      state
    ),
    covidQuestionnaireResponses: selectors.covidQuestionnaireResponses.getCovidQuestionnaireResponses(
      state
    ),
    isLeaderPortalCovidSurveyEnabled: systemConfigSelectors.systemConfigs.getIsLeaderPortalCovidSurveyEnabled(
      state,
      IS_LEADER_PORTAL_COVID_SURVEY_ENABLED
    )
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCovidQuestionnaire: actions.covidQuestionnaire.getCovidQuestionnaire,
      getCovidQuestionnaireResponses:
        actions.covidQuestionnaireResponses
          .getCovidQuestionnaireResponsesByDepartureId,
      submitCrewCovidQuestionnaire: request => async (dispatch, getState) => {
        const departureId = getState().params.departureId; // Adjust this to get the correct departureId from state
        await actions.covidQuestionnaire.submitCrewCovidQuestionnaire(
          departureId,
          request
        )(dispatch);
      }
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CovidCrewQuestionnaireContainer);
