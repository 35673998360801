import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions';
import selectors from '../selectors';
import CovidQuestionnaire from '../components/CovidQuestionnaire';
import { targetType } from '../../common/constants/targetType';
import systemConfigSelectors from '../../systemConfigs/selectors';
import { IS_LEADER_PORTAL_COVID_SURVEY_ENABLED } from '../../common/constants/systemConfigCode';
import { ROUTE_PAXLISTUPDATE } from '../../common/constants/routes';
import createLink from '../../common/helpers/createLink';
import { useParams } from 'react-router-dom';

const targetId = targetType.CUSTOMER;

const CovidPaxQuestionnaireContainer = props => {
  const { departureId, paxId } = useParams();

  useEffect(() => {
    loadData(targetId);
  }, [departureId, paxId]);

  const loadData = targetId => {
    props.actions.getCovidQuestionnaireResponses(departureId, paxId, targetId);
    props.actions.getCovidQuestionnaire(targetId);
    props.actions.getPax(paxId, departureId);
  };

  const getFirstPageContent = () => {
    return (
      <div>
        <p>
          <b>COVID Self-Declaration</b>
        </p>
        <p>Why are you being asked to provide this information?</p>
        <p>
          The safety and well-being of our customers, leaders, and suppliers is
          our highest priority. Before you depart on a trip, you are required to
          complete a COVID-19 Self Declaration questionnaire...
        </p>
      </div>
    );
  };

  const getInstructions = () => {
    return (
      <div>
        <h4 style={{ marginTop: 0 }}>
          One of your customers has answered YES to one of the questions on the
          COVID-19 self-declaration form: You must:
        </h4>
        <ul>
          <li>Keep calm.</li>
          <li>
            Isolate the customer (and anyone in their travelling party) from the
            rest of the group.
          </li>
          <li>
            While maintaining physical distancing, ask the customer to give you
            more details about their answer.
          </li>
          <li>Call your Operations Manager/emergency contact.</li>
          <li>
            With your Operations Manager's assistance, alert local health
            authorities and get their advice.
          </li>
          <li>
            Make sure the rest of the group is taken care of and kept informed.
          </li>
          <li>Log the incident in the Incident Management System (IMS).</li>
        </ul>
      </div>
    );
  };

  const covidQuestionnairePaxResponse =
    props.covidQuestionnaireResponses?.covidQuestionnairePaxResponse;
  const returnUrl = createLink(ROUTE_PAXLISTUPDATE, { id: departureId });

  return (
    props.isLeaderPortalCovidSurveyEnabled &&
    props.covidQuestionnaire &&
    props.covidQuestionnaireResponses &&
    props.statePax.pax && (
      <CovidQuestionnaire
        covidQuestionnaire={props.covidQuestionnaire}
        covidQuestionnaireResponses={covidQuestionnairePaxResponse}
        departureId={departureId}
        submit={props.actions.submitCovidQuestionnaire}
        returnURL={returnUrl}
        instructionTitle={'Instructions'}
        instructionContent={getInstructions()}
        confirmatonTitle={'Covid Declaration Confirmation'}
        confirmationMessage={
          'A YES response has been entered, are you sure you want to submit?'
        }
        firstPageContent={getFirstPageContent()}
        crewOrPaxFullName={props.statePax.pax.fullNameWithTitle}
        age={props.statePax.pax.age}
      />
    )
  );
};

CovidPaxQuestionnaireContainer.propTypes = {
  covidQuestionnaire: PropTypes.object,
  actions: PropTypes.shape({
    getCovidQuestionnaire: PropTypes.func.isRequired,
    getCovidQuestionnaireResponses: PropTypes.func.isRequired,
    submitCovidQuestionnaire: PropTypes.func.isRequired,
    getPax: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => {
  return {
    covidQuestionnaireResponses: selectors.covidQuestionnaireResponses.getCovidQuestionnaireResponses(
      state
    ),
    covidQuestionnaire: selectors.covidQuestionnaire.getCovidQuestionnaire(
      state
    ),
    isLeaderPortalCovidSurveyEnabled: systemConfigSelectors.systemConfigs.getIsLeaderPortalCovidSurveyEnabled(
      state,
      IS_LEADER_PORTAL_COVID_SURVEY_ENABLED
    ),
    statePax: selectors.selectedPax.getSelectedDeparturePax(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCovidQuestionnaireResponses:
        actions.covidQuestionnaireResponses.getCovidQuestionnaireResponses,
      getCovidQuestionnaire: actions.covidQuestionnaire.getCovidQuestionnaire,
      submitCovidQuestionnaire: request => async (dispatch, getState) => {
        const { departureId, paxId } = getState().params; // Adjust this to get the correct ids
        await actions.covidQuestionnaire.submitCovidQuestionnaire(
          paxId,
          departureId,
          request
        )(dispatch);
      },
      getPax: actions.selectedPax.getSelectedDeparturePax
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CovidPaxQuestionnaireContainer);
